import React from "react";
import ReactApexChart from "react-apexcharts";
const series = [45, 10, 80, 20];
const options = {
  chart: {
    type: "donut",
    width: 250,
  },
  dataLabels: {
    enabled: false,
  },
  legend: {
    show: false,
  },
  stroke: {
    width: 0,
  },
  fill: {
    colors: ["#FF0000", "#4FD66E", "#0000FF", "#8C90FC"],
  },
  responsive: [
    {
      breakpoint: 601,
      options: {
        chart: {
          width: 200,
          height: 200,
        },
        legend: {
          position: "bottom",
        },
      },
    },
  ],
};

const OrderSummaryChart = (totalExpense) => {
  return (
    <div id="AllProject">
      <ReactApexChart
        options={options}
        series={series}
        type="donut"
        width={250}
      />
    </div>
  );
};

export default OrderSummaryChart;
