import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { deleteCoupon, getCoupon, postCoupon, updateCoupon } from "./couponAPI";

// fetching all coupon
export const fetchAsyncAllCoupon = createAsyncThunk(
  "coupon/fetchCoupon",
  async () => {
    const collections = await getCoupon();
    return collections;
  }
);

// creating a coupon
export const createAsyncCoupon = createAsyncThunk(
  "coupon/createCoupon",
  async (data) => {
    const response = await postCoupon(data);
    return response;
  }
);

// updating a coupon
export const updateAsyncCoupon = createAsyncThunk(
  "coupon/updateCoupon",
  async (data) => {
    const response = await updateCoupon(data);
    return response;
  }
);

// Async thunk for deleting a coupon
export const deleteAsyncCoupon = createAsyncThunk(
  "coupon/deleteCoupon",
  async (douponId) => {
    const response = await deleteCoupon(douponId);
    return response;
  }
);

// Initial state for the coupoon slice
const initialState = {
  allCoupon: [],
  status: "idle",
  error: null,
};

// Category slice
const couponSlice = createSlice({
  name: "coupons",
  initialState,
  extraReducers: (builder) => {
    builder
      // fetch coupon
      .addCase(fetchAsyncAllCoupon.pending, (state) => {
        state.status = "loading";
        state.error = null;
      })
      .addCase(fetchAsyncAllCoupon.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.allCoupon = action.payload;
      })
      .addCase(fetchAsyncAllCoupon.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      // create coupon
      .addCase(createAsyncCoupon.pending, (state) => {
        state.status = "loading";
        state.error = null;
      })
      .addCase(createAsyncCoupon.fulfilled, (state, action) => {
        // state.allCoupon.push(action.payload);
        state.status = "succeeded";
      })
      .addCase(createAsyncCoupon.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      // Handle updateAsyncCoupon
      .addCase(updateAsyncCoupon.pending, (state) => {
        state.status = "loading";
        state.error = null;
      })
      .addCase(updateAsyncCoupon.fulfilled, (state, action) => {
        const updatedCoupon = action.payload;
        if (updatedCoupon && updatedCoupon.id) {
          state.allCoupon = state.allCoupon.map((c) =>
            c.id === updatedCoupon.id ? updatedCoupon : c
          );
        }
      })
      .addCase(updateAsyncCoupon.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      // Handle deleteAsyncCoupon
      .addCase(deleteAsyncCoupon.pending, (state) => {
        state.status = "loading";
        state.error = null;
      })
      .addCase(deleteAsyncCoupon.fulfilled, (state, action) => {
        const couponId = action.payload;
        state.allCoupon = state.allCoupon.filter(
          (c) => c.id !== couponId
        );
        state.status = "succeeded";
      })
      .addCase(deleteAsyncCoupon.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      });
  },
});

export default couponSlice.reducer;
