import * as yup from "yup";

// YUP schema
export const productSchema = yup.object().shape({
  name: yup
    .string()
    .required("Name is required")
    .min(3, "Name must be at least 3 characters long"),
  title: yup
    .string()
    .required("Title is required")
    .min(3, "Title must be at least 3 characters long"),
  phone: yup
    .string()
    .matches(
      /^(70|71|76|78|79|81|03|3)\d{6}$/,
      "Phone Number Must be Lebanese Number"
    )
    .required("Required"),
  email: yup
    .string()
    .matches(
      /^[a-zA-Z0-9]*\.?[a-zA-Z0-9]*$/,
      "Email must not contain any special characters or spaces"
    )
    .required("Email is required"),
  title: yup
    .string()
    .required("Title is required")
    .min(3, "Title must be at least 3 characters long"),
  description: yup.string().required("Description is required"),
  dateOfBirth: yup.date().required("Date of Birth is required"),
  dateOfEmployment: yup.date().required("Date of Employment is required"),
});

export const serviceSchema = yup.object().shape({
  name: yup
    .string()
    .required("Name is required")
    .min(3, "Name must be at least 3 characters long"),
  description: yup.string().required("Description is required"),
  waitlistPriceInDollar: yup.number().required("Waitlist Price is required"),
  reservationPriceInDollar: yup
    .number()
    .required("Reservation Price is required"),
  durationInMinutes: yup.number().required("Duration in Minutes is required"),
});

export const GeneralSchema = yup.object().shape({
  title: yup
    .string()
    .required("Title is required")
    .min(3, "Title must be at least 3 characters long"),
  subTitle: yup
    .string()
    .required("Sub Title is required")
    .min(3, "Sub Title must be at least 3 characters long"),
});

export const categoryServicesSchema = yup.object().shape({
  name: yup
    .string()
    .required("Name is required")
    .min(3, "Name must be at least 3 characters long"),
});

// Password validation for Confirm Password page
export const passwordSchema = yup.object().shape({
  password: yup
    .string()
    .required("Password is required")
    .min(8, "Password must be at least 8 characters long")
    .max(30, "Password must be at most 30 characters long")
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&_])[A-Za-z\d@$!%*?&_]{8,30}$/,
      "Password must contain at least one uppercase letter, one lowercase letter, one number, and one special character"
    ),
  // confirmPassword: yup
  //   .string()
  //   .required("Confirm Password is required")
  //   .oneOf([yup.ref("password"), null], "Passwords must match"),
});
