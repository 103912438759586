import React, { useState, useEffect } from "react";
import { Dropdown } from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";

import LogoutPage from "./Logout";
import { SVGICON } from "../../constant/theme";
import { fetchShopDetails } from "../../../store/shopDetails/ShopDetailsSlice";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { fetchAsyncUnread } from "../../../store/Message/InboxSlice";

const Header = () => {
  const [headerFix, setheaderFix] = useState(false);
  const [header, setHeader] = useState("Dashboard");
  const { pathname } = useLocation();
  const dispatch = useDispatch();

  useEffect(() => {
    window.addEventListener("scroll", () => {
      setheaderFix(window.scrollY > 50);
    });
  }, []);

  useEffect(() => {
    dispatch(fetchAsyncUnread());
  }, [dispatch]);

  const allUnreadMessage = useSelector((state) => state.Inbox.unReadInbox);

  // Header name dynamic with pathname
  useEffect(() => {
    if (pathname === "/") {
      setHeader("Dashboard");
    } else if (pathname === "/brands") {
      setHeader("Brands");
    } else if (pathname === "/products") {
      setHeader("product");
    } else if (pathname === "/categories") {
      setHeader("Category Page");
    } else if (pathname === "/collections") {
      setHeader("Collections");
    } else if (pathname === "/tags") {
      setHeader("Tags");
    } else if (pathname === "/order-page") {
      setHeader("Orders");
    } else if (pathname === "/discount-coupon") {
      setHeader("Discount");
    } else if (pathname === "/customers-page") {
      setHeader("Customer");
    } else if (pathname === "/general-page") {
      setHeader("General");
    } else if (pathname === "/list-of-shops") {
      setHeader("Shops");
    }
  }, [pathname]);

  useEffect(() => {
    dispatch(fetchShopDetails());
  }, [dispatch]);
  const shopDetails = useSelector((state) => state.ShopDetails.shopDetails);

  return (
    <div className={`header zIndex ${headerFix ? "is-fixed" : ""}`}>
      <div className="header-content">
        <nav className="navbar navbar-expand">
          <div className="collapse navbar-collapse justify-content-between">
            <div className="header-left">
              <div
                className="dashboard_bar"
                style={{ textTransform: "capitalize" }}
              >
                {header}
              </div>
            </div>
            <div className="header-right d-flex align-items-center">
              <ul className="navbar-nav ">
                <Link
                  as="li"
                  className="nav-item dropdown notification_dropdown"
                  to="/inbox-page"
                >
                  <Dropdown.Toggle
                    variant=""
                    as="a"
                    className="nav-link  i-false c-pointer"
                  >
                    {SVGICON.MailIcon}
                    {allUnreadMessage?.length > 0 ? (
                      <span className="badge light text-white bg-secondary rounded-circle">
                        {allUnreadMessage?.length}
                      </span>
                    ) : null}
                  </Dropdown.Toggle>
                </Link>

                <li className="nav-item ps-3">
                  <Dropdown className="header-profile2">
                    <Dropdown.Toggle className="nav-link i-false" as="div">
                      <div className="header-info2 d-flex align-items-center">
                        <div className="header-media object-fit-cover  object-fit-center">
                          <img src={shopDetails?.url} alt="" />
                        </div>
                      </div>
                    </Dropdown.Toggle>
                    <Dropdown.Menu align="end">
                      <div className="card border-0 mb-0">
                        <div className="card-header py-2">
                          <div className="products">
                            <img
                              src={shopDetails?.url}
                              className="avatar avatar-md"
                              alt=""
                            />
                            <div>
                              <h6>Al-Khatib</h6>
                              {/* <span>{data?.title}</span> */}
                            </div>
                          </div>
                        </div>
                        <div className="card-body px-0 py-2">
                          <Link to={"#"} className="dropdown-item ai-icon">
                            {SVGICON.UserSvg}{" "}
                            <span className="ms-2">Profile</span>
                          </Link>
                        </div>
                        <LogoutPage />
                      </div>
                    </Dropdown.Menu>
                  </Dropdown>
                </li>
              </ul>
            </div>
          </div>
        </nav>
      </div>
    </div>
  );
};

export default Header;
