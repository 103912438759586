import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import AddProductOrderModal from "./OrderModals/AddProductOrderModal";
import { useSelector } from "react-redux";
import {
  deleteCartProduct,
  removeAllCartItems,
} from "../../../store/addProductSlice";
import { useDispatch } from "react-redux";
import { createAsyncOrder } from "../../../store/order/orderSlice";
import Select from "react-select";
import { fetchAsyncAllDelivery } from "../../../store/DeliveryZones/DeliveryZonesSlice";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";

const AddOrder = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [addProductModal, setAddProductModal] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);
  const [btnLoading, setBtnLoading] = useState(false);

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  const cartItems = useSelector((state) => state.ProductSlice.cartItems);

  // Calculate the total amount
  const totalAmount = cartItems.reduce((total, item) => {
    const price =
      item.mainPrice === 0 ? item.productSize.price : item.mainPrice;

    return total + item.cartQuantity * price;
  }, 0);

  useEffect(() => {
    dispatch(fetchAsyncAllDelivery());
  }, [dispatch]);

  const delivery = useSelector((state) => state.Delivery.allDelivery);

  const deliveryOptions = delivery?.map((info) => ({
    value: info.id,
    label: `${info.areaName}`,
    deliveryFee: info.deliveryFee,
  }));

  const onSubmit = async (data) => {
    try {
      setBtnLoading(true);
      // data.total = Number(data.total);
      data.total = totalAmount;
      data.deliveryFee = Number(selectedOption?.deliveryFee);
      data.products = cartItems.map((item) => ({
        id: item.id,
        size: item.productSize?.name,
        color: item.productColor?.name,
        quantity: item.cartQuantity,
      }));

      if (selectedOption === null) {
        Swal.fire({
          position: "top-end",
          title: "Please select a delivery zone",
          showConfirmButton: false,
          icon: "info",
          timer: 1500,
          toast: true,
        });
        return;
      }

      if (cartItems?.length === 0) {
        Swal.fire({
          position: "top-end",
          title: "Please select atleast one product",
          showConfirmButton: false,
          icon: "info",
          timer: 1500,
          toast: true,
        });
        return;
      }

      await dispatch(createAsyncOrder({ data: data }));
      await dispatch(removeAllCartItems());
      await Swal.fire({
        icon: "success",
        title: "Order added successfully",
        showConfirmButton: false,
        timer: 1500,
      });
      await navigate("/order-page");
      reset();
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Something went wrong!",
      });
    } finally {
      setBtnLoading(false);
    }
  };

  const handleAddProductModal = () => {
    setAddProductModal(true);
  };

  const handleDeleteProduct = (item) => {
    dispatch(deleteCartProduct(item));
  };

  return (
    <div>
      <div className="container">
        <div className="row">
          <div className="col-xl-12">
            <div className="card mt-2">
              <div className="card-body">
                <div className="mb-3">
                  <h4 className="card-title fs-3 text-center">Add Order</h4>
                </div>
                <form onSubmit={handleSubmit(onSubmit)}>
                  <div className="form-group">
                    <label className="form-label">First Name</label>
                    <input
                      type="text"
                      className="form-control mb-3"
                      placeholder="First Name"
                      {...register("firstName", {
                        required: "First Name is required",
                      })}
                    />
                    {errors.firstName && (
                      <p className="text-danger">{errors.firstName.message}</p>
                    )}
                    <label className="form-label">Last Name</label>
                    <input
                      type="text"
                      className="form-control mb-3"
                      placeholder="Last Name"
                      {...register("lastName", {
                        required: "Last Name is required",
                      })}
                    />
                    {errors.lastName && (
                      <p className="text-danger">{errors.lastName.message}</p>
                    )}

                    <label className="form-label">Phone</label>
                    <input
                      type="number"
                      className="form-control mb-3"
                      placeholder="Enter Phone Number"
                      {...register("phone", {
                        required: "phone number is required",
                        pattern: {
                          value:
                            /^(\961|0)?(3|70|71|76|78|79|81|83|84|85|961|1|2|3|4|5|6|7|8|9)\d{6,7}$/,
                          message: "Please enter a valid lebanese number",
                        },
                      })}
                    />
                    {errors.phone && (
                      <p className="text-danger">{errors.phone.message}</p>
                    )}
                    <label className="form-label">
                      Street Address is required
                    </label>
                    <input
                      type="text"
                      className="form-control mb-3"
                      placeholder="Street Address"
                      {...register("streetAddress", {
                        required: "streetAddress",
                      })}
                    />
                    {errors.streetAddress && (
                      <p className="text-danger">
                        {errors.streetAddress.message}
                      </p>
                    )}
                    <label className="form-label">City</label>
                    <input
                      type="text"
                      className="form-control mb-3"
                      placeholder="Street Address"
                      {...register("city", {
                        required: "City is required",
                      })}
                    />
                    {errors.city && (
                      <p className="text-danger">{errors.city.message}</p>
                    )}
                    <label className="form-label">Country</label>
                    <input
                      type="text"
                      defaultValue={"Lebanon"}
                      readOnly
                      className="form-control mb-3"
                      placeholder="Street Address"
                      {...register("country", {
                        required: "country",
                      })}
                    />
                    {errors.country && (
                      <p className="text-danger">{errors.country.message}</p>
                    )}
                    <div className="row"></div>
                    <div className="row">
                      <div className="col-6">
                        <label className="form-label">Delivery Zone</label>
                        <Select
                          onChange={setSelectedOption}
                          value={selectedOption}
                          options={deliveryOptions}
                          style={{
                            lineHeight: "40px",
                            color: "#7e7e7e",
                            paddingLeft: " 15px",
                          }}
                        />
                      </div>
                    </div>
                    {cartItems &&
                      cartItems.map((item) => (
                        <div
                          key={item.id}
                          className="border d-inline-block p-3 rounded-lg light mt-5 mx-2"
                        >
                          <h4 className="mt-3">{item?.name}</h4>
                          {item?.cartQuantity !== "" && (
                            <p className="mb-0">
                              Quantity: {item?.cartQuantity}
                            </p>
                          )}
                          {item?.productSize !== "" ? (
                            <p className="mb-0">
                              Price: {item?.productSize?.price}
                            </p>
                          ) : (
                            <p className="mb-0">Price: {item?.mainPrice}</p>
                          )}

                          {item?.productColor !== "" ? (
                            <span
                              style={{
                                width: "20px",
                                height: "20px",
                                borderRadius: "50%",
                                border: "1px solid #000",
                                display: "inline-block",
                                backgroundColor: item?.productColor?.colorCode,
                              }}
                            ></span>
                          ) : (
                            <div className="d-inline-flex align-items-center">
                              <span
                                style={{
                                  width: "20px",
                                  height: "20px",
                                  borderRadius: "50%",
                                  border: "1px solid #000",
                                  display: "inline-block",
                                  // backgroundColor: item?.productColor?.colorCode,
                                }}
                              ></span>
                              <span className="ms-2 text-info">
                                *Color is not available
                              </span>
                            </div>
                          )}
                          <div className="d-flex justify-content-between">
                            {item?.productColor !== "" ? (
                              <p className=" border px-2 rounded-4">
                                {item?.productSize?.name}
                              </p>
                            ) : (
                              <p className="text-info">
                                *Size is not available
                              </p>
                            )}
                            <button
                              type="button"
                              onClick={() => handleDeleteProduct(item)}
                              className="btn btn-outline-danger"
                            >
                              Remove
                            </button>
                          </div>
                        </div>
                      ))}
                  </div>
                  <div>
                    <button
                      type="button"
                      onClick={() => handleAddProductModal()}
                      className="btn btn-outline-primary mt-3"
                    >
                      Add Product
                    </button>{" "}
                  </div>
                  <div className="modal-footer mt-5">
                    {btnLoading ? (
                      <button
                        className="btn btn-primary"
                        type="button"
                        disabled
                      >
                        <span
                          className="spinner-border spinner-border-sm"
                          role="status"
                          aria-hidden="true"
                        ></span>
                        Loading...
                      </button>
                    ) : (
                      <button type="submit" className="btn btn-primary">
                        Save
                      </button>
                    )}
                  </div>
                </form>

                <AddProductOrderModal
                  show={addProductModal}
                  onHide={() => setAddProductModal(false)}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddOrder;
