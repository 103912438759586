import { get, patch } from "aws-amplify/api";
import { amplifyConfigs } from "../../config";
import Swal from "sweetalert2";

export async function getShopDetails() {
  try {
    const restOperation = get({
      apiName: amplifyConfigs.apiName,
      path: "/shop-details",
    });
    const response = await restOperation.response;
    return response.body.json();
  } catch (error) {
    
  }
}

export async function postShopDetails({ data, shopId }) {
  try {
    const restOperation = patch({
      apiName: amplifyConfigs.apiName,
      path: `/shop-details/${shopId}`,
      options: {
        body: data,
      },
    });
    await restOperation.response;
    Swal.fire({
      icon: "success",
      title: "Success!",
      text: "Your info has been updated successfully.",
    });
  } catch (error) {
    
    Swal.fire({
      icon: "error",
      title: "Error!",
      text: error.message,
    });
  }
}

// Social Links
export async function postSocialLinks({ data }) {
  
  try {
    const restOperation = patch({
      apiName: amplifyConfigs.apiName,
      path: `/shop-details/social-links`,
      options: {
        body: data,
      },
    });
    const response = await restOperation.response;
    Swal.fire({
      icon: "success",
      title: "Success!",
      text: "Your social links have been updated successfully.",
    });
    return response.body.json();
  } catch (error) {
    
    Swal.fire({
      icon: "error",
      title: "Error!",
      text: error.message,
    });
  }
}

// Get Social Links
export async function getSocialLinks() {
  try {
    const restOperation = get({
      apiName: amplifyConfigs.apiName,
      path: "/shop-details/social-links",
    });
    const response = await restOperation.response;
    return response.body.json();
  } catch (error) {
    
  }
}
