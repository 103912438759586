import { SVGICON } from "../../constant/theme";

export const MenuList = [
  {
    title: "Dashboard",
    to: "/",
    iconStyle: SVGICON.Home,
  },
  {
    title: "Products",
    iconStyle: SVGICON.Inventory,
    to: "products",
  },
  {
    title: "Categories",
    iconStyle: SVGICON.Category,
    to: "categories",
  },
  {
    title: "Collections",
    iconStyle: SVGICON.Collection,
    to: "collections",
  },
  {
    title: "Brands",
    iconStyle: SVGICON.Brand,
    to: "brands",
  },
  {
    title: "Tags",
    iconStyle: SVGICON.Tags,
    to: "tags",
  },
  {
    title: "Orders",
    iconStyle: SVGICON.SvgMenu,
    to: "/order-page",
  },
  {
    title: "discount-coupon",
    iconStyle: SVGICON.Table,
    to: "discount-coupon",
  },
  {
    title: "Customers",
    iconStyle: SVGICON.Customer,
    to: "customers-page",
  },
  {
    title: "Settings",
    iconStyle: SVGICON.General,
    to: "general-page",
  },
];

export const MenuList2 = [
  {
    title: "Dashboard",
    to: "/",
    iconStyle: SVGICON.Home,
  },

  {
    title: "Products",
    classsChange: "mm-collapse",
    iconStyle: SVGICON.Inventory,
    content: [
      {
        title: "Products",
        to: "/products",
      },
      {
        title: "Add Product",
        to: "add/products",
      },
    ],
  },
  {
    title: "Categories",
    iconStyle: SVGICON.History,
    to: "categories",
  },
  {
    title: "Collections",
    iconStyle: SVGICON.History,
    to: "collections",
  },
  {
    title: "Brands",
    iconStyle: SVGICON.History,
    to: "brands",
  },
  {
    title: "Tags",
    iconStyle: SVGICON.History,
    to: "tags",
  },
  {
    title: "List of Shops",
    iconStyle: SVGICON.History,
    to: "list-of-shops",
  },
  {
    title: "Orders",
    iconStyle: SVGICON.History,
    to: "/order-page",
  },
  {
    title: "discount-coupon",
    iconStyle: SVGICON.Table,
    to: "discount-coupon",
  },
  {
    title: "Customers",
    iconStyle: SVGICON.Customer,
    to: "customers-page",
  },
  {
    title: "Settings",
    iconStyle: SVGICON.General,
    to: "general-page",
  },
];
