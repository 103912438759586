import React, { useEffect } from "react";
import { Tab } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import Loading from "../../components/Loading";
import { fetchAsyncOutbox } from "../../../store/Message/OutBoxSlice";

// const allSentMessage = {
//   messages: [
//     {
//       id: 1,
//       name: "John Doe",
//       subject: "Lorem ipsum dolor sit amet",
//       createdAt: 1618417200, // Unix timestamp
//       isRead: false,
//     },
//     {
//       id: 2,
//       name: "Alice Smith",
//       subject: "Consectetur adipiscing elit",
//       createdAt: 1618327200, // Unix timestamp
//       isRead: true,
//     },
//     // Add more fake messages as needed
//   ],
// };

const SentMessages = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchAsyncOutbox());
  }, [dispatch]);
  const allSentMessage = useSelector((state) => state.Outbox.allOutBox);





  return (
    <>
      <Tab.Container defaultActiveKey="Import">
        {/* <Tab.Content>
          <div role="toolbar" className="toolbar ms-1 ms-sm-0">
            <div className="saprat ps-3">
              <div className="mail-tools ms-0">
                <h5 className="text-primary mb-0 mt-1">Sent messasges</h5>
              </div>
            </div>
          </div>
          <div className="email-list bg-white">
            <div className="message">
              <div>
                <div className="col-mail col-mail-2 margin-start">
                  <div className="hader">96145654565</div>
                  <div className="subject">Yor issues is solved</div>
                  <div className="date">15-12-2024</div>
             
                </div>
              </div>
            </div>
          </div>
        </Tab.Content> */}
        <Tab.Content>
          <div className="email-list">
            <div>
              <div className="toolbar bg-white ms-1 ms-sm-0">
                <div className="saprat ps-3">
                  <div className="mail-tools  ms-0">
                    <h5 className="text-primary mb-0 mt-1">
                      All Sent messages
                    </h5>
                  </div>
                </div>
              </div>
            </div>
            {allSentMessage?.messages?.length === 0 && (
              <div className="text-center mt-5">
                No Message message in this page
              </div>
            )}
            {allSentMessage?.map((data, ind) => (
              <div className={`message`} key={ind}>
                <div>
                  <div className="d-flex message-single">
                    <div className="col-mail col-mail-2">
                      <div className="text-primary fw-semibold">
                        {data && typeof data.message === "string"
                          ? data.message.slice(0, 50)
                          : ""}
                      </div>
                      <div className="date text-primary">
                      {new Date(data.createdAt).toLocaleString()}{" "}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </Tab.Content>
      </Tab.Container>
    </>
  );
};

export default SentMessages;
