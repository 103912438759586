import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  createAssociatedProduct,
  deleteAssociateProduct,
  deleteProduct,
  getAssociatedProduct,
  getProducts,
  getSingleProduct,
  postProducts,
  updateProduct,
} from "./productAPI";

// fetching all products
export const fetchAsyncAllProduct = createAsyncThunk(
  "product/fetchProducts",
  async () => {
    const collections = await getProducts();
    return collections;
  }
);

// single product
export const fetchAsyncSingleProduct = createAsyncThunk(
  "product/fetchSingleProduct",
  async (id) => {
    const collections = await getSingleProduct(id);
    return collections;
  }
);

// creating a tag
export const createAsyncNewProduct = createAsyncThunk(
  "product/createProduct",
  async (data) => {
    const response = await postProducts(data);
    return response;
  }
);

// Update Product
export const updateAsyncProduct = createAsyncThunk(
  "product/updateSingleProduct",
  async (data) => {
    const response = await updateProduct(data);
    return response;
  }
);

// Associated Product
export const fetchAsyncAssociatedProduct = createAsyncThunk(
  "product/fetchAssociatedProduct",
  async (id) => {
    const associated = await getAssociatedProduct(id);
    return associated;
  }
);

// Create Associated Product
export const createAsyncAssociatedProduct = createAsyncThunk(
  "associatedProduct/createAssociatedProduct",
  async (id) => {
    const associated = await createAssociatedProduct(id);
    return associated;
  }
);

// Delete Product
export const deleteAsyncProduct = createAsyncThunk(
  "product/deleteProduct",
  async (id) => {
    const collections = await deleteProduct(id);
    return collections;
  }
);

// Delete Associated Product
export const deleteAsyncAssociatedProduct = createAsyncThunk(
  "associatedProduct/deleteAssociatedProduct",
  async (data) => {
    await deleteAssociateProduct(data);
    return data.associatedProductId;
  }
);

// Initial state for the tag slice
const initialState = {
  allProduct: [],
  associatedProduct: [],
  associatedStatus: "idle",
  singleProduct: [],
  status: "idle",
  singleProductStatus: "idle",
  error: null,
};

// tag slice
const productSlice = createSlice({
  name: "product",
  initialState,
  extraReducers: (builder) => {
    builder
      // Handle fetchAsyncAllProduct
      .addCase(fetchAsyncAllProduct.pending, (state) => {
        state.status = "loading";
        state.error = null;
      })
      .addCase(fetchAsyncAllProduct.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.allProduct = action.payload;
      })
      .addCase(fetchAsyncAllProduct.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      // Handle fetchAsyncSingleCategory
      .addCase(fetchAsyncSingleProduct.pending, (state) => {
        state.singleProductStatus = "loading";
        state.error = null;
      })
      .addCase(fetchAsyncSingleProduct.fulfilled, (state, action) => {
        state.singleProductStatus = "succeeded";
        state.singleProduct = action.payload;
      })
      .addCase(fetchAsyncSingleProduct.rejected, (state, action) => {
        state.singleProductStatus = "failed";
        state.error = action.error.message;
      })
      // Handle createAsyncCategory
      .addCase(createAsyncNewProduct.pending, (state) => {
        state.status = "loading";
        state.error = null;
      })
      .addCase(createAsyncNewProduct.fulfilled, (state, action) => {
        // state.allProduct.push(action.payload);
        state.status = "succeeded";
      })
      .addCase(createAsyncNewProduct.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      // Delete Product
      .addCase(deleteAsyncProduct.pending, (state) => {
        state.status = "loading";
        state.error = null;
      })
      .addCase(deleteAsyncProduct.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.allProduct = state.allProduct.filter(
          (product) => product.id !== action.payload.id
        );
      })
      .addCase(deleteAsyncProduct.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      // Delete Associated Product
      .addCase(deleteAsyncAssociatedProduct.pending, (state) => {
        state.associatedStatus = "loading";
        state.error = null;
      })
      .addCase(deleteAsyncAssociatedProduct.fulfilled, (state, action) => {
        state.associatedStatus = "succeeded";
        if (state.singleProduct && state.singleProduct.associatedProducts) {
          state.singleProduct.associatedProducts =
            state.singleProduct.associatedProducts.filter(
              (product) => product.id !== action.payload
            );
        } else {
        }
        // state.singleProduct.associatedProducts =
        //   state.singleProduct?.associatedProducts?.filter(
        //     (product) => product.id !== action.payload
        //   );
      })
      .addCase(deleteAsyncAssociatedProduct.rejected, (state, action) => {
        state.associatedStatus = "failed";
        state.error = action.error.message;
      })
      // get associated product
      .addCase(fetchAsyncAssociatedProduct.pending, (state) => {
        state.status = "loading";
        state.error = null;
      })
      .addCase(fetchAsyncAssociatedProduct.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.associatedProduct = action.payload;
      })
      .addCase(fetchAsyncAssociatedProduct.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      // create associated product
      .addCase(createAsyncAssociatedProduct.pending, (state) => {
        state.associatedStatus = "loading";
        state.error = null;
      })
      .addCase(createAsyncAssociatedProduct.fulfilled, (state, action) => {
        state.associatedStatus = "succeeded";
        
      })
      .addCase(createAsyncAssociatedProduct.rejected, (state, action) => {
        state.associatedStatus = "failed";
        state.error = action.error.message;
      })
    
  },
});

export default productSlice.reducer;
