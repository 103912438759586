import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { IMAGES } from "../../constant/theme";
import {
  createAsyncCategory,
  fetchAsyncAllCategory,
  updateAsyncCategory,
} from "../../../store/category/categorySlice";
import { Form, Spinner } from "react-bootstrap";
import Swal from "sweetalert2";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { FaArrowCircleLeft } from "react-icons/fa";

const AddCategory = () => {
  const dispatch = useDispatch();
  const categoryId = useParams().id;
  const navigate = useNavigate();
  const location = useLocation();
  const [icon, setIcon] = useState("" || IMAGES.User);
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [base64, setBase64] = useState("");
  const [nameError, setNameError] = useState("");
  const [descriptionError, setDescriptionError] = useState("");
  const [formValid, setFormValid] = useState(false);
  const [loading, setLoading] = useState(false);

  const editMode = location.pathname.includes("edit");

  useEffect(() => {
    if (categoryId) {
      dispatch(fetchAsyncAllCategory());
    }
  }, [categoryId, dispatch]);

  const status = useSelector((state) => state.Category.status);
  const categoryToEdit = useSelector((state) =>
    state.Category.allCategory?.find((c) => c?.id === categoryId)
  );

  useEffect(() => {
    if (categoryId && editMode) {
      setDescription(categoryToEdit?.description);
      setName(categoryToEdit?.name);
      setIcon(categoryToEdit?.url);
    }
  }, [
    categoryId,
    editMode,
    categoryToEdit?.name,
    categoryToEdit?.description,
    categoryToEdit?.url,
  ]);

  const validateName = () => {
    if (name.trim().length < 3) {
      setNameError("Name must be at least 3 characters long.");
      return false;
    } else {
      setNameError("");
      return true;
    }
  };

  const validateDescription = () => {
    if (description.trim().length < 3) {
      setDescriptionError("Description must be at least 3 characters long.");
      return false;
    } else {
      setDescriptionError("");
      return true;
    }
  };

  const handleNameChange = (e) => {
    setName(e.target.value);
    validateName();
  };

  const handleDescriptionChange = (e) => {
    setDescription(e.target.value);
    validateDescription();
  };

  // Function to handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!editMode && !base64) {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Please fill the icon in the form correctly.",
      });
      return;
    }

    try {
      setLoading(true);
      const formData = {
        name,
        description,
      };
      if (base64) {
        formData.icon = base64;
      }
      if (editMode) {
        await dispatch(updateAsyncCategory({ data: formData, id: categoryId }));
      } else {
        await dispatch(createAsyncCategory(formData));
      }
      setName("");
      setDescription("");
      setBase64("");
      navigate("/categories");
    } catch (error) {
      console.error("Error creating category:", error);
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "An error occurred while creating the category. Please try again later.",
      });
    } finally {
      setLoading(false);
      navigate("/categories");
    }
  };

  const handleFileChange = async (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();
    reader.onloadend = () => {
      const fileNameClean = reader.result.split("base64,")[1];
      setBase64(fileNameClean);
      setIcon(reader.result);
    };
    reader.readAsDataURL(file);
  };

  useEffect(() => {
    if (name && description) {
      setFormValid(true);
    } else {
      setFormValid(false);
    }
  }, [name, description]);

  return (
    <>
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-12">
            <div className="card profile-card card-bx mb-30">
              <div className="card-header">
                <h6 className="title">
                  <Link to="/categories">
                    <FaArrowCircleLeft className="me-2" />
                  </Link>
                  Add Category
                </h6>
              </div>
              <div className="p-5">
                <div className="author-profile">
                  <div className="author-media">
                    <img src={icon} className="" alt="" />
                    <div
                      className="upload-link"
                      data-toggle="tooltip"
                      data-placement="right"
                      data-original-title="update"
                    >
                      <input
                        type="file"
                        className="update-flie"
                        onChange={handleFileChange}
                      />
                      <i className="fa fa-camera"></i>
                    </div>
                  </div>
                </div>
              </div>
              <Form className="profile-form" onSubmit={handleSubmit}>
                <div className="card-body row">
                  <Form.Group className="mb-3 col-sm-6 m-b30" controlId="name">
                    <Form.Label>Category Name</Form.Label>
                    <Form.Control
                      type="text"
                      autoComplete="off"
                      value={name}
                      onChange={handleNameChange}
                      isInvalid={nameError !== ""}
                    />
                    <Form.Control.Feedback type="invalid">
                      {nameError}
                    </Form.Control.Feedback>
                  </Form.Group>

                  <Form.Group
                    className="mb-3 col-sm-6 m-b30"
                    controlId="description"
                  >
                    <Form.Label>Description</Form.Label>
                    <Form.Control
                      type="text"
                      autoComplete="off"
                      value={description}
                      onChange={handleDescriptionChange}
                      isInvalid={descriptionError !== ""}
                    />
                    <Form.Control.Feedback type="invalid">
                      {descriptionError}
                    </Form.Control.Feedback>
                  </Form.Group>


                </div>
                <div className="card-footer">
                  <div></div>
                  <button
                    disabled={!formValid}
                    type="submit"
                    className="btn btn-primary"
                    onClick={() => {
                      Swal.fire({
                        position: "top-end",
                        title: "Wait a moment, please",
                        showConfirmButton: false,
                        icon: "info",
                        timer: 2000,
                        toast: true,
                      });
                    }}
                  >
                    {loading === true ? (
                      <div className="d-flex align-items-center justify-content-center ">
                        <span className="me-2">Initiated</span>
                        <Spinner animation="border" size="sm" />
                      </div>
                    ) : (
                      "Save"
                    )}
                  </button>
                </div>
              </Form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddCategory;
