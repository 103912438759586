import { get } from "aws-amplify/api";
import { amplifyConfigs } from "../../config";


// Get all customers
export async function getCustomers() {
  try {
    const restOperation = get({
      apiName: amplifyConfigs.apiName,
      path: "/customers",
    });
    const response = await restOperation.response;
    return response.body.json();
  } catch (error) {
    
  }
}

// get customer with Account
export async function getCustomerWithAccount() {
  try {
    const restOperation = get({
      apiName: amplifyConfigs.apiName,
      path: `/customers/withAccount`,
    });
    const response = await restOperation.response;
    return response.body.json();
  } catch (error) {
    
  }
}

// get customer without Account
export async function getCustomerWithoutAccount() {
  try {
    const restOperation = get({
      apiName: amplifyConfigs.apiName,
      path: `/customers/withoutAccount`,
    });
    const response = await restOperation.response;
    return response.body.json();
  } catch (error) {
    
  }
}