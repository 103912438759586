import React, { useEffect, useState } from "react";
import { Card } from "react-bootstrap";
import { useSelector } from "react-redux";

const fakeData = [
  {
    url: "https://images.unsplash.com/photo-1583743814966-8936f5b7be1a?q=80&w=1974&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    name: "T-Shirt",
    price: 19.99,
    stock: 50,
  },
  {
    url: "https://images.unsplash.com/photo-1581605405669-fcdf81165afa?q=80&w=1974&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    name: "Backpack",
    price: 79.99,
    stock: 30,
  },
  {
    url: "https://images.unsplash.com/photo-1632200004922-bc18602c79fc?q=80&w=1974&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    name: "Wireless Earbuds",
    price: 39.99,
    stock: 100,
  },
  {
    url: "https://images.unsplash.com/photo-1602143407151-7111542de6e8?q=80&w=1974&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    name: "Water Bottle",
    price: 24.99,
    stock: 40,
  },
];

const AllProduct = () => {

  return (
    <div className="container">
      <div className="row justify-content-center gap-3">
        {fakeData?.map((item) => (
          <>
            <Card
              key={item.id}
              className="col-12 col-md-4 col-lg-3 main-card-custom"
            >
              <Card.Img className="card-image-custom" src={item.url} />
              <Card.Body className="card-body-custom">
                <h4>{item.name}</h4>
                <Card.Text>
                  <p className="mb-0">
                    <span className="fw-bold">Price Per Unit:</span> $
                    {item.price}
                  </p>
                  <p>
                    <span className="fw-bold">Qnt inside the stock:</span>{" "}
                    {item.stock}
                  </p>
                </Card.Text>
                <button className="btn btn-primary">Edit</button>
              </Card.Body>
            </Card>
          </>
        ))}
      </div>
    </div>
  );
};

export default AllProduct;
