import React, { useEffect, useMemo, useState } from "react";
import { Modal, Nav, Spinner, Tab } from "react-bootstrap";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import {
  createAsyncAssociatedProduct,
  deleteAsyncAssociatedProduct,
  deleteAsyncProduct,
  fetchAsyncAllProduct,
  fetchAsyncAssociatedProduct,
  fetchAsyncSingleProduct,
} from "../../../store/product/productSlice";
import { useSelector } from "react-redux";
import Swal from "sweetalert2";
import { IoMdInformationCircleOutline } from "react-icons/io";
import { FaTrash } from "react-icons/fa";
import Loading from "../../components/Loading";

const SingleProduct = () => {
  const productId = useParams().id;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isModalOpen, setModalOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredItem, setFilteredItem] = useState([]);

  // Fetch all products
  useEffect(() => {
    dispatch(fetchAsyncAllProduct());
    dispatch(fetchAsyncSingleProduct(productId));
    dispatch(fetchAsyncAssociatedProduct({ productId: productId }));
  }, [dispatch, productId]);

  // Get data from store
  const associated = useSelector((state) => state.Product.associatedProduct);
  const singleProduct = useSelector((state) => state.Product.singleProduct);
  const allProduct = useSelector((state) => state.Product.allProduct);
  const loading = useSelector((state) => state.Product.singleProductStatus);
  const associatedStatus = useSelector(
    (state) => state.Product.associatedStatus
  );
  const allImages = singleProduct?.images;

  // extract already associated products id
  const associatedId = useMemo(
    () => associated?.map((p) => p.id),
    [associated]
  );

  // availabe products can be added as associate
  const availableToAssociate = useMemo(
    () =>
      allProduct?.filter(
        (i) => !associatedId?.includes(i.id) && i.id !== productId
      ),
    [allProduct, associatedId, productId]
  );

  // add product function
  const handleAddProduct = async (associatedId) => {
    setModalOpen(false);
    try {
      await dispatch(
        createAsyncAssociatedProduct({
          productId: productId,
          associatedProductId: associatedId,
        })
      );
      await dispatch(fetchAsyncAssociatedProduct({ productId: productId }));
    } catch (error) {}
  };

  // delete product function
  const handleDeleteProduct = async () => {
    const result = await Swal.fire({
      title: "Are you sure for delete?",
      text: "Once deleted, you will not be able to recover this imaginary file!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#dd6b55",
      cancelButtonColor: "#aaa",
      confirmButtonText: "Yes, delete it!",
    });

    if (result.isConfirmed) {
      try {
        await dispatch(deleteAsyncProduct(productId));
        navigate("/products");
        await dispatch(fetchAsyncSingleProduct(productId));
      } catch (error) {
        Swal.fire("Error", "An error occurred while deleting.", "error");
        console.error("Error deleting service:", error);
      }
    }
  };

  // search product to associate
  useEffect(() => {
    const filtered = availableToAssociate?.filter((item) => {
      const searchTermLower = searchTerm?.toLowerCase();
      const nameIncludes = item?.name?.toLowerCase().includes(searchTermLower);
      return nameIncludes;
    });
    setFilteredItem(filtered);
  }, [searchTerm, availableToAssociate]);

  // associated product delete funciton
  const handleDeleteAssociateProduct = async (associatedProductId) => {
    const dataToSend = {
      productId: productId,
      associatedProductId: associatedProductId,
    };

    const result = await Swal.fire({
      title: "Are you sure?",
      text: "Do you want to delete this associated product?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    });

    if (result.isConfirmed) {
      try {
        await dispatch(deleteAsyncAssociatedProduct(dataToSend));
        await dispatch(fetchAsyncAssociatedProduct({ productId: productId }));
      } catch (error) {}
    }
  };

  return (
    <>
      <div className="container mh-auto">
        <div className="row">
          <div className="col-lg-12">
            {loading === "loading" ? (
              <Loading status={loading} />
            ) : (
              <>
                {" "}
                <div className="card">
                  <div className="card-body">
                    <div className="row">
                      <div className="col-xl-4 col-lg-6  col-md-6">
                        {/* Tab panes */}
                        <Tab.Container defaultActiveKey="1">
                          <Tab.Content>
                            {allImages
                              ?.slice()
                              .sort((a, b) => b.isMain - a.isMain)
                              .map((image, index) => (
                                <Tab.Pane key={index} eventKey={`${index + 1}`}>
                                  {/* Dynamic tab key */}
                                  <img
                                    className="img-fluid rounded"
                                    src={image.url}
                                    style={{
                                      height: "300px",
                                      objectFit: "cover",
                                      width: "100%",
                                      objectPosition: "center",
                                    }}
                                    alt={image.name || "Product Image"}
                                    key={image.id}
                                  />
                                </Tab.Pane>
                              ))}
                          </Tab.Content>
                          <div className="tab-slide-content new-arrival-product mb-4 mb-xl-0">
                            <Nav
                              as="ul"
                              className="nav slide-item-list mt-3"
                              role="tablist"
                            >
                              {allImages
                                ?.slice()
                                .sort((a, b) => b.isMain - a.isMain)
                                .map((image, index) => (
                                  <Nav.Item as="li" key={image.id}>
                                    <Nav.Link
                                      as="a"
                                      eventKey={`${index + 1}`}
                                      to={`#${index + 1}`}
                                    >
                                      <img
                                        className="img-fluid mb-3"
                                        style={{
                                          height: "80px",
                                          objectFit: "cover",
                                          width: "100px",
                                          objectPosition: "center",
                                        }}
                                        src={image.url}
                                        alt={
                                          image.name ||
                                          "Product Image Thumbnail"
                                        }
                                        width={50}
                                      />
                                    </Nav.Link>
                                  </Nav.Item>
                                ))}
                            </Nav>
                          </div>
                        </Tab.Container>
                      </div>
                      {/*Tab slider End*/}

                      <div className="col-xl-8 col-lg-6 col-md-6 col-sm-12">
                        <div className="product-detail-content">
                          {/*Product details*/}
                          <div className="new-arrival-content pr">
                            <h4>{singleProduct?.name}</h4>
                            <p>
                              Availability:
                              <span className="item ms-2">
                                Stock {singleProduct?.storageStatus}
                                <i className="fa fa-shopping-basket" />
                              </span>
                            </p>
                            <p>
                              Product Title:
                              <span className=" ms-2 fw-semibold">
                                {singleProduct?.title}
                              </span>
                            </p>
                            {singleProduct?.brands?.length > 0 && (
                              <p>
                                Brand:
                                <span className="ms-2">
                                  {singleProduct?.brands?.name}
                                </span>
                              </p>
                            )}
                            {singleProduct?.tags && (
                              <p>
                                Product tags:&nbsp;&nbsp;
                                <span className="badge badge-success light me-1">
                                  {singleProduct?.tags?.name}
                                </span>
                              </p>
                            )}

                            <p className="text-content">
                              <p className="fw-semibold">Description:</p>
                              <span
                                dangerouslySetInnerHTML={{
                                  __html: singleProduct?.description,
                                }}
                              ></span>
                            </p>
                            {singleProduct?.summary !== "" && (
                              <p className="text-content">
                                <p className="fw-semibold">Summary:</p>
                                <span
                                  dangerouslySetInnerHTML={{
                                    __html: singleProduct?.summary,
                                  }}
                                ></span>
                              </p>
                            )}

                            <div>
                              <p>
                                {singleProduct?.sizes?.length > 0 ? (
                                  <>
                                    Sizes:
                                    <span className="ms-2 fw-semibold">
                                      {singleProduct?.sizes[0]?.name}
                                    </span>
                                  </>
                                ) : (
                                  <></>
                                )}
                              </p>
                            </div>
                            <div>
                              {singleProduct?.colors?.length > 0 && (
                                <p>
                                  Color:
                                  <span className="ms-2">
                                    {singleProduct?.colors?.map((color) => (
                                      <span
                                        key={color.id}
                                        className="badge badge-dark me-1"
                                      >
                                        {color.name}
                                      </span>
                                    ))}
                                  </span>
                                </p>
                              )}
                            </div>
                            <div>
                              <p>
                                Category Name:
                                <span className="ms-2">
                                  {singleProduct?.categories?.name}
                                </span>
                              </p>
                            </div>
                            {associated?.length > 0 ? (
                              <div>
                                <p>
                                  Associated Products:
                                  {associated?.map((item) => (
                                    <span
                                      key={item.id}
                                      className="ms-2 badge badge-light light me-1"
                                    >
                                      {item.name}
                                    </span>
                                  ))}
                                </p>
                              </div>
                            ) : (
                              <div>
                                <p>
                                  Associated Products:
                                  <span className="ms-2 badge badge-danger light me-1">
                                    No associated product!
                                  </span>
                                </p>
                              </div>
                            )}
                          </div>
                          <div className="text-end mt-4">
                            <button
                              onClick={() => handleDeleteProduct()}
                              className="btn btn-danger"
                            >
                              Delete
                            </button>
                            <Link to={`../edit/products/${singleProduct?.id}`}>
                              <button className="btn btn-primary mx-3">
                                Edit
                              </button>
                            </Link>
                            <button
                              className="btn btn-primary"
                              onClick={() => setModalOpen(true)}
                            >
                              Add Associated Products
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* <AssociateProducts singleProduct={singleProduct} /> */}
                  {associated.length > 0 && (
                    <>
                      {" "}
                      <div className="card-footer"></div>
                      <div className="card-body">
                        <h3>Associated Products:</h3>
                        <div className="row d-flex justify-content-between align-content-center">
                          {associated?.map((i) => {
                            return (
                              <div
                                key={i.id}
                                className="d-flex justify-content-between align-content-center my-3 px-3"
                              >
                                <div className="associated-image px-3">
                                  <img
                                    className="img-fluid rounded"
                                    src={i.images[0]?.url}
                                    style={{
                                      height: "150px",
                                      objectFit: "cover",
                                      width: "100%",
                                      objectPosition: "center",
                                    }}
                                    alt={i.name || "Product Image"}
                                  />
                                </div>
                                <div className="w-50 px-2 d-flex flex-column justify-content-center">
                                  <h4>{i.name}</h4>
                                  <p>{i.title}</p>
                                </div>
                                <div className="w-25 px-2 d-flex align-items-center justify-content-center">
                                  {associatedStatus === "loading" ? (
                                    <button className="btn btn-outline-danger">
                                      <span className="me-2">Initiated</span>
                                      <Spinner animation="border" size="sm" />
                                    </button>
                                  ) : (
                                    <button
                                      onClick={() =>
                                        handleDeleteAssociateProduct(i.id)
                                      }
                                      className="btn btn-outline-danger"
                                    >
                                      <FaTrash />
                                      <span className="ms-2">Remove</span>
                                    </button>
                                  )}
                                </div>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    </>
                  )}
                </div>
              </>
            )}
          </div>
        </div>
      </div>

      {/* Modal */}
      <Modal show={isModalOpen} size={"lg"} onHide={() => setModalOpen(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Add Associated</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="col-lg-6 d-flex justify-content-end">
            <div className="input-group mb-3">
              <input
                type="text"
                className="form-control"
                placeholder="Search"
                aria-label="Search"
                aria-describedby="button-addon2"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
              <button
                className="btn btn-outline-primary"
                type="button"
                id="button-addon2"
              >
                <i className="fa fa-search"></i>
              </button>
            </div>
          </div>
          {availableToAssociate?.length > 0 ? (
            filteredItem?.map((product) => (
              <div
                className="mb-3 d-flex align-items-center justify-content-between"
                key={product.id}
              >
                <h4>Product: {product.name}</h4>
                <button
                  onClick={() => {
                    handleAddProduct(product?.id);
                    Swal.fire({
                      position: "top-end",
                      title: "Wait a moment, please",
                      showConfirmButton: false,
                      icon: "info",
                      timer: 2000,
                      toast: true,
                    });
                  }}
                  className="btn btn-primary"
                >
                  Add
                </button>
              </div>
            ))
          ) : (
            <p className="text-danger ps-2">
              <IoMdInformationCircleOutline />
              <span className="ms-2">No products to add!</span>
            </p>
          )}
        </Modal.Body>
      </Modal>
    </>
  );
};

export default SingleProduct;
