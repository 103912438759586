import React, { useState } from "react";
import { Link } from "react-router-dom";
import AddShopModal from "../../components/modals/AddShopModal";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import { FaSearch } from "react-icons/fa";

const listOfShop = [
  {
    id: 1,
    name: "Blossom Boutique",
    address: "123 Rose Street, Beirut, Lebanon",
    phone: "1234567890",
    email: "blossom@gmail.com",
    logo: "/logo.webp",
  },
  {
    id: 2,
    name: "Cedar Crafts",
    address: "456 Cedar Avenue, Tripoli, Lebanon",
    phone: "1234567890",
    email: "cedarcrafts@gmail.com",
    logo: "/logo.webp",
  },
  {
    id: 3,
    name: "Lebanese Loom",
    address: "789 Silk Road, Sidon, Lebanon",
    phone: "1234567890",
    email: "lebaneseloom@gmail.com",
    logo: "/logo.webp",
  },
];

const ListOfShops = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    logo: "",
    link: "",
    ownerName: "",
    ownerPhone: "",
    address: "",
    provider: "",
    senderId: "",
    notes: "",
  });
  const [errors, setErrors] = useState({});

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const validateForm = () => {
    let isValid = true;
    const newErrors = {};

    // Validate each field
    for (const [key, value] of Object.entries(formData)) {
      if (!value) {
        newErrors[key] = `${
          key.charAt(0).toUpperCase() + key.slice(1)
        } is required`;
        isValid = false;
      }
    }

    setErrors(newErrors);
    return isValid;
  };

  const handleModalSubmit = () => {
    if (validateForm()) {
      setShowModal(false);
    }
  };

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
  };

  const filteredShops = listOfShop.filter((shop) =>
    shop.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <div className="container">
      <div className="row">
        <div className="col-xl-12">
          <div className=" mt-2">
            <div className="card-body">
              <div className="bg-white mx-1 mb-3 rounded p-3 row justify-content-between align-items-center">
                <div className="col">
                  <h3>List of Shops</h3>
                </div>
                <div className="col-auto">
                  <div className="d-flex flex-wrap-nowrap align-items-center">
                    <button
                      className="btn btn-primary me-2 w-100"
                      onClick={() => setShowModal(true)}
                    >
                      Add New Shop
                    </button>
                    <InputGroup>
                      <InputGroup.Text id="basic-addon1">
                        <FaSearch />
                      </InputGroup.Text>
                      <Form.Control
                        placeholder="Search by shop name"
                        aria-label="Search by shop name"
                        aria-describedby="basic-addon1"
                      />
                    </InputGroup>
                  </div>
                </div>
              </div>
              <div>
                {filteredShops.map((shop) => (
                  <Link
                    className="mt-2"
                    key={shop.id}
                    to={`/shop-details/${shop.id}`}
                  >
                    <div className="card border border-2 mt-2" key={shop.id}>
                      <div className="card-body">
                        <div className="row align-items-center">
                          <h5 className="col-6">{shop.name}</h5>
                          <h5 className="col-6 text-end">
                            Address: {shop.address}
                          </h5>
                        </div>
                      </div>
                    </div>
                  </Link>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Modal */}
      <AddShopModal
        showModal={showModal}
        setShowModal={setShowModal}
        handleModalSubmit={handleModalSubmit}
        handleInputChange={handleInputChange}
        formData={formData}
        setFormData={setFormData}
        errors={errors}
        setErrors={setErrors}
      />
    </div>
  );
};

export default ListOfShops;
