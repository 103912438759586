import React from "react";
import { Modal, Form, Button, Row, Col } from "react-bootstrap";

const AddShopModal = ({
  showModal,
  setShowModal,
  handleModalSubmit,
  handleInputChange,
  formData,
  setFormData,
  errors,
  setErrors,
}) => {
  return (
    <Modal
      show={showModal}
      onHide={() => setShowModal(false)}
      centered
      size="lg"
    >
      <Modal.Header closeButton>
        <Modal.Title>Add New Shop</Modal.Title>
      </Modal.Header>
      <Modal.Body className="p-5">
        <Form>
          <Row>
            {/* Name */}
            <Col xs={12} md={6} className="mb-3">
              <Form.Label>Name:</Form.Label>
              <Form.Control
                type="text"
                id="name"
                name="name"
                value={formData.name}
                placeholder="Enter shop name"
                onChange={handleInputChange}
                isInvalid={!!errors.name}
              />
              <Form.Control.Feedback type="invalid">
                {errors.name}
              </Form.Control.Feedback>
            </Col>
            {/* Logo */}
            <Col xs={12} md={6} className="mb-3">
              <Form.Label>Logo:</Form.Label>
              <Form.Control
                type="file"
                id="logo"
                name="logo"
                placeholder="Upload shop logo"
                onChange={handleInputChange}
                isInvalid={!!errors.logo}
              />
              <Form.Control.Feedback type="invalid">
                {errors.logo}
              </Form.Control.Feedback>
            </Col>
            {/* Link */}
            <Col xs={12} md={6} className="mb-3">
              <Form.Label>Link:</Form.Label>
              <Form.Control
                type="text"
                id="link"
                name="link"
                value={formData.link}
                placeholder="Enter shop link"
                onChange={handleInputChange}
                isInvalid={!!errors.link}
              />
              <Form.Control.Feedback type="invalid">
                {errors.link}
              </Form.Control.Feedback>
            </Col>
            {/* Owner Name */}
            <Col xs={12} md={6} className="mb-3">
              <Form.Label>Owner Name:</Form.Label>
              <Form.Control
                type="text"
                id="ownerName"
                name="ownerName"
                value={formData.ownerName}
                placeholder="Enter owner name"
                onChange={handleInputChange}
                isInvalid={!!errors.ownerName}
              />
              <Form.Control.Feedback type="invalid">
                {errors.ownerName}
              </Form.Control.Feedback>
            </Col>
            {/* Owner Phone Number */}
            <Col xs={12} md={6} className="mb-3">
              <Form.Label>Owner Phone Number:</Form.Label>
              <Form.Control
                type="tel"
                id="ownerPhone"
                name="ownerPhone"
                value={formData.ownerPhone}
                placeholder="Enter owner phone number"
                onChange={handleInputChange}
                isInvalid={!!errors.ownerPhone}
              />
              <Form.Control.Feedback type="invalid">
                {errors.ownerPhone}
              </Form.Control.Feedback>
            </Col>
            {/* Address */}
            <Col xs={12} md={6} className="mb-3">
              <Form.Label>Address:</Form.Label>
              <Form.Control
                type="text"
                id="address"
                name="address"
                value={formData.address}
                placeholder="Enter shop address"
                onChange={handleInputChange}
                isInvalid={!!errors.address}
              />
              <Form.Control.Feedback type="invalid">
                {errors.address}
              </Form.Control.Feedback>
            </Col>
            {/* Provider */}
            <Col xs={12} md={6} className="mb-3">
              <Form.Label>Provider:</Form.Label>
              <Form.Control
                type="text"
                id="provider"
                name="provider"
                value={formData.provider}
                placeholder="Enter shop provider"
                onChange={handleInputChange}
                isInvalid={!!errors.provider}
              />
              <Form.Control.Feedback type="invalid">
                {errors.provider}
              </Form.Control.Feedback>
            </Col>
            {/* Sender ID */}
            <Col xs={12} md={6} className="mb-3">
              <Form.Label>Sender ID:</Form.Label>
              <Form.Control
                type="text"
                id="senderId"
                name="senderId"
                value={formData.senderId}
                placeholder="Enter sender ID"
                onChange={handleInputChange}
                isInvalid={!!errors.senderId}
              />
              <Form.Control.Feedback type="invalid">
                {errors.senderId}
              </Form.Control.Feedback>
            </Col>
            {/* Notes */}
            <Col xs={12} className="mb-3">
              <Form.Label>Notes:</Form.Label>
              <Form.Control
                as="textarea"
                rows={3}
                id="notes"
                name="notes"
                value={formData.notes}
                placeholder="Enter notes"
                onChange={handleInputChange}
                isInvalid={!!errors.notes}
              />
              <Form.Control.Feedback type="invalid">
                {errors.notes}
              </Form.Control.Feedback>
            </Col>
          </Row>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="primary" onClick={handleModalSubmit}>
          Submit
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default AddShopModal;
