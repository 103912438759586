import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { useDispatch } from "react-redux";
import {
  fetchSingleOrder,
  updateOrderProduct,
} from "../../../../store/order/orderSlice";
import Swal from "sweetalert2";
import { useSelector } from "react-redux";
import Select from "react-select";
import { fetchAsyncAllProduct } from "../../../../store/product/productSlice";

const OrderProductModal = (props) => {
  const dispatch = useDispatch();

  const orderId = props.orderId;
  const productId = props.itemId;
  const orderItemsId = props.orderItemsId;
  const [quantity, setQuantity] = useState("");
  const [color, setColor] = useState("");
  const [size, setSize] = useState("");
  const [options, setOptions] = useState([]);
  const [sizeOptions, setSetSizeOptions] = useState([]);

  console.log(color);
  console.log(size);

  useEffect(() => {
    dispatch(fetchSingleOrder({ id: orderId }));
    dispatch(fetchAsyncAllProduct());
  }, [dispatch, orderId]);

  const order = useSelector((state) => state.Order.singleOrder);
  const status = useSelector((state) => state.Order.updateProductStatus);
  const allProduct = useSelector((state) => state.Product.allProduct);
  const product = allProduct?.find((product) => product.id === productId);

  const selectedProduct = order?.orderItems?.find(
    (item) => item.id === orderItemsId
  );

  // Simulate fetching data from API
  useEffect(() => {
    if (product?.colors.length > 0) {
      const formattedColors = product?.colors.map((color) => ({
        value: color.name,
        label: color.name,
        color: color.colorCode,
      }));
      setOptions(formattedColors);
    } else {
      setOptions([]);
    }
  }, [product?.colors]);

  useEffect(() => {
    if (product?.sizes.length > 0) {
      const formattedSizes = product?.sizes.map((size) => ({
        value: size.name,
        label: size.name,
      }));
      setSetSizeOptions(formattedSizes);
    } else {
      setSetSizeOptions([]);
    }
  }, [product?.sizes]);

  useEffect(() => {
    if (order || selectedProduct) {
      setQuantity(selectedProduct?.quantity);
      setColor(selectedProduct?.color);
      setSize(selectedProduct?.size);
    }
  }, [order, selectedProduct]);

  const handleSubmit = async () => {
    const data = {
      quantity: quantity,
    };
    if (product?.sizes.length > 0 && !color.value) {
      Swal.fire({
        position: "top-end",
        title: "Please select a color",
        showConfirmButton: false,
        icon: "info",
        timer: 2000,
        toast: true,
      });
      return;
    } else {
      data.color = color.value;
    }

    if (product?.sizes.length > 0 && !size) {
      Swal.fire({
        position: "top-end",
        title: "Please select a size",
        showConfirmButton: false,
        icon: "info",
        timer: 2000,
        toast: true,
      });
      return;
    } else {
      data.size = size;
    }

    // if (color) {
    //   data.color = color;
    // }
    // if (size) {
    //   data.size = size;
    // }
    try {
      await dispatch(updateOrderProduct({ id: orderItemsId, data }));
      await dispatch(fetchSingleOrder({ id: orderId }));
      props.onHide();
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: error.message,
      });
    }
  };

  const decrementQuantity = () => {
    if (quantity > 1) {
      setQuantity(quantity - 1);
    }
  };
  const incrementQuantity = () => {
    setQuantity(quantity + 1);
  };

  return (
    <div>
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        scrollable
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Edit Product
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            <div className="mb-2 col-6">
              <label>Quantity</label>
              <div>
                <span
                  onClick={decrementQuantity}
                  className="btn btn-primary btn-sm"
                >
                  -
                </span>
                <input
                  type="number"
                  className="quantity-input"
                  value={quantity}
                  readOnly
                />
                <span
                  onClick={incrementQuantity}
                  className="btn btn-primary btn-sm"
                >
                  +
                </span>
              </div>
            </div>
            <div className="col-6">
              <label>Colors</label>
              <Select
                // onChange={setSelectedOption}
                onChange={(selectedOption) => setColor(selectedOption)}
                options={options}
                defaultValue={options.find(
                  (option) => option.color === selectedProduct?.color
                )}
                style={{
                  lineHeight: "40px",
                  color: "#7e7e7e",
                  paddingLeft: " 15px",
                }}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-6">
              <label>Size</label>
              <Select
                // onChange={setSelectedOption}
                defaultValue={sizeOptions.find(
                  (option) => option.value === size
                )}
                options={sizeOptions}
                style={{
                  lineHeight: "40px",
                  color: "#7e7e7e",
                  paddingLeft: " 15px",
                }}
              />
            </div>
          </div>
          <div className="modal-footer mt-5">
            {status === "loading" ? (
              <button className="btn btn-primary" type="button" disabled>
                <span
                  className="spinner-border spinner-border-sm"
                  role="status"
                  aria-hidden="true"
                ></span>
                Loading...
              </button>
            ) : (
              <button
                type="submit"
                onClick={handleSubmit}
                className="btn btn-primary"
              >
                Save
              </button>
            )}
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default OrderProductModal;
