import { del, get, post } from "aws-amplify/api";
import { amplifyConfigs } from "../../config";
import Swal from "sweetalert2";

export async function getOutBoxMessage() {
  try {
    const restOperation = get({
      apiName: amplifyConfigs.apiName,
      path: "/outboxs",
    });
    const response = await restOperation.response;
    return response.body.json();
  } catch (error) {
    
  }
}

// post message
export async function postOutBoxMessage({ data }) {
  
  try {
    const restOperation = post({
      apiName: amplifyConfigs.apiName,
      path: "/outboxs/promo",
      options: {
        body: data,
      },
    });
    const response = await restOperation.response;
    return response.body.json();
  } catch (error) {
    
  }
}

// Reply message
export async function postOutBoxReplyMessage({ data, id }) {
  
  try {
    const restOperation = post({
      apiName: amplifyConfigs.apiName,
      path: `/outboxs/inbox/${id}/replay`,
      options: {
        body: data,
      },
    });
    const response = await restOperation.response;
    Swal.fire({
      title: "Message sent successfully",
      icon: "success",
      showConfirmButton: false,
      timer: 1500,
    });
    return response.body.json();
  } catch (error) {
    
  }
}

export async function getOutBoxSingleMessage({ id }) {
  try {
    const restOperation = get({
      apiName: amplifyConfigs.apiName,
      path: `/outboxs/${id}`,
    });
    const response = await restOperation.response;
    return response.body.json();
  } catch (error) {
    
  }
}

// Delete inbox
export async function deleteOutBoxMessage({ id }) {
  try {
    const restOperation = del({
      apiName: amplifyConfigs.apiName,
      path: `/outboxs/${id}`,
    });
    const response = await restOperation.response;
    return response.body.json();
  } catch (error) {
    
  }
}
