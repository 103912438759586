import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { deleteTag, getTags, postTags, updateTag } from "./tagsAPI";

// Async thunk for fetching all tag
export const fetchAsyncAllTags = createAsyncThunk("tag/fetchTags", async () => {
  const collections = await getTags();
  return collections;
});

// Async thunk for creating a tag
export const createAsyncTags = createAsyncThunk(
  "tag/createTags",
  async (data) => {
    const response = await postTags(data);
    return response;
  }
);

// Async thunk for updating a tag
export const updateAsyncTag = createAsyncThunk(
  "tags/updataTag",
  async (data) => {
    const response = await updateTag(data);
    return response;
  }
);

// Async thunk for deleting a tag
export const deleteAsyncTag = createAsyncThunk("tags/deleteTag", async (id) => {
  const response = await deleteTag(id);
  return response;
});

// Initial state for the tag slice
const initialState = {
  allTag: [],
  status: "idle",
  error: null,
};

// tag slice
const tagsSlice = createSlice({
  name: "tags",
  initialState,
  extraReducers: (builder) => {
    builder
      // Handle fetchAsyncAllCategory
      .addCase(fetchAsyncAllTags.pending, (state) => {
        state.status = "loading";
        state.error = null;
      })
      .addCase(fetchAsyncAllTags.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.allTag = action.payload;
      })
      .addCase(fetchAsyncAllTags.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      // Handle createAsyncCategory
      .addCase(createAsyncTags.pending, (state) => {
        state.status = "loading";
        state.error = null;
      })
      .addCase(createAsyncTags.fulfilled, (state, action) => {
        // state.allTag.push(action.payload);
        state.status = "succeeded";
      })
      .addCase(createAsyncTags.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      // Handle update collection
      .addCase(updateAsyncTag.pending, (state) => {
        state.status = "loading";
        state.error = null;
      })
      .addCase(updateAsyncTag.fulfilled, (state, action) => {
        const updateTag = action.payload;
        if (updateTag && updateTag.id) {
          state.allTag = state.allTag.map((t) =>
            t.id === updateTag.id ? updateTag : t
          );
        }
      })
      .addCase(updateAsyncTag.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      // Handle deleteAsync collection
      .addCase(deleteAsyncTag.pending, (state) => {
        state.status = "loading";
        state.error = null;
      })
      .addCase(deleteAsyncTag.fulfilled, (state, action) => {
        const deletedId = action.payload;
        state.allTag = state.allTag.filter((t) => t.id !== deletedId);
        state.status = "succeeded";
      })
      .addCase(deleteAsyncTag.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      });
  },
});

export default tagsSlice.reducer;
