import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  collectionToProduct,
  deleteCollection,
  getCollections,
  getSingleCollection,
  postCollections,
  updateCollection,
} from "./collectionAPI";

// Async thunk for fetching all collection
export const fetchAsyncAllCollections = createAsyncThunk(
  "collections/fetchCollections",
  async () => {
    const collections = await getCollections();
    return collections;
  }
);

// Async thunk for fetching Single collection
export const fetchAsyncSingleCollections = createAsyncThunk(
  "collections/fetchSingleCollection",
  async (id) => {
    const collections = await getSingleCollection(id);
    return collections;
  }
);

// Async thunk for creating a category
export const createAsyncCollection = createAsyncThunk(
  "collections/createCollections",
  async (data) => {
    const response = await postCollections(data);
    return response;
  }
);

// Async thunk for updating a collection
export const updateAsyncCollection = createAsyncThunk(
  "collections/updateCollection",
  async (data) => {
    const response = await updateCollection(data);
    return response;
  }
);

// Async thunk for deleting a Collection
export const deleteAsyncCollection = createAsyncThunk(
  "collections/deleteCollection",
  async (id) => {
    const response = await deleteCollection(id);
    return response;
  }
);

// Async thunk for add product to collection
export const addProductToCollection = createAsyncThunk(
  "collections/addProductToCollection",
  async (data) => {
    const response = await collectionToProduct(data);
    return response;
  }
);

// Initial state for the category slice
const initialState = {
  addProductToCollection: [],
  allCollection: [],
  collection: {},
  status: "idle",
  error: null,
};

// Category slice
const collectionSlice = createSlice({
  name: "categories",
  initialState,
  extraReducers: (builder) => {
    builder
      // Handle fetchAsyncAllCategory
      .addCase(fetchAsyncAllCollections.pending, (state) => {
        state.status = "loading";
        state.error = null;
      })
      .addCase(fetchAsyncAllCollections.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.allCollection = action.payload;
      })
      .addCase(fetchAsyncAllCollections.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      // Handle fetchAsync single collection
      .addCase(fetchAsyncSingleCollections.pending, (state) => {
        state.status = "loading";
        state.error = null;
      })
      .addCase(fetchAsyncSingleCollections.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.collection = action.payload;
      })
      .addCase(fetchAsyncSingleCollections.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      // Handle createAsyncCategory
      .addCase(createAsyncCollection.pending, (state) => {
        state.status = "loading";
        state.error = null;
      })
      .addCase(createAsyncCollection.fulfilled, (state, action) => {
        state.allCollection.push(action.payload);
        state.status = "succeeded";
      })
      .addCase(createAsyncCollection.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      // Handle update collection
      .addCase(updateAsyncCollection.pending, (state) => {
        state.status = "loading";
        state.error = null;
      })
      .addCase(updateAsyncCollection.fulfilled, (state, action) => {
        const updatedCcollection = action.payload;
        if (updatedCcollection && updatedCcollection.id) {
          state.allCollection = state.allCollection.map((collection) =>
            collection.id === updatedCcollection.id
              ? updatedCcollection
              : collection
          );
        }
      })
      .addCase(updateAsyncCollection.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      // Handle deleteAsync collection
      .addCase(deleteAsyncCollection.pending, (state) => {
        state.status = "loading";
        state.error = null;
      })
      .addCase(deleteAsyncCollection.fulfilled, (state, action) => {
        const deletedId = action.payload;
        state.allCollection = state.allCollection.filter(
          (category) => category.id !== deletedId
        );
        state.status = "succeeded";
      })
      .addCase(deleteAsyncCollection.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      // handle add product to collection
      .addCase(addProductToCollection.pending, (state) => {
        state.status = "loading";
        state.error = null;
      })
      .addCase(addProductToCollection.fulfilled, (state, action) => {
        state.addProductToCollection.push(action.payload);
        state.status = "succeeded";
      })
      .addCase(addProductToCollection.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      });
  },
});

export default collectionSlice.reducer;
