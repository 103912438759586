import React, { useEffect, useState } from "react";

import Select from "react-select";

const productColors = [
  { value: "black", label: "black", color: "#000000" },
  { value: "white", label: "white", color: "#FFFFFF" },
  { value: "red", label: "red", color: "#FF0000" },
  { value: "lime", label: "lime", color: "#00FF00" },
  { value: "blue", label: "blue", color: "#0000FF" },
  { value: "yellow", label: "yellow", color: "#FFFF00" },
  { value: "cyan", label: "cyan", color: "#00FFFF" },
  { value: "magenta", label: "magenta", color: "#FF00FF" },
  { value: "silver", label: "silver", color: "#C0C0C0" },
  { value: "gray", label: "gray", color: "#808080" },
  { value: "maroon", label: "maroon", color: "#800000" },
  { value: "olive", label: "olive", color: "#808000" },
  { value: "green", label: "green", color: "#008000" },
  { value: "purple", label: "purple", color: "#800080" },
  { value: "teal", label: "teal", color: "#008080" },
  { value: "navy", label: "navy", color: "#000080" },
  { value: "orange", label: "orange", color: "#FFA500" },
  { value: "brown", label: "brown", color: "#A52A2A" },
  { value: "pink", label: "pink", color: "#FFC0CB" },
  { value: "coral", label: "coral", color: "#FF7F50" },
  { value: "beige", label: "beige", color: "#F5F5DC" },
  { value: "mint", label: "mint", color: "#98FF98" },
  { value: "lavender", label: "lavender", color: "#E6E6FA" },
  { value: "gold", label: "gold", color: "#FFD700" },
  { value: "ivory", label: "ivory", color: "#FFFFF0" },
  { value: "turquoise", label: "turquoise", color: "#40E0D0" },
  { value: "darkblue", label: "darkblue", color: "#00008B" },
  { value: "peach", label: "peach", color: "#FFE5B4" },
  { value: "mustard", label: "mustard", color: "#FFDB58" },
  { value: "plum", label: "plum", color: "#DDA0DD" },
];

const CustomClearText = () => "clear all";
const ClearIndicator = (props) => {
  const {
    children = <CustomClearText />,
    getStyles,
    innerProps: { ref, ...restInnerProps },
  } = props;
  return (
    <div
      {...restInnerProps}
      ref={ref}
      style={getStyles("clearIndicator", props)}
    >
      <div style={{ padding: "0px 5px" }}>{children}</div>
    </div>
  );
};

const ClearIndicatorStyles = (base, state) => ({
  ...base,
  cursor: "pointer",
  color: state.isFocused ? "blue" : "black",
});

const defaultColorss = [
  { value: "black", label: "black", color: "#000000" },
  { value: "white", label: "white", color: "#FFFFFF" },
  { value: "red", label: "red", color: "#FF0000" },
  { value: "lime", label: "lime", color: "#00FF00" },
  { value: "blue", label: "blue", color: "#0000FF" },
  { value: "yellow", label: "yellow", color: "#FFFF00" },
  { value: "cyan", label: "cyan", color: "#00FFFF" },
  { value: "magenta", label: "magenta", color: "#FF00FF" },
  { value: "silver", label: "silver", color: "#C0C0C0" },
  { value: "gray", label: "gray", color: "#808080" },
  { value: "maroon", label: "maroon", color: "#800000" },
  { value: "olive", label: "olive", color: "#808000" },
  { value: "green", label: "green", color: "#008000" },
  { value: "purple", label: "purple", color: "#800080" },
  { value: "teal", label: "teal", color: "#008080" },
  { value: "navy", label: "navy", color: "#000080" },
  { value: "orange", label: "orange", color: "#FFA500" },
  { value: "brown", label: "brown", color: "#A52A2A" },
  { value: "pink", label: "pink", color: "#FFC0CB" },
  { value: "coral", label: "coral", color: "#FF7F50" },
  { value: "beige", label: "beige", color: "#F5F5DC" },
  { value: "mint", label: "mint", color: "#98FF98" },
  { value: "lavender", label: "lavender", color: "#E6E6FA" },
  { value: "gold", label: "gold", color: "#FFD700" },
];

export default function CustomClearIndicator({ onValueChange, colors }) {
  const [defaultColors, setDefaultColors] = useState([]);

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    simulateLoading();
  }, []);

  function simulateLoading() {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 2000);
  }

  // Simulate fetching data from API
  useEffect(() => {
    if (colors.length > 0) {
      const formattedColors = colors.map((color) => ({
        value: color.name,
        label: color.name,
        color: color.colorCode,
      }));
      setDefaultColors(formattedColors);
    }
  }, [colors]);

  const handleChange = (selectedOptions) => {
    const formattedOptions = selectedOptions.map((option) => ({
      name: option.value,
      colorCode: option.color,
    }));
    onValueChange(formattedOptions);
  };

  return (
    <div>
      {loading ? (
        <div>Loading...</div>
      ) : (
        <Select
          closeMenuOnSelect={false}
          components={{ ClearIndicator }}
          styles={{ clearIndicator: ClearIndicatorStyles }}
          defaultValue={defaultColors}
          isMulti
          options={productColors}
          onChange={handleChange}
        />
      )}
    </div>
  );
}
