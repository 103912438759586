import { del, get, patch, post } from "aws-amplify/api";
import { amplifyConfigs } from "../../config";
import Swal from "sweetalert2";

export async function getProducts() {
  try {
    const restOperation = get({
      apiName: amplifyConfigs.apiName,
      path: "/products",
    });
    const response = await restOperation.response;
    return response.body.json();
  } catch (error) {}
}

// Get Single Product
export async function getSingleProduct(id) {
  try {
    const restOperation = get({
      apiName: amplifyConfigs.apiName,
      path: `/products/${id}`,
    });
    const response = await restOperation.response;
    return response.body.json();
  } catch (error) {}
}

// Create Product

export async function postProducts({ categoryId, data, navigate }) {
  try {
    const restOperation = post({
      apiName: amplifyConfigs.apiName,
      path: `/products/categories/${categoryId}`,
      options: {
        body: data,
      },
    });
    const response = await restOperation.response;
    Swal.fire({
      icon: "success",
      title: "Success",
      text: "Product added successfully",
    });
    // navigate("/products");
    return response;
  } catch (error) {
    Swal.fire({
      icon: "error",
      title: "Oops...",
      text: error.message,
    });
  }
}

// Update Product
export async function updateProduct({ productId, categoryId, data, navigate }) {
  try {
    const restOperation = patch({
      apiName: amplifyConfigs.apiName,
      path: `/products/${productId}/categories/${categoryId}`,
      options: {
        body: data,
      },
    });
    const response = await restOperation.response;
    Swal.fire({
      icon: "success",
      title: "Success",
      text: "Product updated successfully",
    });
    // navigate("/products");
    return response;
  } catch (error) {
    Swal.fire({
      icon: "error",
      title: "Oops...",
      text: error.message,
    });
  }
}

// Add Associated Product
// Associated Product
export async function createAssociatedProduct({
  productId,
  associatedProductId,
}) {
  try {
    const restOperation = get({
      apiName: amplifyConfigs.apiName,
      path: `/products/${productId}/associated/${associatedProductId}`,
    });
    const response = await restOperation.response;
    Swal.fire({
      icon: "success",
      title: "Success",
      text: "Associated Product added successfully",
    });
    return response.body.json();
  } catch (error) {
    Swal.fire({
      icon: "error",
      title: "Oops...",
      text: error.message,
    });
  }
}

// Get Associated Product
export async function getAssociatedProduct({ productId }) {
  try {
    const restOperation = get({
      apiName: amplifyConfigs.apiName,
      path: `/products/${productId}/associated`,
    });
    const response = await restOperation.response;
    return response.body.json();
  } catch (error) {
    console.log(error);
    Swal.fire({
      icon: "error",
      title: "Oops...",
      text: error.message,
    });
  }
}

// Delete Product

export async function deleteProduct(id) {
  try {
    const restOperation = del({
      apiName: amplifyConfigs.apiName,
      path: `/products/${id}`,
    });
    const response = await restOperation.response;
    Swal.fire({
      icon: "success",
      title: "Success",
      text: "Product deleted successfully",
    });
    return response;
  } catch (error) {
    Swal.fire({
      icon: "error",
      title: "Oops...",
      text: error.message,
    });
  }
}

// Delete Product
export async function deleteAssociateProduct({
  productId,
  associatedProductId,
}) {
  try {
    const restOperation = del({
      apiName: amplifyConfigs.apiName,
      path: `/products/${productId}/associated/${associatedProductId}`,
    });
    const response = await restOperation.response;
    Swal.fire({
      icon: "success",
      title: "Success",
      text: "Associated Product deleted successfully",
    });
    return response;
  } catch (error) {
    Swal.fire({
      icon: "error",
      title: "Oops...",
      text: error.message,
    });
  }
}
