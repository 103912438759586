import { createSlice } from "@reduxjs/toolkit";
import { v4 as uuidv4 } from "uuid";

const initialState = {
  products: [],
  cartItems: [],
  allProduct: [],
  product: {},
  status: "idle",
  error: null,
};

const customProductSlice = createSlice({
  name: "customProduct",
  initialState,
  reducers: {
    addToCart(state, action) {
      const { product, productSize, productColor, cartQuantity } =
        action.payload;
      const { id: productId, name: productName } = product;
      const quantityToAdd = cartQuantity ?? 1;

      const newProduct = {
        id: productId,
        uniqueId: uuidv4(),
        name: productName,
        cartQuantity: quantityToAdd,
        productSize: productSize ?? "",
        productColor: productColor ?? "",
        mainPrice: product.price !== 0 ? product.price : 0,
      };
      state.cartItems.push(newProduct);
    },
    deleteCartProduct: (state, action) => {
      const filteredCartItems = state.cartItems.filter(
        (cartItem) => cartItem.uniqueId !== action.payload.uniqueId
      );
      state.cartItems = filteredCartItems;
    },
    removeAllCartItems: (state) => {
      state.cartItems = [];
    },
  },
});

export const { addToCart, deleteCartProduct, removeAllCartItems } = customProductSlice.actions;

export default customProductSlice.reducer;
