import { fetchUserAttributes } from "aws-amplify/auth";
import React, { useEffect, useState } from "react";
import { useLocation, Navigate } from "react-router-dom";
import Loading from "./components/Loading";
import { useDispatch } from "react-redux";


const PrivateRoute = ({ children, allowedRoles }) => {
  const [role, setRole] = useState(null);
  const [loading, setLoading] = useState(true);
  const location = useLocation();
  const dispatch = useDispatch();
  

  useEffect(() => {
    fetchUserAttributes()
      .then(attributes => {
        const userRole = attributes['custom:role'];
        setRole(userRole);
        setLoading(false);
      })
      .catch(error => {
        setLoading(false);
      });
  }, []);


  if (loading) {
    return <Loading status={"loading"} />;
  }

  

  // If the role is 'user' and the user is trying to access the home page, redirect them to 'visit-page'
  // if (role === 'user' && location.pathname === '/') {
  //   return <Navigate to="/visit-page" replace={true} />;
  // }

  // if (role === 'supervisor' && location.pathname === '/') {
  //   return <Navigate to="/visit-page" replace={true} />;
  // }

  // For 'admin' or other allowed roles, allow access to the route if they are in allowedRoles
  if (!allowedRoles.includes(role)) {
    return <Navigate to="/login" replace={true} />;
  }

  // Render children for allowed roles without further redirection
  return children;
};

export default PrivateRoute;
