import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";

import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

import { useDispatch } from "react-redux";

import { fetchAsyncAllBrands } from "../../../store/brands/brandsSlice";
import { useSelector } from "react-redux";

import { fetchAsyncAllCollections } from "../../../store/collections/collectionSlice";
import { fetchAsyncAllCategory } from "../../../store/category/categorySlice";
import { fetchAsyncAllTags } from "../../../store/tags/tagsSlice";
import { IMAGES } from "../../constant/theme";
import {
  createAsyncNewProduct,
  fetchAsyncAllProduct,
  fetchAsyncSingleProduct,
  updateAsyncProduct,
} from "../../../store/product/productSlice";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import CustomClearIndicator from "../../components/PluginsMenu/Select2/MultiSelect";
import { FaArrowCircleLeft, FaTrash } from "react-icons/fa";

const AddProduct = () => {
  const [isRTL, setIsRTL] = useState(localStorage.getItem("isRTL") === "true");
  const [mainImage, setMainImage] = useState("" || IMAGES.User);
  const [sizes, setSizes] = useState([{ name: "", price: "" }]);
  const [additionalInfo, setAdditionalInfo] = useState("");
  const [storageStatus, setStorageStatus] = useState("in");
  const [btnLoading, setBtnLoading] = useState(false);
  const [otherImages, setOtherImages] = useState([]);
  const [subCategory, setSubCategory] = useState([]);
  const [description, setDescription] = useState("");
  const [mainPrice, setMainPrice] = useState(0);
  const [category, setCategory] = useState("");
  const [videoUrl, setVideoUrl] = useState("");
  const [discount, setDiscount] = useState(0);
  const [summary, setSummary] = useState("");
  const [colors, setColors] = useState([]);
  const [title, setTitle] = useState("");
  const [brand, setBrand] = useState("");
  const [name, setName] = useState("");
  const path = useLocation().pathname;
  const [tag, setTag] = useState("");
  const productId = useParams().id;
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const toggleDirection = () => {
    Swal.fire({
      title: "Are you sure?",
      text: "You need to refresh the page to apply changes",
      icon: "warning",
      showCancelButton: true,
      cancelButtonColor: "#d33",
      confirmButtonColor: "#3085d6",
      confirmButtonText: "Yes, refresh it!",
    }).then((result) => {
      if (result.isConfirmed) {
        setIsRTL(!isRTL);
        localStorage.setItem("isRTL", !isRTL);
        window.location.reload();
      }
    });
  };

  // Fetch all the required data
  useEffect(() => {
    dispatch(fetchAsyncAllBrands());
    dispatch(fetchAsyncAllCollections());
    dispatch(fetchAsyncAllCategory());
    dispatch(fetchAsyncAllTags());
    dispatch(fetchAsyncAllProduct());
    dispatch(fetchAsyncSingleProduct(productId));
  }, [dispatch, productId]);

  const allBrands = useSelector((state) => state.Brand.allBrand);
  const fullProduct = useSelector((state) => state.Product.singleProduct);
  const allCategory = useSelector((state) => state.Category.allCategory);
  const tags = useSelector((state) => state.Tag.allTag);
  const singleProduct = fullProduct;
  const status = useSelector((state) => state.Product.status);

  const [editProduct, setEditProduct] = useState(false);
  const [addProduct, setAddProduct] = useState(false);

  // Check if the user is adding or editing a product
  useEffect(() => {
    if (path === `/add/products`) {
      setAddProduct(true);
      setEditProduct(false);
    } else if (path === `/edit/products/${productId}`) {
      setAddProduct(false);
      setEditProduct(true);
    } else {
      setAddProduct(false);
      setEditProduct(false);
    }
  }, [productId, path]);

  // Handle main image change
  const handleMainImageChange = (e) => {
    const file = e.target.files[0];
    setMainImage(file);
  };

  // Handle other images change
  const handleOtherImagesChange = (e) => {
    if (e.target.files.length > 4) {
      Swal.fire({
        icon: "error",
        title: "Validation Error",
        text: "You can only upload 4 images",
      });
      e.target.value = null;
    } else {
      const files = Array.from(e.target.files);
      setOtherImages(files);
    }
  };

  // Handle size change
  const handleSizeChange = (index, key, value) => {
    if (value === "") {
      return;
    } else {
      const newSizes = JSON.parse(JSON.stringify(sizes));

      newSizes[index][key] = value;
      setSizes(newSizes);
    }
  };

  const handleAddSize = () => {
    setSizes([...sizes, { name: "", price: "" }]);
  };

  const handleDeleteSize = (index) => {
    const newSizes = [...sizes];
    newSizes.splice(index, 1);
    setSizes(newSizes);
  };

  const handleValueChange = (selectedColors) => {
    setColors(selectedColors);
  };

  // Set the form values if the user is editing a product
  useEffect(() => {
    if (editProduct) {
      if (singleProduct) {
        setName(singleProduct.name || "");
        setTitle(singleProduct.title || "");
        setBrand(singleProduct.brands?.id || "");
        setCategory(singleProduct?.categories?.id || "");
        setSubCategory(
          singleProduct?.subcollections?.length > 0
            ? singleProduct?.subcollections[0]?.id
            : "" || ""
        );
        setTag(singleProduct.tags?.id || "");
        setDescription(singleProduct.description || "");
        setAdditionalInfo(singleProduct.additionalInfo || "");
        setSummary(singleProduct.summary || "");
        setColors(singleProduct.colors || []);
        setSizes(singleProduct.sizes || [{ name: "", price: "" }]);
        setDiscount(singleProduct.discount || 0);
        setMainPrice(singleProduct.price || 0);
        setStorageStatus(singleProduct.storageStatus || "");
        setVideoUrl(singleProduct.videoUrl || "");
        if (singleProduct?.images?.length > 0) {
          const mainImage = singleProduct?.images?.find((img) => img.isMain);
          const otherImages = singleProduct?.images?.filter(
            (img) => !img.isMain
          );
          setMainImage(mainImage?.url);
          setOtherImages(otherImages.map((img) => img.url));
        }
      }
    }
  }, [singleProduct, editProduct]);

  // Define required fields
  const requiredFields = [
    { id: "name", label: "Name" },
    { id: "title", label: "Title" },
    { id: "category", label: "Category" },
  ];
  // Define a validation function
  const validateForm = () => {
    let isValid = true;

    // Iterate through the required fields
    for (const field of requiredFields) {
      const value = document.getElementById(field?.id).value;

      // Check if the field is empty
      if (!value.trim()) {
        // Display an error message using Swal
        Swal.fire({
          icon: "error",
          title: "Validation Error",
          text: `${field.label} is required.`,
        });

        // Set the form validity to false
        isValid = false;
        break;
      }
    }

    return isValid;
  };

  // Function to convert file to base64 string
  const readFileAsBase64 = (file) => {
    if (typeof file == "string" || typeof file == "undefined") {
      return;
    } else {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = () => resolve(reader.result.split(",")[1]);
        reader.onerror = (error) => reject(error);
        reader.readAsDataURL(file);
      });
    }
  };

  useEffect(() => {
    if (mainPrice) {
      setSizes([]);
    }
  }, [mainPrice]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setBtnLoading(true);

      // Validate the main image
      /*if (!mainImage.startsWith("https://") && !mainImage.startsWith("data:")) {
        Swal.fire({
          icon: "error",
          title: "Validation Error",
          text: "Main Image is required.",
        });
        return;
      }*/

      // Validate the form
      if (!validateForm()) {
        return;
      }
      if (description === "") {
        Swal.fire({
          icon: "error",
          title: "Validation Error",
          text: "Description is required.",
        });
        return;
      }

      if (!mainPrice) {
        if (
          sizes.length === 0 ||
          sizes[0].name === "" ||
          sizes[0].price === ""
        ) {
          Swal.fire({
            icon: "error",
            title: "Validation Error",
            text: "Size and Price are required.",
          });
          return;
        }
      }

      // Convert main and other images to base64
      const mainBase64 = await readFileAsBase64(mainImage);
      const otherBase64Promises = otherImages.map(readFileAsBase64);

      // Wait for all promises to resolve
      const otherBase64Images = await Promise.all(otherBase64Promises);

      // Create main image object with isMain: true
      const mainImageObject = {
        image: mainBase64,
        isMain: true,
      };

      // Create other image objects with isMain: false
      const otherImagesObjects = otherBase64Images.map((base64) => ({
        image: base64,
        isMain: false,
      }));

      // Combine main and other images
      let allImages;
      if (otherBase64Images.length > 0 && typeof mainBase64 === "string") {
        allImages = [mainImageObject, ...otherImagesObjects];
      } else if (
        otherBase64Images.length === 0 &&
        typeof mainBase64 === "string"
      ) {
        allImages = [mainImageObject];
      } else if (
        otherBase64Images.length > 0 &&
        typeof otherBase64Images[0] === "string"
      ) {
        allImages = [...otherImagesObjects];
      }

      // Check for empty values in sizes
      const hasEmptyValues = sizes.some((obj) => !obj.name || !obj.price);
      if (hasEmptyValues) {
        Swal.fire({
          icon: "error",
          title: "Validation Error",
          text: "Size and Price should not be empty.",
        });
        return;
      }

      // Form data to be submitted
      const formData = {
        name,
        title,
        tag,
        description,
        additionalInfo,
        summary,
        colors,
        sizes,
        discount,
        storageStatus,
        price: Number(mainPrice),
        videoUrl,
      };
      if (subCategory) {
        formData.subCollections = subCategory;
      }
      if (brand) {
        formData.brand = brand;
      }
      if (allImages?.length > 0) {
        formData.images = allImages;
      }
      const dataToPost = {
        categoryId: category,
        data: formData,
      };
      if (editProduct) {
        dataToPost.productId = productId;
        await dispatch(updateAsyncProduct({ ...dataToPost, navigate }));
        await dispatch(fetchAsyncAllProduct());
        navigate("/products");
      } else {
        await dispatch(createAsyncNewProduct({ ...dataToPost, navigate }));
        await dispatch(fetchAsyncAllProduct());
        navigate("/products");
      }
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: error.message,
      });
    } finally {
      setBtnLoading(false);
    }
  };

  // Prevent form submission on Enter key press
  const preventFormSubmitOnEnter = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
    }
  };

  return (
    <>
      <div className="container">
        <div className="row">
          <div className="col-xl-12">
            <div className="card profile-card card-bx m-b30">
              <div className="card-header">
                {editProduct ? (
                  <h6 className="title">
                    {" "}
                    <Link to="/products">
                      <FaArrowCircleLeft className="me-2" />
                    </Link>
                    Edit Product
                  </h6>
                ) : (
                  <h6 className="title">
                    {" "}
                    <Link to="/products">
                      <FaArrowCircleLeft className="me-2" />
                    </Link>
                    Add New Product
                  </h6>
                )}
              </div>
              <>
                <div
                  className={`profile-form ${
                    btnLoading ? "disabled-form" : ""
                  }`}
                >
                  <div className="card-body">
                    <div className="row">
                      <div className="col-sm-6 m-b30">
                        <label htmlFor="name" className="form-label">
                          Name*
                        </label>
                        <input
                          type="text"
                          id="name"
                          className="form-control"
                          value={name}
                          onChange={(e) => setName(e.target.value)}
                        />
                      </div>
                      <div className="col-sm-6 m-b30">
                        <label htmlFor="name" className="form-label">
                          Title
                        </label>
                        <input
                          type="text"
                          id="title"
                          className="form-control"
                          value={title}
                          onChange={(e) => setTitle(e.target.value)}
                        />
                      </div>
                      {/* Image previewing here */}
                      {editProduct ? (
                        mainImage instanceof Blob ? (
                          <div className="author-profile">
                            <div className="author-media">
                              <img
                                src={URL.createObjectURL(mainImage)}
                                className="mx-2"
                                alt="Main photos"
                              />
                            </div>
                          </div>
                        ) : (
                          singleProduct?.images?.map((item) => {
                            if (item.isMain) {
                              return (
                                <div key={item.id} className="author-profile">
                                  <div className="author-media">
                                    <img
                                      key={item.id}
                                      src={item.url}
                                      className="mx-2"
                                      alt="Thumbnail"
                                    />
                                  </div>
                                </div>
                              );
                            }
                            return null;
                          })
                        )
                      ) : (
                        <div className="author-profile">
                          <div className="author-media">
                            <img
                              src={
                                mainImage instanceof Blob
                                  ? URL.createObjectURL(mainImage)
                                  : mainImage
                              }
                              className="mx-2"
                              alt="Main photos"
                            />
                          </div>
                        </div>
                      )}
                      <div className="form-group col-sm-12 m-b30">
                        <label htmlFor="mainImages">Main Image</label>
                        <input
                          type="file"
                          id="mainImage"
                          className="form-control"
                          onChange={handleMainImageChange}
                        />
                      </div>

                      {/* Other Images */}
                      {editProduct ? (
                        otherImages && otherImages.length > 0 ? (
                          <div className="author-profile">
                            <div className="author-media">
                              {otherImages.map((file, index) => (
                                <img
                                  key={index}
                                  src={
                                    file instanceof Blob
                                      ? URL.createObjectURL(file)
                                      : file
                                  }
                                  className="mx-2"
                                  alt="Other photos"
                                />
                              ))}
                            </div>
                          </div>
                        ) : (
                          singleProduct &&
                          singleProduct?.images
                            ?.filter((file) => !file.isMain)
                            .map((file, index) => (
                              <div className="author-profile" key={index}>
                                <div className="author-media">
                                  <img
                                    src={file.url || IMAGES.User}
                                    className="mx-2"
                                    alt="Other photos"
                                  />
                                </div>
                              </div>
                            ))
                        )
                      ) : (
                        otherImages &&
                        otherImages.length > 0 && (
                          <div className="author-profile">
                            <div className="author-media">
                              {otherImages.map(
                                (file, index) =>
                                  file instanceof Blob && (
                                    <img
                                      key={index}
                                      src={URL.createObjectURL(file)}
                                      className="mx-2"
                                      alt="Other photos"
                                    />
                                  )
                              )}
                            </div>
                          </div>
                        )
                      )}

                      <div className="form-group col-sm-12 m-b30">
                        <label htmlFor="mainImages">Other Images</label>
                        <input
                          type="file"
                          id="mainImages"
                          className="form-control"
                          multiple
                          onChange={handleOtherImagesChange}
                        />
                      </div>

                      {/* Add Brand Field */}
                      <div className="form-group col-sm-12 m-b30">
                        <label htmlFor="brand">Brand</label>
                        <select
                          id="brand"
                          className="form-control"
                          value={brand}
                          onChange={(e) => setBrand(e.target.value)}
                        >
                          <option disabled value="">
                            Select Brand
                          </option>
                          {allBrands?.map((brand) => (
                            <option key={brand?.name} value={brand?.id}>
                              {brand?.name}
                            </option>
                          ))}
                        </select>
                      </div>
                      <div className="form-group col-sm-6 m-b30">
                        {/* <CategorySelect allCategory={allCategory}/> */}
                        <label htmlFor="category">Category</label>
                        <select
                          id="category"
                          className="form-control"
                          value={subCategory ? subCategory : category}
                          onChange={(e) => {
                            const selectedCategoryId = e.target.value;
                            const selectedCategory = allCategory.find((cat) => {
                              if (cat.id === selectedCategoryId) {
                                // If the selected option is a top-level category
                                return true;
                              } else if (cat.subcollections) {
                                // If the selected option is a subcategory, check if it belongs to any top-level category
                                return cat.subcollections.some(
                                  (sub) => sub.id === selectedCategoryId
                                );
                              }
                              return false;
                            });
                            if (selectedCategory) {
                              // If the selected option is a top-level category
                              if (selectedCategory.subcollections) {
                                // If the selected category has subcategories
                                const selectedSubCategory =
                                  selectedCategory.subcollections.find(
                                    (sub) => sub.id === selectedCategoryId
                                  );
                                setCategory(selectedCategory?.id);
                                setSubCategory(selectedSubCategory?.id);
                              } else {
                                // If the selected category has no subcategories
                                setCategory(selectedCategory?.id);
                              }
                            }
                          }}
                        >
                          <option disabled value="">
                            Select Category
                          </option>
                          {allCategory?.map((c, index) => {
                            // Check if the category has sub-categories
                            if (
                              c.subcollections &&
                              c.subcollections.length > 0
                            ) {
                              return (
                                <optgroup label={c.name} key={index}>
                                  {c.subcollections.map((sub) => (
                                    <option key={sub.id} value={sub.id}>
                                      {sub.name}
                                    </option>
                                  ))}
                                </optgroup>
                              );
                            } else {
                              // Category without sub-categories
                              return (
                                <option key={c.id} value={c.id}>
                                  {c.name}
                                </option>
                              );
                            }
                          })}
                        </select>
                      </div>

                      {/* Add Tag Field */}
                      <div className="form-group col-sm-6 m-b30">
                        <label htmlFor="tag">Tag</label>
                        <select
                          id="tag"
                          className="form-control"
                          value={tag}
                          onChange={(e) => setTag(e.target.value)}
                        >
                          <option value="">Select Tag</option>
                          {tags?.map((t) => (
                            <option key={t?.name} value={t?.id}>
                              {t?.name}
                            </option>
                          ))}
                        </select>
                      </div>
                      
                      <div className="custom-ekeditor m-b30">
                        <label
                          htmlFor="description"
                          className="fs-4 fw-semibold"
                        >
                          Description
                        </label>
                        {/* <button
                          className="btn btn-outline-success btn-sm ms-3"
                          onClick={toggleDirection}
                        >
                          Switch to {isRTL ? "LTR" : "RTL"}
                        </button> */}
                        <CKEditor
                          id={"description"}
                          editor={ClassicEditor}
                          config={{
                            language: {
                              ui: "en",
                              content: isRTL ? "ar" : "en",
                            },
                            alignment: {
                              options: ["left", "center", "right", "justify"],
                            },
                          }}
                          onReady={(editor) => {}}
                          data={description}
                          onChange={(event, editor) => {
                            setDescription(editor.getData());
                          }}
                          onBlur={(event, editor) => {}}
                          onFocus={(event, editor) => {}}
                        />
                      </div>

                      <div className="custom-ekeditor m-b30">
                        <label htmlFor="brand" className="fs-4 fw-semibold">
                          Additional Information
                        </label>
                        {/* <button
                          className="btn btn-outline-success btn-sm ms-3"
                          onClick={toggleDirection}
                        >
                          Switch to {isRTL ? "LTR" : "RTL"}
                        </button> */}
                        <CKEditor
                          editor={ClassicEditor}
                          config={{
                            language: {
                              ui: "en",
                              content: isRTL ? "ar" : "en",
                            },
                            alignment: {
                              options: ["left", "center", "right", "justify"],
                            },
                          }}
                          onReady={(editor) => {}}
                          data={additionalInfo}
                          onChange={(event, editor) => {
                            setAdditionalInfo(editor.getData());
                          }}
                          onBlur={(event, editor) => {}}
                          onFocus={(event, editor) => {}}
                        />
                      </div>

                      <div className="custom-ekeditor m-b30">
                        <label htmlFor="brand" className="fs-4 fw-semibold">
                          Summary
                        </label>
                        {/* <button
                          className="btn btn-outline-success btn-sm ms-3"
                          onClick={toggleDirection}
                        >
                          Switch to {isRTL ? "LTR" : "RTL"}
                        </button> */}
                        <CKEditor
                          editor={ClassicEditor}
                          config={{
                            language: {
                              ui: "en",
                              content: isRTL ? "ar" : "en",
                            },
                            alignment: {
                              options: ["left", "center", "right", "justify"],
                            },
                          }}
                          onReady={(editor) => {}}
                          data={summary}
                          onChange={(event, editor) => {
                            setSummary(editor.getData());
                          }}
                          onBlur={(event, editor) => {}}
                          onFocus={(event, editor) => {}}
                        />
                      </div>
                      <div className="my-4">
                        <label>Colors</label>
                        <CustomClearIndicator
                          colors={colors}
                          status={status}
                          onValueChange={handleValueChange}
                        ></CustomClearIndicator>
                      </div>

                      {/* Add Sizes Field */}
                      {sizes.map((size, index) => (
                        <div key={index} className="row">
                          <div className="col-sm-5 m-b30">
                            <label htmlFor={`size-name-${index}`}>
                              Size Name
                            </label>
                            <input
                              type="text"
                              id={`size-name-${index}`}
                              className="form-control"
                              value={size.name}
                              onChange={(e) =>
                                handleSizeChange(index, "name", e.target.value)
                              }
                            />
                          </div>
                          <div className="col-sm-5 m-b30">
                            <label htmlFor={`size-price-${index}`}>Price</label>
                            <input
                              type="text"
                              id={`size-price-${index}`}
                              className="form-control"
                              value={size.price}
                              onChange={(e) =>
                                handleSizeChange(index, "price", e.target.value)
                              }
                            />
                          </div>
                          <div className="col-sm-2 m-b30 d-flex justify-content-center align-content-center">
                            <div className="mt-4">
                              <button
                                type="button"
                                className="btn btn-outline-danger"
                                onClick={(e) => handleDeleteSize(index)}
                              >
                                <FaTrash /> <span className="ms-2">Delete</span>
                              </button>
                            </div>
                          </div>
                        </div>
                      ))}
                      <button
                        className="btn btn-outline-primary w-25 mx-auto"
                        type="button"
                        onClick={handleAddSize}
                        disabled={mainPrice > 0}
                      >
                        Add Size
                      </button>
                      {mainPrice > 0 && (
                        <p className="text-danger text-center">
                          You can't add size if you have main price
                        </p>
                      )}

                      {/* Main Price */}
                      <div className="form-group m-b30">
                        <label htmlFor="mainPrice">Main Price</label>
                        <input
                          type="number"
                          id="mainPrice"
                          className="form-control"
                          value={mainPrice}
                          onChange={(e) => setMainPrice(e.target.value)}
                        />
                      </div>

                      {/* Add Discount Field */}
                      <div className="form-group m-b30">
                        <label htmlFor="discount">Discount (%)</label>
                        <input
                          type="number"
                          id="discount"
                          className="form-control"
                          value={discount}
                          // onChange={(e) => setDiscount(e.target.value)}
                          onChange={(event) => {
                            setDiscount(parseFloat(event.target.value));
                          }}
                        />
                      </div>

                      {/* Add Stock Status Field */}
                      <div className="form-group col-sm-6 m-b30">
                        <label htmlFor="stockStatus">Stock Status</label>
                        <select
                          id="stockStatus"
                          className="form-control"
                          value={storageStatus}
                          defaultValue={"in"}
                          onChange={(e) => setStorageStatus(e.target.value)}
                        >
                          <option disabled value="">
                            Select Status
                          </option>
                          <option value="in">Stock In</option>
                          <option value="out">Out of Stock</option>
                        </select>
                      </div>

                      {/* Add Video Link Field */}
                      <div className="form-group col-sm-6 m-b30">
                        <label htmlFor="videoLink">Video URL</label>
                        <input
                          type="text"
                          id="videoLink"
                          className="form-control"
                          value={videoUrl}
                          onChange={(e) => setVideoUrl(e.target.value)}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="card-footer">
                    {btnLoading ? (
                      <button
                        className="btn btn-primary"
                        type="button"
                        disabled
                      >
                        <span
                          className="spinner-border spinner-border-sm me-2"
                          role="status"
                          aria-hidden="true"
                        ></span>
                        Loading...
                      </button>
                    ) : (
                      <input
                        type="submit"
                        value="Submit"
                        onKeyDown={preventFormSubmitOnEnter}
                        onClick={(e) => {
                          Swal.fire({
                            position: "top-end",
                            title: "Wait a moment, please",
                            showConfirmButton: false,
                            icon: "info",
                            timer: 2000,
                            toast: true,
                          });
                          handleSubmit(e);
                        }}
                        className="btn btn-primary"
                      />
                    )}
                  </div>
                </div>
              </>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default AddProduct;
