import React, { useEffect, useState } from "react";
import { Card, Form } from "react-bootstrap";
import { useDispatch } from "react-redux";
import Swal from "sweetalert2";
import {
  createShopDetails,
  fetchShopDetails,
} from "../../../store/shopDetails/ShopDetailsSlice";
import { useSelector } from "react-redux";

const ProfileSettings = () => {
  const [businessName, setBusinessName] = useState("");
  const [loading, setLoading] = useState(false);
  const [name, setName] = useState("");
  const [link, setLink] = useState("");
  const [address, setAddress] = useState("");
  const [phone, setPhone] = useState("");
  const [base64, setBase64] = useState("");
  const dispatch = useDispatch();

  // Handle Image Upload
  const handleFileChange = async (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        const fileNameClean = reader.result.split("base64,")[1];
        setBase64(fileNameClean);
      };
      reader.readAsDataURL(file);
    } else {
    }
  };

  useEffect(() => {
    dispatch(fetchShopDetails());
  }, [dispatch]);
  const shopDetails = useSelector((state) => state.ShopDetails.shopDetails);

  const handleSubmit = async () => {
    // if (!name || !link || !address || !phone || !base64) {
    //   return Swal.fire({
    //     icon: "error",
    //     title: "Error!",
    //     text: "Please fill out all the fields.",
    //   });
    // }
    try {
      setLoading(true);
      const data = {
        name,
        link,
        address,
        phone,
        logo: base64
      };
      dispatch(createShopDetails({ data: data, shopId: shopDetails?.id }));
    } catch (error) {
      console.error("Error updating info:", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      <div className="row">
        <div className="col-md-3">
          <h5>Profile Settings</h5>
        </div>
        <div className="col-md-9">
          <h5>Name</h5>
          <input
            value={name}
            onChange={(e) => setName(e.target.value)}
            type="text"
            className="form-control mb-2"
          />
          <h5>Link</h5>
          <input
            value={link}
            onChange={(e) => setLink(e.target.value)}
            type="text"
            className="form-control mb-2"
          />
          <h5>Address</h5>
          <input
            value={address}
            onChange={(e) => setAddress(e.target.value)}
            type="text"
            className="form-control mb-2"
          />
          <h5>Phone</h5>
          <input
            value={phone}
            onChange={(e) => setPhone(e.target.value)}
            type="text"
            className="form-control mb-2"
          />
          <h5>Image</h5>
          <input
            onChange={handleFileChange}
            type="file"
            className="form-control mb-2"
          />
          <button
            onClick={() => {
              handleSubmit();
            }}
            className="btn btn-outline-primary rounded-5 mt-4"
          >
            Save
          </button>
        </div>
      </div>
      <Card.Footer className="mt-3" />
    </div>
  );
};

export default ProfileSettings;
