import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import SmsCounter from "sms-counter";
import { useDispatch, useSelector } from "react-redux";
import Swal from "sweetalert2";
import {
  deleteAsyncInbox,
  fetchAsyncInbox,
  fetchAsyncSingleInbox,
} from "../../../store/Message/InboxSlice";
import { createAsyncReplyMessage } from "../../../store/Message/OutBoxSlice";
import Loading from "../../components/Loading";

const SingleMessage = ({ singleMessageId }) => {
  // All Hooks
  const dispatch = useDispatch();
  const navigate = useNavigate();
  // all state
  const [message, setMessage] = useState("");
  const [smsInfo, setSmsInfo] = useState(null);

  useEffect(() => {
    dispatch(fetchAsyncSingleInbox({ id: singleMessageId }));
  }, [dispatch, singleMessageId]);
  const singleMessage = useSelector((state) => state.Inbox.singleInbox);
  const singleMessageStatus = useSelector((state) => state.Inbox.status);
  const messageSentStatus = useSelector((state) => state.Outbox.status);

  // handles are here
  const handleInputChange = (event) => {
    event.preventDefault();
    const newMessage = event.target.value;
    setMessage(newMessage);
    const smsCount = SmsCounter.count(newMessage, true);
    setSmsInfo(smsCount);
  };

  // handles for send message
  const handleSubmitData = () => {
    const data = {
      phone: singleMessage.phone,
      replay: message,
    };
    dispatch(createAsyncReplyMessage({ data: data, id: singleMessageId }));
    setMessage("");
  };

  // Delete message
  const handleDeleteMessage = (id) => {
    Swal.fire({
      title: "Are you sure you want to delete this message?",
      text: "You will not be able to recover it!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#aaa",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(deleteAsyncInbox({ id: id }));
        navigate("/all-message");
      }
    });
    dispatch(fetchAsyncInbox());
  };

  // Regex pattern for a valid Lebanese phone number
  const lebanesePhoneNumberPattern = /^(\+961|0)?(1|3|4|7|8)\d{7}$/;
  // const lebanesePhoneNumberPattern = true;

  return (
    <>
      <div role="toolbar" className="toolbar ms-1 ms-sm-0">
        <div className="saprat ps-3">
          <div className="mail-tools ms-0 mt-3">
            <Link to="/all-message" className="btn btn-primary px-3 light me-2">
              <i className="fas fa-arrow-left"></i>
            </Link>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-12">
          <div className="right-box-padding border-start p-0">
            <div className="read-wapper dz-scroll mb-3" id="read-content">
              {singleMessageStatus === "loading" ? (
                <Loading status={singleMessageStatus} />
              ) : (
                <>
                  <div className="read-content">
                    <div className="media pt-3 d-sm-flex d-block justify-content-between">
                      <div className="clearfix mb-3 d-flex">
                        <div className="media-body me-2">
                          <h5 className="text-primary mb-0 mt-1">
                            Name: {singleMessage?.name}
                          </h5>
                          <h5 className="my-2">
                            Phone: {singleMessage?.phone}
                          </h5>
                          <p className="mb-0">
                            Date and Time:{" "}
                            {new Date(
                              singleMessage?.createdAt
                            ).toLocaleString()}
                          </p>
                        </div>
                      </div>
                      <div className="clearfix mb-3">
                        <a
                          href="#write-email"
                          className="btn btn-primary px-3 light me-2"
                        >
                          <i className="fa fa-reply"></i>
                        </a>
                        <Link
                          onClick={() => handleDeleteMessage(singleMessageId)}
                          className="btn btn-primary px-3 light me-2"
                        >
                          <i className="fa fa-trash"></i>
                        </Link>
                      </div>
                    </div>
                    <hr />
                    <div className="media mb-2 mt-3">
                      <div className="media-body">
                        <p className="read-content-email">
                          Subject: {singleMessage?.subject}
                        </p>
                      </div>
                    </div>
                    <div className="read-content-body">
                      <p className="mb-2">{singleMessage?.message}</p>
                      <hr />
                      <h5>Your Reply</h5>
                      {singleMessage?.replies?.length > 0 ? (
                        singleMessage.replies.map((reply, index) => (
                          <div
                            key={index}
                            className="border p-3 rounded-2 mb-4"
                          >
                            <p className="mb-2">{reply.replay}</p>
                          </div>
                        ))
                      ) : (
                        <p>You didn't reply anything.</p>
                      )}
                    </div>
                    <div className="mb-3 pt-3">
                      {lebanesePhoneNumberPattern.test(singleMessage?.phone) ? (
                        <>
                          <>
                            <textarea
                              name="write-email"
                              id="write-email"
                              cols="30"
                              rows="5"
                              className="form-control"
                              placeholder="enter your message..."
                              value={message}
                              onChange={handleInputChange}
                            ></textarea>
                            {smsInfo && (
                              <div className="row justify-content-between">
                                <small className="col form-text">
                                  Character Count: {smsInfo.length}
                                </small>
                                <small className="col form-text text-center">
                                  Remaining Character: {smsInfo.remaining}
                                </small>
                                <small className="col form-text text-end">
                                  SMS Count: {smsInfo.messages}
                                </small>
                              </div>
                            )}
                          </>
                          <div className="text-end mt-2">
                            {messageSentStatus === "loading" ? (
                              <button
                                className="btn btn-primary btn-sl-sm me-2"
                                type="button"
                                disabled
                              >
                                <span className="me-2">
                                  <i className="fa fa-spinner fa-spin"></i>
                                </span>
                                Sending...
                              </button>
                            ) : (
                              <button
                                className="btn btn-primary btn-sl-sm me-2"
                                type="button"
                                onClick={() => handleSubmitData()}
                              >
                                <span className="me-2">
                                  <i className="fa fa-paper-plane"></i>
                                </span>
                                Send
                              </button>
                            )}
                          </div>
                        </>
                      ) : (
                        <p className="text-center text-danger">
                          You can only reply to a lebanese number!
                        </p>
                      )}
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SingleMessage;
