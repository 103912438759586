import React from "react";
import { Modal } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { createAsyncSubCategory } from "../../../store/category/categorySlice";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

const SubCategoryModal = (props) => {
  const id = props.id;
  const dispatch = useDispatch();
  const loading = useSelector((state) => state.Category.status);
  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();
  const onSubmit = (data) => {
    dispatch(createAsyncSubCategory({data: data, id: id}));
    reset();
    props.onHide();
    navigate("/categories");
  };

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Add Sub Category
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="form-group">
            <label className="form-label">Sub Category Name</label>
            <input
              type="text"
              className="form-control mb-3"
              placeholder="Name"
              {...register("name", { required: "Sub-Category Name required" })}
            />
            {errors.name && (
              <p className="text-danger">{errors.name.message}</p>
            )}
            <label className="form-label">Description</label>
            <input
              type="text"
              className="form-control mb-3"
              placeholder="Description"
              {...register("description", { required: "Description is required" })}
            />
            {errors.description && (
              <p className="text-danger">{errors.description.message}</p>
            )}
          </div>
          <div className="text-end">
            {loading === "loading" ? (
              <button className="btn btn-primary" type="button" disabled>
                <span
                  className="spinner-border spinner-border-sm me-1"
                  role="status"
                  aria-hidden="true"
                ></span>
                Loading...
              </button>
            ) : (
              <button type="submit" className="btn btn-primary">
                Save
              </button>
            )}
            {/* <button type="submit" className="btn btn-primary">
              Save
            </button> */}
          </div>
        </form>
      </Modal.Body>
    </Modal>
  );
};

export default SubCategoryModal;
