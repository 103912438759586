import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { useDispatch } from "react-redux";
import {
  CreateAsyncPostDelivery,
  fetchAsyncAllDelivery,
} from "../../../../store/DeliveryZones/DeliveryZonesSlice";
import Swal from "sweetalert2";

const DeliveryZonesModal = (props) => {
  const dispatch = useDispatch();
  const [areaName, setAreaName] = useState("");
  const [deliveryFee, setDeliveryFee] = useState("");
  const status = props.status;

  const handleSubmit = async () => {
    if (!areaName || !deliveryFee) {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "All fields are required",
      });
      return;
    }
    const data = {
      deliveryFees: [
        {
          areaName: areaName,
          deliveryFee: deliveryFee,
        },
      ],
    };

    try {
      await dispatch(CreateAsyncPostDelivery(data));
      await dispatch(fetchAsyncAllDelivery());
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Failed to add delivery zone",
      });
    }
    props.onHide();
    setAreaName("");
    setDeliveryFee("");
  };

  return (
    <div>
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Edit Orders
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <label className="form-label">Area Name</label>
          <input
            onChange={(e) => setAreaName(e.target.value)}
            value={areaName}
            type="text"
            className="form-control mb-2"
          />
          <label className="form-label">Delivery Fee</label>
          <input
            onChange={(e) => setDeliveryFee(e.target.value)}
            value={deliveryFee}
            type="number"
            className="form-control mb-2"
          />
          <div className="text-end mt-2">
            {status === "loading" ? (
              <button className="btn btn-primary" type="button" disabled>
                <span
                  className="spinner-border spinner-border-sm"
                  role="status"
                  aria-hidden="true"
                ></span>
                Loading...
              </button>
            ) : (
              <button
                onClick={handleSubmit}
                type="submit"
                className="btn btn-primary"
              >
                Save
              </button>
            )}
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default DeliveryZonesModal;
