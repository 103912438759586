import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import Loading from "../../components/Loading";
import {
  deleteAsyncTag,
  fetchAsyncAllTags,
} from "../../../store/tags/tagsSlice";
import Swal from "sweetalert2";
import { FaRegEye } from "react-icons/fa6";
import { MdEdit } from "react-icons/md";
import { MdDeleteForever } from "react-icons/md";

const Tags = () => {
  const dispatch = useDispatch();

  // Fetch all categories
  useEffect(() => {
    dispatch(fetchAsyncAllTags());
  }, [dispatch]);

  const tags = useSelector((state) => state.Tag.allTag);
  const status = useSelector((state) => state.Tag.status);

  const handleDeleteTag = async (id, e) => {
    e.preventDefault();
    const result = await Swal.fire({
      title: "Are you sure?",
      text: "You want to delete this Tag?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#3085d6",
      confirmButtonText: "Yes, delete it!",
    });

    if (result.isConfirmed) {
      try {
        await dispatch(deleteAsyncTag(id));
        Swal.fire("Deleted!", "Your Tag has been deleted.", "success");
        await dispatch(fetchAsyncAllTags());
      } catch (error) {
        Swal.fire(
          "Error!",
          "Failed to delete Tag. Please try again later.",
          "error"
        );
      }
    }
  };

  return (
    <div className="container">
      <Link className="text-white" to="/tags/add">
        <button className={`btn btn-primary`}>Create new tags</button>
      </Link>

      {status === "loading" ? (
        <Loading status={status} />
      ) : (
        <div className="row">
          <div className="col-xl-12">
            <div className="card mt-2">
              <div className="card-body p-0">
                <div className="table-responsive active-projects style-1 ItemsCheckboxSec shorting ">
                  <div
                    id="orderlist-table"
                    className="dataTables_wrapper no-footer"
                  >
                    <table
                      id="empoloyees-tbl"
                      className="table dataTable no-footer"
                    >
                      <thead>
                        <tr>
                          <th>name</th>
                          <th className="text-end">Actions</th>
                        </tr>
                      </thead>
                      <tbody>
                        {tags && tags.length > 0 ? (
                          <>
                            {tags?.map((tag) => {
                              return (
                                <tr className="list-border" key={tag?.id}>
                                  <td>
                                    <span>{tag?.name}</span>
                                  </td>
                                  <td className="text-end">
                                    <Link to={`/tag/${tag?.id}/products`}>
                                      <button className="btn btn-outline-success me-2">
                                        <FaRegEye />
                                      </button>
                                    </Link>
                                    <Link to={`/tags/edit/${tag?.id}`}>
                                      <button className="btn btn-outline-primary me-2">
                                        <MdEdit />
                                      </button>
                                    </Link>
                                    <button
                                      className="btn btn-outline-danger"
                                      onClick={(e) =>
                                        handleDeleteTag(tag?.id, e)
                                      }
                                    >
                                      <MdDeleteForever />
                                    </button>
                                  </td>
                                </tr>
                              );
                            })}
                          </>
                        ) : (
                          <h3 className="mt-4 ms-4 ">No product found</h3>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Tags;
