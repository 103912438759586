import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Tab } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import Loading from "../../components/Loading";
import { AsyncMarkAsRead, fetchAsyncUnread } from "../../../store/Message/InboxSlice";
import Swal from "sweetalert2";
import { fetchAsyncOutbox } from "../../../store/Message/OutBoxSlice";

const UnreadMessage = ({ page }) => {
  const dispatch = useDispatch();

  // Fetch all unread messages
  useEffect(() => {
    dispatch(fetchAsyncUnread());
  }, [dispatch]);
  const allUnreadMessage = useSelector((state) => state.Inbox.unReadInbox);
  const status = useSelector((state) => state.Inbox.status);

  const [dataToShow, setDataToShow] = useState([]);
  useEffect(() => {
    if (allUnreadMessage?.length > 0) {
      setDataToShow(allUnreadMessage);
    }
  }, [allUnreadMessage]);

  // Chackbox function
  const chackbox = document.querySelectorAll(".message input");
  const motherChackBox = document.querySelector("#checkbox1");

  // pagination
  const [currentPage, setCurrentPage] = useState(1);
  const recordsPage = 10;
  const lastIndex = currentPage * recordsPage;
  const firstIndex = lastIndex - recordsPage;
  const paginatedData = dataToShow?.slice(firstIndex, lastIndex);

  const npage = Math.ceil(dataToShow?.length / recordsPage);
  const number = [...Array(npage + 1)?.keys()]?.slice(1);

  function prePage() {
    if (currentPage !== 1) {
      setCurrentPage(currentPage - 1);
    }
  }
  function changeCPage(id) {
    setCurrentPage(id);
  }
  function nextPage() {
    if (currentPage !== npage) {
      setCurrentPage(currentPage + 1);
    }
  }

  const handleMarkRead = async(id) => {
    await dispatch(AsyncMarkAsRead({id: id}));
    Swal.fire({
      position: "top-end",
      title: "Message marked as read",
      showConfirmButton: false,
      icon: "info",
      timer: 1000,
      toast: true,
    });
    await dispatch(fetchAsyncOutbox());
  }


  return (
    <>
      <Tab.Container defaultActiveKey="Import">
        <div className="email-list">
          {status === "loading" ? (
            <Loading status={status} />
          ) : (
            <>
              {allUnreadMessage?.length === 0 && (
                <div className="text-center mt-5">
                  No unread message in this page
                </div>
              )}
              {allUnreadMessage?.map((data, ind) => (
                <div className="message bg-white" key={ind}>
                  <div>
                    <div className="d-flex message-single">
                      <div className="ps-1 align-self-center mt-3">
                        <div className="form-check custom-checkbox">
                          <input
                            type="checkbox"
                            className="form-check-input"
                            onClick={() => handleMarkRead(data.id)}
                            id="checkbox2"
                          />
                        </div>
                      </div>
                    </div>
                    <Link
                      to={`/single-message/${data.id}`}
                      className="col-mail col-mail-2"
                    >
                      <div className="hader">{data.name}</div>
                      <div className="subject">{data.subject}</div>
                      <div className="date">
                        {new Date(data.createdAt).toLocaleString()}{" "}
                      </div>
                    </Link>
                  </div>
                </div>
              ))}
            </>
          )}
        </div>
      </Tab.Container>
    </>
  );
};

export default UnreadMessage;
