import { del, get, patch } from "aws-amplify/api";
import { amplifyConfigs } from "../../config";

export async function getMessageRead() {
  try {
    const restOperation = get({
      apiName: amplifyConfigs.apiName,
      path: "/inboxs",
    });
    const response = await restOperation.response;
    return response.body.json();
  } catch (error) {
    
  }
}

export async function getMessageUnread() {
  try {
    const restOperation = get({
      apiName: amplifyConfigs.apiName,
      path: "/inboxs/unread",
    });
    const response = await restOperation.response;
    return response.body.json();
  } catch (error) {
    
  }
}

export async function getSingleMessage({id}) {
  try {
    const restOperation = get({
      apiName: amplifyConfigs.apiName,
      path: `/inboxs/${id}`,
    });
    const response = await restOperation.response;
    return response.body.json();
  } catch (error) {
    
  }
}

// Delete inbox
export async function deleteMessage({id}) {
  try {
    const restOperation = del({
      apiName: amplifyConfigs.apiName,
      path: `/inboxs/${id}`,
    });
    const response = await restOperation.response;
    return response.body.json();
  } catch (error) {
    
  }
}

// Mark as read
export async function markAsRead({id}) {
  try {
    const restOperation = patch({
      apiName: amplifyConfigs.apiName,
      path: `/inboxs/${id}`,
    });
    const response = await restOperation.response;
    return response.body.json();
  } catch (error) {
    
  }
}