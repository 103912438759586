import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  deleteCategory,
  deleteSubCategory,
  getCategory,
  getSingleCategory,
  getSingleSubCategory,
  postCategory,
  postSubCategory,
  updateCategory,
  updateSubCategory,
} from "./categoryAPI";

// Fetch All Categorys
export const fetchAsyncAllCategory = createAsyncThunk(
  "category/fetchCategory",
  async () => {
    const products = await getCategory();
    return products;
  }
);

// Fetch single Categorys
export const fetchAsyncSingleCategory = createAsyncThunk(
  "category/fetchSingleCategory",
  async (id) => {
    const response = await getSingleCategory(id);
    return response;
  }
);

// Create Category
export const createAsyncCategory = createAsyncThunk(
  "category/createCategory",
  async (data) => {
    const response = await postCategory(data);
    return response;
  }
);

// Async thunk for updating a category
export const updateAsyncCategory = createAsyncThunk(
  "category/updateCategory",
  async (data) => {
    const response = await updateCategory(data);
    return response;
  }
);

// Async thunk for deleting a category
export const deleteAsyncCategory = createAsyncThunk(
  "category/deleteCategory",
  async (id) => {
    const response = await deleteCategory(id);
    return response;
  }
);

// Add sub-category
export const createAsyncSubCategory = createAsyncThunk(
  "subCategory/createSubCategory",
  async (data) => {
    const response = await postSubCategory(data);
    return response;
  }
);

// Async thunk for updating sub category
export const updateAsyncSubCategory = createAsyncThunk(
  "subCategory/updateSubCategory",
  async (data) => {
    const response = await updateSubCategory(data);
    return response;
  }
);

export const fetchAsyncSubCategory = createAsyncThunk(
  "subCategory/getSubCategory",
  async (data) => {
    const response = await getSingleSubCategory(data);
    return response;
  }
);

export const deleteAsyncSubCategory = createAsyncThunk(
  "subCategory/deleteSubCategory",
  async (id) => {
    await deleteSubCategory(id);
    return id;
  }
);

const initialState = {
  allCategory: [],
  singleCategory: {},
  singleSubCategory: {},
  subCategory: [],
  status: "idle",
  error: null,
};

// Category slice
const categorySlice = createSlice({
  name: "categories",
  initialState,
  extraReducers: (builder) => {
    builder
      // Handle fetchAsyncAllCategory
      .addCase(fetchAsyncAllCategory.pending, (state) => {
        state.status = "loading";
        state.error = null;
      })
      .addCase(fetchAsyncAllCategory.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.allCategory = action.payload;
      })
      .addCase(fetchAsyncAllCategory.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      // Handle fetch single Category
      .addCase(fetchAsyncSingleCategory.pending, (state) => {
        state.status = "loading";
        state.error = null;
      })
      .addCase(fetchAsyncSingleCategory.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.singleCategory = action.payload;
      })
      .addCase(fetchAsyncSingleCategory.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      // Handle createAsyncCategory
      .addCase(createAsyncCategory.pending, (state) => {
        state.status = "loading";
        state.error = null;
      })
      .addCase(createAsyncCategory.fulfilled, (state, action) => {
        state.allCategory.push(action.payload);
        state.status = "succeeded";
      })
      .addCase(createAsyncCategory.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      // Handle createAsyncSubCategory
      .addCase(createAsyncSubCategory.pending, (state) => {
        state.status = "loading";
        state.error = null;
      })
      .addCase(createAsyncSubCategory.fulfilled, (state, action) => {
        state.subCategory.push(action.payload);
        state.status = "succeeded";
      })
      .addCase(createAsyncSubCategory.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(fetchAsyncSubCategory.pending, (state) => {
        state.status = "loading";
        state.error = null;
      })
      .addCase(fetchAsyncSubCategory.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.singleSubCategory = action.payload;
      })
      .addCase(fetchAsyncSubCategory.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })

      // Handle update Category
      .addCase(updateAsyncCategory.pending, (state) => {
        state.status = "loading";
        state.error = null;
      })
      .addCase(updateAsyncCategory.fulfilled, (state, action) => {
        const updateCategory = action.payload;
        if (updateCategory && updateCategory.id) {
          state.allCategory = state.allCategory.map((c) =>
            c.id === updateCategory.id ? updateCategory : c
          );
        }
      })
      .addCase(updateAsyncCategory.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      // Handle update Category
      .addCase(updateAsyncSubCategory.pending, (state) => {
        state.status = "loading";
        state.error = null;
      })
      .addCase(updateAsyncSubCategory.fulfilled, (state, action) => {
        const updateSubCategory = action.payload;
        if (updateSubCategory && updateSubCategory.id) {
          state.subCategory = state.subCategory.map((c) =>
            c.id === updateSubCategory.id ? updateSubCategory : c
          );
        }
      })
      .addCase(updateAsyncSubCategory.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      // Handle deleteAsync Category
      .addCase(deleteAsyncCategory.pending, (state) => {
        state.status = "loading";
        state.error = null;
      })
      .addCase(deleteAsyncCategory.fulfilled, (state, action) => {
        const deletedId = action.meta.arg;
        state.allCategory = state.allCategory.filter((c) => c.id !== deletedId);
        state.status = "succeeded";
      })
      .addCase(deleteAsyncCategory.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      // Handle deleteAsync sub Category
      .addCase(deleteAsyncSubCategory.pending, (state) => {
        state.status = "loading";
        state.error = null;
      })
      .addCase(deleteAsyncSubCategory.fulfilled, (state, action) => {
        const deletedId = action.payload.id;

        state.subCategory = state.subCategory.filter(
          (i) => i?.id !== deletedId
        );

        state.status = "succeeded";
      })
      .addCase(deleteAsyncSubCategory.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      });
  },
});

export default categorySlice.reducer;
