import React from "react";
import Select from "react-select";
import OrderSummaryChart from "../elements/OrderSummaryChart";

const progressData = [
  {
    title: "On Delivery",
    data: "6,245",
    color: "bg-primary",
    status: "30%",
    iconColoe: "#3B42F0",
  },
  {
    title: "Delivered",
    data: "2,355",
    color: "bg-success",
    status: "60%",
    iconColoe: "#4FD66E",
  },
  {
    title: "Canceled",
    data: "9,456",
    color: "bg-warning",
    status: "10%",
    iconColoe: "#FF8D0E",
  },
];

const options = [
  { value: "1", label: "Daily" },
  { value: "2", label: "Week" },
  { value: "3", label: "Month" },
];

const Summery = () => {
  return (
    <div className="col-xl-12 custome-width">
      <div className="card">
        <div className="card-header border-0 pb-0">
          <h3 className="h-title">Order Summary</h3>
          <div className="d-flex align-items-center">
            <Select
              options={options}
              className="custom-react-drop-btn"
              defaultValue={options[0]}
              isSearchable={false}
            />
            {/* <DropBtnBlog /> */}
          </div>
        </div>
        <div className="card-body ">
          <p style={{ width: "90%" }}>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit Lorem ipsum
            dolor sit amet, consectetur adipiscing elit.dolor sit amet,
            consectetur adipiscing elit
          </p>
          <div className="row">
            <div className="col-xl-6">
              <div id="piechart"></div>
              <OrderSummaryChart />
            </div>
            <div className="col-xl-6">
              {progressData?.map((item, index) => (
                <div className="mb-4" key={index}>
                  <div className="progress">
                    <div
                      className={`progress-bar linear ${item.color}`}
                      style={{ width: item.status, height: "13px" }}
                    >
                      <span className="sr-only">60% Complete</span>
                    </div>
                  </div>
                  <span className="d-flex align-items-center mt-2">
                    <span>
                      <svg
                        width="13"
                        height="14"
                        viewBox="0 0 13 14"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <rect
                          y="0.420288"
                          width="13"
                          height="13"
                          rx="4"
                          fill={item.iconColoe}
                        />
                      </svg>{" "}
                      <span className="mb-0  text-dark">{item.title}</span>
                    </span>
                    <span className="ms-auto font-w600">${item.data}</span>
                  </span>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Summery;
