import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  getOutBoxMessage,
  postOutBoxMessage,
  postOutBoxReplyMessage,
} from "./OutboxAPI";

// Post Reply message
export const createAsyncReplyMessage = createAsyncThunk(
  "inbox/replyMessage",
  async (data) => {
    const inbox = await postOutBoxReplyMessage(data);
    return inbox;
  }
);

// Fetch All outbox
export const fetchAsyncOutbox = createAsyncThunk(
  "inbox/fetchOutbox",
  async () => {
    const outbox = await getOutBoxMessage();
    return outbox;
  }
);

// Post Message
export const createAsyncPostMessage = createAsyncThunk(
  "inbox/postMessage",
  async (data) => {
    const outbox = await postOutBoxMessage(data);
    return outbox;
  }
);

const initialState = {
  allOutBox: [],
  singleOutbox: [],
  status: null,
  error: null,
};

// inbox slice
const OutBoxSlice = createSlice({
  name: "outBox",
  initialState,
  extraReducers: (builder) => {
    builder
      // Handle Post Message
      .addCase(createAsyncPostMessage.pending, (state) => {
        state.status = "loading";
        state.error = null;
      })
      .addCase(createAsyncPostMessage.fulfilled, (state, { payload }) => {
        state.status = "success";
        state.singleOutbox = payload;
      })
      .addCase(createAsyncPostMessage.rejected, (state, { error }) => {
        state.status = "failed";
        state.error = error.message;
      })
      // Handle Post Reply Message
      .addCase(createAsyncReplyMessage.pending, (state) => {
        state.status = "loading";
        state.error = null;
      })
      .addCase(createAsyncReplyMessage.fulfilled, (state, { payload }) => {
        state.status = "success";
        state.singleOutbox = payload;
      })
      .addCase(createAsyncReplyMessage.rejected, (state, { error }) => {
        state.status = "failed";
        state.error = error.message;
      })
      // Handle fetchAsyncOutbox
      .addCase(fetchAsyncOutbox.pending, (state) => {
        state.status = "loading";
        state.error = null;
      })
      .addCase(fetchAsyncOutbox.fulfilled, (state, { payload }) => {
        state.status = "success";
        state.allOutBox = payload;
      })
      .addCase(fetchAsyncOutbox.rejected, (state, { error }) => {
        state.status = "failed";
        state.error = error.message;
      });
  },
});

export default OutBoxSlice.reducer;
