import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { addOrder, addOrderProduct, editOrderInformation, editOrderProduct, getOrders, getSingleOrder, updateOrder } from "./orderApi";

export const fetchAllOrder = createAsyncThunk(
  "order/fetchAllOrder",
  async () => {
    const response = await getOrders();
    return response;
  }
);

// Create a new order
export const createAsyncOrder = createAsyncThunk(
  "orders/createOrder",
  async (data) => {
    try {
      const response = await addOrder(data);
      return response;
    } catch (error) {
      throw error;
    }
  }
);

export const fetchSingleOrder = createAsyncThunk(
  "orders/fetchSingleOrder",
  async (id) => {
    try {
      const order = await getSingleOrder(id);
      return order;
    } catch (error) {
      throw error;
    }
  }
);

// Update single order information
export const updateAsyncSingleOrderInfo = createAsyncThunk(
  "orders/updateAsyncSingleOrderInfo",
  async (data) => {
    try {
      const response = await editOrderInformation(data);
      return response;
    } catch (error) {
      throw error;
    }
  }
);

// Add order product
export const asyncAddOrderProduct = createAsyncThunk(
  "orders/addOrderProduct",
  async (data) => {
    try {
      const response = await addOrderProduct(data);
      return response;
    } catch (error) {
      throw error;
    }
  }
);

// Order product edit 
export const updateOrderProduct = createAsyncThunk(
  "orders/updateOrderProduct",
  async (data) => {
    try {
      const response = await editOrderProduct(data);
      return response;
    } catch (error) {
      throw error;
    }
  }
);


// Update order status
export const updateOrderStatus = createAsyncThunk(
  "orders/updateOrderStatus",
  async (data) => {
    try {
      const response = await updateOrder(data);
      return response;
    } catch (error) {
      throw error;
    }
  }
);

// Initial state
const initialState = {
  allOrder: [],
  singleOrder: {},
  status: "idle",
  updateOrderStatus: "idle",
  updateProductStatus: "idle",
  singleOrderStatus: "loading",
  error: null,
};

// Slice
const orderSlice = createSlice({
  name: "orders",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllOrder.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchAllOrder.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.allOrder = action.payload;
      })
      .addCase(fetchAllOrder.rejected, (state, action) => {
        state.status = "rejected";
        state.error = action.error.message;
      })
      .addCase(fetchSingleOrder.pending, (state) => {
        state.singleOrderStatus = "loading";
      })
      .addCase(fetchSingleOrder.fulfilled, (state, action) => {
        state.singleOrderStatus = "succeeded";
        state.singleOrder = action.payload;
      })
      .addCase(fetchSingleOrder.rejected, (state, action) => {
        state.singleOrderStatus = "rejected";
        state.error = action.error.message;
      })
      .addCase(updateOrderStatus.pending, (state) => {
        state.status = "loading";
      })
      .addCase(updateOrderStatus.fulfilled, (state) => {
        state.status = "succeeded";
      })
      .addCase(updateOrderStatus.rejected, (state, action) => {
        state.status = "rejected";
        state.error = action.error.message;
      })
      // Update single order information
      .addCase(updateAsyncSingleOrderInfo.pending, (state) => {
        state.updateOrderStatus = "loading";
      })
      .addCase(updateAsyncSingleOrderInfo.fulfilled, (state) => {
        state.updateOrderStatus = "succeeded";
      })
      .addCase(updateAsyncSingleOrderInfo.rejected, (state, action) => {
        state.updateOrderStatus = "rejected";
        state.error = action.error.message;
      })
      // Update order product
      .addCase(updateOrderProduct.pending, (state) => {
        state.updateProductStatus = "loading";
      })
      .addCase(updateOrderProduct.fulfilled, (state) => {
        state.updateProductStatus = "succeeded";
      })
      .addCase(updateOrderProduct.rejected, (state, action) => {
        state.updateProductStatus = "rejected";
        state.error = action.error.message;
      })
      // Create a new order
      .addCase(createAsyncOrder.pending, (state) => {
        state.status = "loading";
      })
      .addCase(createAsyncOrder.fulfilled, (state) => {
        state.status = "succeeded";
      })
      .addCase(createAsyncOrder.rejected, (state, action) => {
        state.status = "rejected";
        state.error = action.error.message;
      })
      // Add order product
      .addCase(asyncAddOrderProduct.pending, (state) => {
        state.status = "loading";
      })
      .addCase(asyncAddOrderProduct.fulfilled, (state) => {
        state.status = "succeeded";
      })
      .addCase(asyncAddOrderProduct.rejected, (state, action) => {
        state.status = "rejected";
        state.error = action.error.message;
      });

  },
});

export default orderSlice.reducer;
