import React from "react";
import { createBrowserRouter } from "react-router-dom";
import Error404 from "./pages/Error404";
import Home from "./components/Dashboard/Home/Home";

//Email File
import Inbox from "./pages/Inbox/Inbox";
import Profile from "./pages/Profile";
import MainLayout from "./layouts/MainLayout";
import Login from "./pages/Login";
import { Toaster } from "react-hot-toast";
import Customers from "./components/Dashboard/Customers";
import ConfirmPassword from "./pages/ConfirmPassword";

import Orders from "./pages/Orders/Orders";
import OrderDetails from "./pages/Orders/OrderDetails";
import AllProduct from "./pages/Product/AllProduct";
import DiscountCoupon from "./pages/DiscountCoupon/DiscountCoupon";
import ListOfShops from "./pages/Shops/ListOfShops";
import ShopDetails from "./pages/Shops/ShopDetails";
import Categories from "./pages/Categories/Categories";
import Brands from "./pages/Brands/Brands";
import AddProduct from "./pages/Product/AddProduct";
import AddBrands from "./pages/Brands/AddBrands";
import Collection from "./pages/Collections/Collection";
import CollectionForm from "./pages/Collections/CollectionForm";
import AddCategory from "./pages/Categories/AddCategory";
import PrivateRoute from "./PrivateRoute";
import Tags from "./pages/Tags/Tags";
import TagsForm from "./pages/Tags/TagsForm";
import Products from "./pages/Product/Products";
import SingleProduct from "./pages/Product/SingleProduct";
import General from "./pages/Settings/General";
import EditOrder from "./pages/Orders/EditOrder";
import AddOrder from "./pages/Orders/AddOrder";
// import ProductForm from "./pages/Product/ProductForm";

const Router = createBrowserRouter([
  {
    path: "/",
    element: (
      <>
        <MainLayout></MainLayout>
        <Toaster />
      </>
    ),
    errorElement: <Error404></Error404>,
    children: [
      { path: "/", element: <PrivateRoute allowedRoles={['admin']}><Home /></PrivateRoute> },
      { path: "profile-page", element: <PrivateRoute allowedRoles={['admin']}><Profile /></PrivateRoute> },
      { path: "general-page", element: <PrivateRoute allowedRoles={['admin']}><General /></PrivateRoute> },
      { path: "customers-page", element: <PrivateRoute allowedRoles={['admin']}><Customers /></PrivateRoute> },
      { path: "compose-page", element: <PrivateRoute allowedRoles={['admin']}><Inbox /></PrivateRoute> },
      { path: "inbox-page", element: <PrivateRoute allowedRoles={['admin']}><Inbox /></PrivateRoute> },
      { path: "all-message", element: <PrivateRoute allowedRoles={['admin']}><Inbox /></PrivateRoute> },
      { path: "sent-message", element: <PrivateRoute allowedRoles={['admin']}><Inbox /></PrivateRoute> },
      { path: "unread-message", element: <PrivateRoute allowedRoles={['admin']}><Inbox /></PrivateRoute> },
      { path: "single-message/:id", element: <PrivateRoute allowedRoles={['admin']}><Inbox /></PrivateRoute> },

      // Product Routes
      { path: "all-product", element: <PrivateRoute allowedRoles={['admin']}><AllProduct /></PrivateRoute> },
      // { path: "add-product", element: <PrivateRoute allowedRoles={['admin']}><ProductForm /></PrivateRoute> },
      { path: "order-page", element: <PrivateRoute allowedRoles={['admin']}><Orders /></PrivateRoute> },
      { path: "order-details/:id", element: <PrivateRoute allowedRoles={['admin']}><OrderDetails /></PrivateRoute> },
      { path: "add-order", element: <PrivateRoute allowedRoles={['admin']}><AddOrder /></PrivateRoute> },
      { path: "edit-order/:id", element: <PrivateRoute allowedRoles={['admin']}><EditOrder /></PrivateRoute> },
      { path: "discount-coupon", element: <PrivateRoute allowedRoles={['admin']}><DiscountCoupon /></PrivateRoute> },

      // List of Shops
      { path: "list-of-shops", element: <PrivateRoute allowedRoles={['super-admin']}><ListOfShops /></PrivateRoute> },
      { path: "shop-details/:id", element: <PrivateRoute allowedRoles={['admin']}><ShopDetails /></PrivateRoute> },

      //Products
      { path: "products", element: <PrivateRoute allowedRoles={['admin']}><Products /></PrivateRoute> },
      { path: "singleProduct/:id", element: <PrivateRoute allowedRoles={['admin']}><SingleProduct /></PrivateRoute> },
      { path: "add/products", element: <PrivateRoute allowedRoles={['admin']}><AddProduct /></PrivateRoute> },
      { path: "edit/products/:id", element: <PrivateRoute allowedRoles={['admin']}><AddProduct /></PrivateRoute> },
      { path: "edit/product/:id", element: <PrivateRoute allowedRoles={['admin']}><AddProduct /></PrivateRoute> },

       // Categories
       { path: "categories", element: <PrivateRoute allowedRoles={['admin']}><Categories /></PrivateRoute> },
       { path: "category/:id/products", element: <PrivateRoute allowedRoles={['admin']}><Products /></PrivateRoute> },
       { path: "categories/add", element: <PrivateRoute allowedRoles={['admin']}><AddCategory /></PrivateRoute> },
       { path: "categories/edit/:id", element: <PrivateRoute allowedRoles={['admin']}><AddCategory /></PrivateRoute> },
       
      //  sub categories
       { path: "sub-categories/:id/products", element: <PrivateRoute allowedRoles={['admin']}><Products /></PrivateRoute> },

      // tags
      { path: "tags", element: <Tags /> },
      { path: "tag/:id/products", element: <Products /> },
      { path: "tags/add", element: <TagsForm /> },
      { path: "tags/edit/:id", element: <TagsForm /> },

      { path: "collections", element: <PrivateRoute allowedRoles={['admin']}><Collection /></PrivateRoute> },
      { path: "collection/:id/products", element: <PrivateRoute allowedRoles={['admin']}><Products /></PrivateRoute> },
      { path: "collections/add", element: <PrivateRoute allowedRoles={['admin']}><CollectionForm /></PrivateRoute> },
      { path: "collections/edit/:id", element: <PrivateRoute allowedRoles={['admin']}><CollectionForm /></PrivateRoute> },

      // Brands
      { path: "brands", element: <PrivateRoute allowedRoles={['admin']}><Brands /></PrivateRoute> },
      { path: "brand/:id/products", element: <PrivateRoute allowedRoles={['admin']}><Products /></PrivateRoute> },
      { path: "brands/add", element: <PrivateRoute allowedRoles={['admin']}><AddBrands /></PrivateRoute> },
      { path: "brands/edit/:id", element: <PrivateRoute allowedRoles={['admin']}><AddBrands /></PrivateRoute> },
    ],
  },
  { path: "login", element: <Login /> },
  { path: "confirmPassword", element: <ConfirmPassword /> },
]);

export default Router;
