import React from "react";
import RevenueChart from "../elements/RevenueChart";

const revenue = {
  Income: 1710,
  Expense: 510,
};

const Revenue = () => {
  let series = [];
  // Define your colors
  const colors = ["#0000FF", "#FF0000"];
  // Map each title to a color
  const titleColorMap = revenue
    ? Object.keys(revenue).reduce((acc, title, index) => {
        acc[title] = colors[index % colors.length];
        return acc;
      }, {})
    : {};
  let total = 0;

  if (revenue && (revenue.Income || revenue.Expense)) {
    series = Object.values(revenue);
    total = series.reduce((a, b) => a + b, 0);
  }

  return (
    <div className="card">
      <div className="row">
        <div className=" col-xl-6 col-12">
          <RevenueChart />
        </div>
        <div className="col-xl-6 p-5">
          {revenue
            ? Object.entries(revenue).map(([title, amount], index) => (
                <div className="mb-4" key={index}>
                  <div className="progress">
                    <div
                      className={`progress-bar linear`}
                      style={{
                        width: `${(amount / total) * 100}%`,
                        height: "13px",
                        background: titleColorMap[title],
                      }}
                    >
                      <span className="sr-only">{`${
                        (amount / total) * 100
                      }% Complete`}</span>
                    </div>
                  </div>
                  <span className="d-flex align-items-center mt-2">
                    <span>
                      <svg
                        width="13"
                        height="14"
                        viewBox="0 0 13 14"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <rect
                          y="0.420288"
                          width="13"
                          height="13"
                          rx="4"
                          // fill="#1921FA"
                          fill={titleColorMap[title]}
                        />
                      </svg>
                      <span className="mb-0 ms-2 text-dark">{title}</span>
                    </span>
                    <span className="ms-auto font-w600">${amount}</span>
                  </span>
                </div>
              ))
            : {}}
        </div>
      </div>
    </div>
  );
};

export default Revenue;
