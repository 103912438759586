import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import {
  deleteAsyncCategory,
  fetchAsyncAllCategory,
} from "../../../store/category/categorySlice";
import { useSelector } from "react-redux";
import Loading from "../../components/Loading";
import Swal from "sweetalert2";
import { FaRegEye } from "react-icons/fa6";
import { MdEdit } from "react-icons/md";
import { MdDeleteForever } from "react-icons/md";

const Categories = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchAsyncAllCategory());
  }, [dispatch]);

  const categoriesList = useSelector((state) => state.Category.allCategory);
  const status = useSelector((state) => state.Category.status);

  const handleDeleteCategory = async (id, event) => {
    event.preventDefault();
    const result = await Swal.fire({
      title: "Are you sure?",
      text: "You want to delete this collection?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#3085d6",
      confirmButtonText: "Yes, delete it!",
    });
    if (result.isConfirmed) {
      try {
        await dispatch(deleteAsyncCategory(id));
        await dispatch(fetchAsyncAllCategory());
      } catch (error) {
        Swal.fire(
          "Error!",
          "Failed to delete collection. Please try again later.",
          "error"
        );
      }
    }
  };



  return (
    <div className="container">
      <Link className="text-white" to="/categories/add">
        <button className="btn btn-primary mb-3">create a new category</button>
      </Link>

      {status === "loading" ? (
        <Loading status={status} />
      ) : (
        <div className="row">
          <div className="col-xl-12">
            <div className="card mt-2">
              <div className="card-body p-0">
                <div className="table-responsive active-projects style-1 ItemsCheckboxSec shorting ">
                  <div
                    id="orderlist-table"
                    className="dataTables_wrapper no-footer"
                  >
                    <table
                      id="empoloyees-tbl"
                      className="table dataTable no-footer"
                    >
                      <thead>
                        <tr>
                          <th></th>
                          <th>name</th>
                          <th className="text-end">Actions</th>
                        </tr>
                      </thead>
                      <tbody>
                        {categoriesList && categoriesList.length > 0 ? (
                          <>
                            {categoriesList?.map((category) => {
                              return (
                                <tr className="list-border" key={category?.id}>
                                  <td>
                                    <div className="img-wrraper">
                                      <img
                                        src={category?.url}
                                        alt="category"
                                        className="rounded-circle list-images"
                                      />
                                    </div>
                                  </td>
                                  <td>
                                    <Link
                                      to={`/category/${category?.id}/products`}
                                    >
                                      <span>{category?.name}</span>
                                    </Link>
                                  </td>
                                  <td className="text-end">
                                    <Link
                                      to={`/category/${category?.id}/products`}
                                    >
                                      <button className="btn btn-outline-success me-2">
                                        <FaRegEye className="" />
                                      </button>
                                    </Link>
                                    <Link
                                      to={`/categories/edit/${category?.id}`}
                                    >
                                      <button className="btn btn-outline-primary me-2">
                                        <MdEdit />
                                      </button>
                                    </Link>
                                    <button
                                      className="btn btn-outline-danger"
                                      onClick={(event) =>
                                        handleDeleteCategory(
                                          category?.id,
                                          event
                                        )
                                      }
                                    >
                                      <MdDeleteForever />
                                    </button>
                                  </td>
                                </tr>
                              );
                            })}
                          </>
                        ) : (
                          <h3 className="mt-4 ms-4 ">No product found</h3>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Categories;
