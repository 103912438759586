import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { deleteDeliveryZone, getDeliveryZones, postDeliveryZones } from "./DeliveryZonesAPI";

// CreateAsyncPostDelivery
export const CreateAsyncPostDelivery = createAsyncThunk(
  "delivery/createDelivery",
  async (data) => {
    const products = await postDeliveryZones(data);
    return products;
  }
);

// Get all delivery zones
export const fetchAsyncAllDelivery = createAsyncThunk(
  "delivery/fetchDelivery",
  async () => {
    const products = await getDeliveryZones();
    return products;
  }
);

// Delete a delivery zone
export const deleteAsyncDelivery = createAsyncThunk(
  "delivery/deleteDelivery",
  async (id) => {
    const products = await deleteDeliveryZone(id);
    return products;
  }
);


const initialState = {
  allDelivery: [],
  addDelivery: {},
  status: "idle",
  error: null,
};

// delivery slice
const DeliveryZonesSlice = createSlice({
  name: "delivery",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(CreateAsyncPostDelivery.pending, (state) => {
      state.status = "loading";
    });
    builder.addCase(CreateAsyncPostDelivery.fulfilled, (state, action) => {
      state.addDelivery = action.payload;
      state.status = "succeeded";
    });
    builder.addCase(CreateAsyncPostDelivery.rejected, (state, action) => {
      state.status = "failed";
      state.error = action.error.message;
    });
    // Handle fetchAsyncAllDelivery
    builder.addCase(fetchAsyncAllDelivery.pending, (state) => {
      state.status = "loading";
      state.error = null;
    });
    builder.addCase(fetchAsyncAllDelivery.fulfilled, (state, action) => {
      state.status = "succeeded";
      state.allDelivery = action.payload;
    });
    builder.addCase(fetchAsyncAllDelivery.rejected, (state, action) => {
      state.status = "failed";
      state.error = action.error.message;
    })
    // Handle deleteAsyncDelivery
    builder.addCase(deleteAsyncDelivery.pending, (state) => {
      state.status = "loading";
    });
    builder.addCase(deleteAsyncDelivery.fulfilled, (state, action) => {
      state.status = "succeeded";
      state.allDelivery = state.allDelivery.filter(
        (delivery) => delivery.id !== action.meta.arg
      );
    });
    builder.addCase(deleteAsyncDelivery.rejected, (state, action) => {
      state.status = "failed";
      state.error = action.error.message;
    });
  },
});

export default DeliveryZonesSlice.reducer;
