import React, { useEffect, useState, useRef, useMemo } from "react";
import { Link } from "react-router-dom";
import { Tab, Modal } from "react-bootstrap";
import InactiveTab from "./elements/InactiveTab";
import Select from "react-select";
import { useDispatch } from "react-redux";
import {
  fetchAsyncAllCustomer,
  fetchAsyncCustomerWithAccount,
  fetchAsyncCustomerWithoutAccount,
} from "../../../store/Customer/CustomerSlice";
import { useSelector } from "react-redux";

const Customers = () => {
  const [addActive, setActive] = useState("All");
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchAsyncAllCustomer());
    dispatch(fetchAsyncCustomerWithAccount());
    dispatch(fetchAsyncCustomerWithoutAccount());
  }, [dispatch]);

  const allCustomers = useSelector((state) => state.Customer.customers);
  const loading = useSelector((state) => state.Customer.isLoading);
  const customersWithAccount = useSelector(
    (state) => state.Customer.customerWithAccount
  );
  const customersWithoutAccount = useSelector(
    (state) => state.Customer.customerWithoutAccount
  );

  const customer = useMemo(() => {
    switch (addActive) {
      case "All":
        return [
          ...allCustomers,
          ...customersWithAccount,
          ...customersWithoutAccount,
        ];
      case "withAccount":
        return customersWithAccount;
      case "withoutAccount":
        return customersWithoutAccount;
      default:
        return [];
    }
  }, [addActive, allCustomers, customersWithAccount, customersWithoutAccount]);

  const [currentPage, setCurrentPage] = useState(0);
  const itemsPerPage = 10;

  const currentData = useMemo(() => {
    const startIndex = currentPage * itemsPerPage;
    return customer.slice(startIndex, startIndex + itemsPerPage);
  }, [currentPage, customer]);

  const totalPages = Math.ceil(customer.length / itemsPerPage);

  const [customModal, setCustomModal] = useState(false);
  return (
    <div className="container">
      <div className="d-flex justify-content-between mb-4 flex-wrap">
        <ul className="revnue-tab nav nav-tabs" id="myTab">
          <li
            className="nav-item"
            onClick={() => {
              setActive("All");
            }}
          >
            <Link
              to={"#"}
              className={`nav-link ${addActive === "All" ? "active" : ""}`}
            >
              All
            </Link>
          </li>
          <li
            className="nav-item"
            onClick={() => {
              setActive("withAccount");
            }}
          >
            <Link
              to={"#"}
              className={`nav-link ${
                addActive === "withAccount" ? "active" : ""
              }`}
            >
              With An Account
            </Link>
          </li>
          <li
            className="nav-item"
            onClick={() => {
              setActive("withoutAccount");
            }}
          >
            <Link
              to={"#"}
              className={`nav-link ${
                addActive === "withoutAccount" ? "active" : ""
              }`}
            >
              Without An Account
            </Link>
          </li>
        </ul>

        <input
          type="text"
          className="form-control w-25"
          placeholder="Filter Search"
          aria-label="Search"
          aria-describedby="button-addon2"
        />

        <div className="d-flex align-items-center">
          <Select className="custom-react-drop-btn" isSearchable={false} />
        </div>
      </div>
      <Tab.Container defaultActiveKey={"Active"}>
        <div className="row">
          <div className="col-xl-12">
            <Tab.Content>
              <Tab.Pane eventKey={"Active"}>
                <div className="card mt-2">
                  <div className="card-body p-0">
                    <div className="table-responsive active-projects style-1 ItemsCheckboxSec shorting ">
                      <div
                        id="orderlist-table"
                        className="dataTables_wrapper no-footer"
                      >
                        <table
                          id="empoloyees-tbl"
                          className="table dataTable no-footer"
                        >
                          <thead>
                            <tr>
                              <th>name</th>
                              <th>Phone</th>
                              <th>Address</th>
                              <th>Spendings</th>
                            </tr>
                          </thead>
                          <tbody>
                            {loading === "loading" ? (
                              <p className="fs-4 mt-4 text-center">Loading</p>
                            ) : (
                              <>
                                {currentData?.map((item, index) => (
                                  <tr key={index}>
                                    <td>
                                      <span>
                                        {item.firstName} {item.lastName}
                                      </span>
                                    </td>
                                    <td>
                                      <span>{item.phone}</span>
                                    </td>
                                    <td>
                                      <div className="d-flex align-items-center">
                                        <p className="mb-0 ms-2">{item.city}</p>
                                      </div>
                                    </td>

                                    <td>
                                      <span className="badge badge-rounded badge-outline-primary badge-lg">
                                        $ {item.spending}
                                      </span>
                                    </td>
                                  </tr>
                                ))}
                              </>
                            )}
                          </tbody>
                        </table>
                        <div className="d-sm-flex text-center justify-content-between align-items-center">
                          <div className="dataTables_info">
                            Showing {currentPage * itemsPerPage + 1} to{" "}
                            {Math.min(
                              (currentPage + 1) * itemsPerPage,
                              customer.length
                            )}{" "}
                            of {customer.length} entries
                          </div>
                          <div
                            className="dataTables_paginate paging_simple_numbers justify-content-center"
                            id="example2_paginate"
                          >
                            <Link
                              className={`paginate_button previous ${
                                currentPage === 0 ? "disabled" : ""
                              }`}
                              to="#"
                              onClick={() =>
                                currentPage > 0 &&
                                setCurrentPage(currentPage - 1)
                              }
                            >
                              Prev
                            </Link>
                            <span>
                              {Array.from({ length: totalPages }, (_, i) => (
                                <Link
                                  key={i}
                                  to="#"
                                  className={`paginate_button ${
                                    currentPage === i ? "current" : ""
                                  }`}
                                  onClick={() => setCurrentPage(i)}
                                >
                                  {i + 1}
                                </Link>
                              ))}
                            </span>
                            <Link
                              className={`paginate_button next ${
                                currentPage + 1 === totalPages ? "disabled" : ""
                              }`}
                              to="#"
                              onClick={() =>
                                currentPage + 1 < totalPages &&
                                setCurrentPage(currentPage + 1)
                              }
                            >
                              Next
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Tab.Pane>
              <Tab.Pane eventKey={"Inactive"}>
                <InactiveTab />
              </Tab.Pane>
            </Tab.Content>
          </div>
        </div>
      </Tab.Container>
      <Modal show={customModal} onHide={setCustomModal} centered>
        <div className="modal-content">
          <div className="modal-header ">
            <h5 className="modal-title">Add Customer</h5>
            <button
              type="button"
              className="btn-close"
              onClick={() => setCustomModal(false)}
            ></button>
          </div>
          <div className="modal-body">
            <div className="form-group">
              <label className="form-label">Customer Name</label>
              <input
                type="text"
                className="form-control mb-3"
                id="exampleInputEmail1"
                placeholder="Name"
              />
              <label className="form-label">Email</label>
              <input
                type="email"
                className="form-control mb-3"
                id="exampleInputEmail2"
                placeholder=" Email"
              />
              <label className="form-label">Location</label>
              <input
                type="text"
                className="form-control mb-3"
                id="exampleInputEmail3"
                placeholder="Location"
              />
            </div>
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-danger light"
              onClick={() => setCustomModal(false)}
            >
              Close
            </button>
            <button type="button" className="btn btn-primary">
              Save
            </button>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default Customers;
