import React, { useEffect, useState } from "react";
// import Select from "react-select";
import { useDispatch } from "react-redux";
import {
  asyncAddOrderProduct,
  fetchSingleOrder,
  updateAsyncSingleOrderInfo,
} from "../../../store/order/orderSlice";
import { useSelector } from "react-redux";
import OrderProductModal from "./OrderModals/OrderProductModal";
import { Link, useParams } from "react-router-dom";
import { FaArrowCircleLeft } from "react-icons/fa";
import AddProductOrderModal from "./OrderModals/AddProductOrderModal";
import Swal from "sweetalert2";
import {
  deleteCartProduct,
  removeAllCartItems,
} from "../../../store/addProductSlice";
// import { fetchAsyncAllDelivery } from "../../../store/DeliveryZones/DeliveryZonesSlice";

const EditOrder = (props) => {
  const orderId = useParams().id;
  const dispatch = useDispatch();

  const [selectedDeliveryArea, setSelectedDeliveryArea] = useState(null);
  const [addProductModal, setAddProductModal] = useState(false);
  const [streetAddress, setStreetAddress] = useState("");
  const [productId, setProductId] = useState(null);
  const [orderItemsId, setOrderItemsId] = useState(null);
  const [country, setCountry] = useState("");
  const [show, setShow] = useState(false);
  const [city, setCity] = useState("");

  const handleModal = (productId, OrderItemsId) => {
    setShow(true);
    setProductId(productId);
    setOrderItemsId(OrderItemsId);
  };

  const handleAddProductModal = () => {
    setAddProductModal(true);
  };

  useEffect(() => {
    dispatch(fetchSingleOrder({ id: orderId }));
  }, [dispatch, orderId]);

  const order = useSelector((state) => state.Order.singleOrder);
  const orderStatus = useSelector((state) => state.Order.updateOrderStatus);
  const cartItems = useSelector((state) => state.ProductSlice.cartItems);

  const totalAmount = cartItems.reduce((total, item) => {
    const price =
      item.mainPrice === 0 ? item.productSize.price : item.mainPrice;

    return total + item.cartQuantity * price;
  }, 0);

  // useEffect(() => {
  //   dispatch(fetchAsyncAllDelivery());
  // }, [dispatch]);

  // const delivery = useSelector((state) => state.Delivery.allDelivery);

  // const deliveryOptions = delivery?.map((info) => ({
  //   value: info.id,
  //   label: `${info.areaName}`,
  //   deliveryFee: info.deliveryFee,
  // }));

  const handleSubmitProduct = async () => {
    Swal.fire({
      position: "top-end",
      title: "Wait a moment, please",
      showConfirmButton: false,
      icon: "info",
      timer: 2000,
      toast: true,
    });
    try {
      await dispatch(
        asyncAddOrderProduct({
          id: orderId,
          data: {
            products: cartItems.map((item) => ({
              id: item.id,
              size: item.productSize?.name,
              color: item.productColor?.name,
              quantity: item.cartQuantity,
            })),
            total: totalAmount,
            // total: cartItems.reduce(
            //   (acc, item) => acc + item.cartQuantity * item.productSize?.price,
            //   0
            // ),
          },
        })
      );
      await dispatch(removeAllCartItems());
      await dispatch(fetchSingleOrder({ id: orderId }));
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: error.message,
      });
    }
  };

  useEffect(() => {
    if (
      order &&
      Array.isArray(order.orderInformation) &&
      order.orderInformation.length > 0
    ) {
      setStreetAddress(order.orderInformation[0].streetAddress);
      setCity(order.orderInformation[0].city);
      setCountry(order.orderInformation[0].country);
      setSelectedDeliveryArea(order.orderInformation[0].deliveryZone);
    }
  }, [order, orderStatus]);

  const handleSubmit = async () => {
    const data = {
      streetAddress: streetAddress,
      city: city,
      country: country,
      deliveryZone: selectedDeliveryArea,
    };
    try {
      await dispatch(
        updateAsyncSingleOrderInfo({
          id: order?.orderInformation[0].id,
          data: data,
        })
      );
      Swal.fire({
        icon: "success",
        title: "Success",
        text: "Order updated successfully",
      });
      await dispatch(fetchSingleOrder({ id: orderId }));
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: error.message,
      });
    }
  };

  const handleDeleteProduct = (item) => {
    dispatch(deleteCartProduct(item));
  };

  console.log("cartItems", cartItems);

  return (
    <div className="container">
      <div className="row">
        <div className="col-xl-12">
          <div className="card mt-2">
            <div className="card-body">
              <h4>
                <Link to={`/order-page`}>
                  <FaArrowCircleLeft className="me-2 fs-3" />
                </Link>
                Remove Product
              </h4>
              <div className="overflow-auto">
                <table className="table">
                  <thead>
                    <tr>
                      <th>Product Name</th>
                      <th>Quantity</th>
                      <th>Price</th>
                      <th className="text-end">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {order?.orderItems?.map((product) => (
                      <tr key={product.id}>
                        <td>{product?.product?.name}</td>
                        <td className="">{product.quantity}</td>
                        <td>
                          {product.product?.sizes?.length > 0
                            ? product.product?.sizes[0]?.price
                            : product?.price}
                        </td>
                        <td className="text-end">
                          <button className="btn btn-sm btn-danger me-2">
                            Remove
                          </button>
                          <button
                            className="btn btn-sm btn-primary"
                            onClick={() =>
                              handleModal(product?.product?.id, product?.id)
                            }
                          >
                            Edit
                          </button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              <h4>Add Product</h4>
              <button
                onClick={handleAddProductModal}
                className="btn btn-primary mb-3"
              >
                Add Product
              </button>
              <div className="mb-3">
                {cartItems &&
                  cartItems.map((item) => (
                    <div
                      key={item.id}
                      className="border d-inline-block p-3 rounded-lg light mt-5 mx-2"
                    >
                      <h4 className="mt-3">{item?.name}</h4>
                      {item?.cartQuantity !== "" && (
                        <p className="mb-0">Quantity: {item?.cartQuantity}</p>
                      )}
                      {item?.productSize !== "" ? (
                        <p className="mb-0">
                          Price: {item?.productSize?.price}
                        </p>
                      ) : (
                        <p className="mb-0">Price: {item?.mainPrice}</p>
                      )}

                      {item?.productColor !== "" ? (
                        <span
                          style={{
                            width: "20px",
                            height: "20px",
                            borderRadius: "50%",
                            border: "1px solid #000",
                            display: "inline-block",
                            backgroundColor: item?.productColor?.colorCode,
                          }}
                        ></span>
                      ) : (
                        <div className="d-inline-flex align-items-center">
                          <span
                            style={{
                              width: "20px",
                              height: "20px",
                              borderRadius: "50%",
                              border: "1px solid #000",
                              display: "inline-block",
                              // backgroundColor: item?.productColor?.colorCode,
                            }}
                          ></span>
                          <span className="ms-2 text-info">
                            *Color is not available
                          </span>
                        </div>
                      )}

                      <div className="d-flex justify-content-between">
                        {item?.productColor !== "" ? (
                          <p className=" border px-2 rounded-4">
                            {item?.productSize?.name}
                          </p>
                        ) : (
                          <p className="text-info">*Size is not available</p>
                        )}
                        <button
                          type="button"
                          onClick={() => handleDeleteProduct(item)}
                          className="btn btn-outline-danger"
                        >
                          Remove
                        </button>
                      </div>
                    </div>
                  ))}
                {cartItems.length > 0 && (
                  <button
                    onClick={handleSubmitProduct}
                    className="btn btn-outline-primary btn-sm d-block mt-3 rounded-3"
                  >
                    Submit this product
                  </button>
                )}
              </div>
              <h4>Street Address</h4>
              <input
                type="text"
                className="form-control mb-3"
                placeholder="Street Address"
                value={streetAddress}
                onChange={(e) => setStreetAddress(e.target.value)}
              />
              <h4>City</h4>
              <input
                type="text"
                className="form-control mb-3"
                placeholder="City"
                value={city}
                onChange={(e) => setCity(e.target.value)}
              />
              <h4>Country</h4>
              <input
                type="text"
                className="form-control mb-3"
                placeholder="Country"
                value={country}
                onChange={(e) => setCountry(e.target.value)}
              />
              <h4>Delivery Area</h4>
              {/* <Select
                onChange={(e) => setSelectedDeliveryArea(e.target.value)}
                value={selectedDeliveryArea}
                options={deliveryOptions}
                style={{
                  lineHeight: "40px",
                  color: "#7e7e7e",
                  paddingLeft: " 15px",
                }}
              /> */}
              <input
                type="text"
                className="form-control mb-3"
                placeholder="Delivery Area"
                value={selectedDeliveryArea}
                onChange={(e) => setSelectedDeliveryArea(e.target.value)}
              />
              <div className="modal-footer mt-5">
                {orderStatus === "loading" ? (
                  <button className="btn btn-primary" type="button" disabled>
                    <span
                      className="spinner-border spinner-border-sm"
                      role="status"
                      aria-hidden="true"
                    ></span>
                    Loading...
                  </button>
                ) : (
                  <button
                    type="submit"
                    onClick={handleSubmit}
                    className="btn btn-primary"
                  >
                    Save
                  </button>
                )}
              </div>
            </div>
            <OrderProductModal
              show={show}
              onHide={() => setShow(false)}
              itemId={productId}
              status={orderStatus}
              orderId={orderId}
              orderItemsId={orderItemsId}
            />
            <AddProductOrderModal
              show={addProductModal}
              onHide={() => setAddProductModal(false)}
              itemId={productId}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditOrder;
