import React from "react";
import { SVGICON } from "../../../constant/theme";
import Revenue from "./Revenue";
import Summery from "./Summery";

const Home = () => {
  const iconBoxCard = [
    {
      title: "Total Customers this Month",
      number: 10,
      icon: SVGICON.DashHome,
    },
    { title: "Total Profit or Loss", number: 5200, icon: SVGICON.GroupCoin },
    {
      title: "Revenue of this Month",
      number: 200,
      icon: SVGICON.MessagePing,
    },
    { title: "Total Expenses", number: 150, icon: SVGICON.DoubleUser },
  ];

  return (
    <>
      <div className="container">
        <div className="row">
          {iconBoxCard?.map((item, ind) => (
            <div className="col-xl-3 col-sm-6" key={ind}>
              <div className="card">
                <div className="card-body d-flex justify-content-between">
                  <div className="card-menu">
                    <span>{item.title}</span>
                    <h2 className="mb-0">{item.number}</h2>
                  </div>
                  <div className="icon-box icon-box-lg bg-primary-light">
                    {item.icon}
                  </div>
                </div>
              </div>
            </div>
          ))}
          <div className="col-xl-12 custome-width">
            {/* <OrderSummary /> */}
            <Summery />
          </div>
          <div className="col-xl-12 custome-width">
            <Revenue />
          </div>
        </div>
      </div>
    </>
  );
};
export default Home;
