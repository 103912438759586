import React from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import {
  deleteAsyncSubCategory,
  fetchAsyncSingleCategory,
} from "../../../store/category/categorySlice";
import { useDispatch } from "react-redux";
import Swal from "sweetalert2";
import SubCategoryEditModal from "./SubCategoryEditModal";

const SubCategoryProduct = (props) => {
  const dispatch = useDispatch();
  const subCatId = useParams().id;
  const navigate = useNavigate();
  const [showEditModal, setShowEditModal] = React.useState(false);
  const [selectedItem, setSelectedItem] = React.useState(null);

  const {
    singleCategory,
    isCategoryPage,
    singleCatStatus,
  } = props;

  const handleEditSubCategory = (item) => {
    setSelectedItem(item);
    setShowEditModal(true);
  };

  const handleDeleteSubCategory = async (id) => {
    const confirmResult = await Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#3085d6",
      confirmButtonText: "Yes, delete it!",
      cancelButtonText: "No, cancel!",
    });

    // If user confirms deletion
    if (confirmResult.isConfirmed) {
      await dispatch(deleteAsyncSubCategory(id));
      await dispatch(fetchAsyncSingleCategory(subCatId));
      navigate(`/category/${subCatId}/products`);

      Swal.fire({
        title: "Deleted!",
        text: "Sub category has been deleted.",
        icon: "success",
        timer: 2000,
        showConfirmButton: false,
      });
    }
  };

  return (
    <div>
      {singleCategory?.subcollections?.length > 0 &&
        isCategoryPage === true && (
          <>
            <div className="d-flex justify-content-between flex-wrap">
              <div className="d-flex align-items-center justify-content-end">
                <h4>Sub Categories</h4>
              </div>
            </div>
            {singleCatStatus === "loading" ? (
              <div className="text-center">
                <p className="fw-bold fs-4">Loading, please wait...</p>
              </div>
            ) : (
              <div className="row">
                <div className="col-xl-12">
                  <div className="card mt-2">
                    <div className="card-body p-0">
                      <div className="table-responsive active-projects style-1 ItemsCheckboxSec shorting ">
                        <div
                          id="orderlist-table"
                          className="dataTables_wrapper no-footer"
                        >
                          <table
                            id="empoloyees-tbl"
                            className="table dataTable no-footer"
                          >
                            <thead>
                              <tr>
                                <th>name</th>
                                <th>Description</th>
                                <th className="text-end">Actions</th>
                              </tr>
                            </thead>
                            <tbody>
                              {singleCategory?.subcollections?.map((item) => {
                                return (
                                  <tr key={item.id}>
                                    <td>
                                      <Link
                                        to={`/sub-categories/${item.id}/products`}
                                      >
                                        <span>{item.name}</span>
                                      </Link>
                                    </td>
                                    <td>
                                      <span>{item.description}</span>
                                    </td>
                                    <td className="text-end">
                                      <button
                                        className="btn btn-outline-primary me-2"
                                        onClick={() =>
                                          handleEditSubCategory(item)
                                        }
                                      >
                                        Edit
                                      </button>
                                      <button
                                        className="btn btn-outline-danger"
                                        onClick={() => {
                                          handleDeleteSubCategory(item.id);
                                        }}
                                      >
                                        Delete
                                      </button>
                                    </td>
                                  </tr>
                                );
                              })}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </>
        )}
      {showEditModal && (
        <SubCategoryEditModal
          show={showEditModal}
          onHide={() => setShowEditModal(false)}
          item={selectedItem}
          navigateId={subCatId}
        />
      )}
    </div>
  );
};

export default SubCategoryProduct;
