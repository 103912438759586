import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Form, Spinner } from "react-bootstrap";
import Swal from "sweetalert2";
import {
  createAsyncTags,
  fetchAsyncAllTags,
  updateAsyncTag,
} from "../../../store/tags/tagsSlice";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { FaArrowCircleLeft } from "react-icons/fa";

const TagsForm = () => {
  const tagId = useParams().id;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [nameError, setNameError] = useState("");
  const [descriptionError, setDescriptionError] = useState("");
  const [formValid, setFormValid] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (tagId) {
      dispatch(fetchAsyncAllTags());
    }
  }, [dispatch, tagId]);

  const tagToEdit = useSelector((state) =>
    state.Tag.allTag.find((t) => t.id === tagId)
  );

  useEffect(() => {
    if (tagId && tagToEdit) {
      setDescription(tagToEdit?.description);
      setName(tagToEdit?.name);
    }
  }, [tagId, tagToEdit, tagToEdit?.name, tagToEdit?.description]);

  const validateName = () => {
    if (name.trim().length < 2) {
      setNameError("Name must be at least 3 characters long.");
      return false;
    } else {
      setNameError("");
      return true;
    }
  };

  const validateDescription = () => {
    if (description.trim().length < 3) {
      setDescriptionError("Description must be at least 3 characters long.");
      return false;
    } else {
      setDescriptionError("");
      return true;
    }
  };

  const handleNameChange = (e) => {
    setName(e.target.value);
    validateName();
  };

  const handleDescriptionChange = (e) => {
    setDescription(e.target.value);
    validateDescription();
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validateName() && validateDescription()) {
      try {
        setLoading(true);
        const formData = {
          name,
          description,
        };
        if (tagId) {
          await dispatch(updateAsyncTag({ id: tagId, data: formData }));
          Swal.fire({
            icon: "success",
            title: "Success!",
            text: "Collection updated successfully.",
          });
        } else {
          await dispatch(createAsyncTags(formData));
          Swal.fire({
            icon: "success",
            title: "Success!",
            text: "Tag created successfully.",
          });
        }
        navigate("/tags");
        setName("");
        setDescription("");
      } catch (error) {
        console.error("Error:", error);
        Swal.fire({
          icon: "error",
          title: "Error",
          text: "Failed to save collection. Please try again later.",
        });
      } finally {
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    if (name && description) {
      setFormValid(true);
    } else {
      setFormValid(false);
    }
  }, [name, description]);

  return (
    <>
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-12">
            <div className="card profile-card card-bx mb-30">
              <div className="card-header">
                <h6 className="title">
                  <Link to="/tags">
                    <FaArrowCircleLeft className="me-2" />
                  </Link>
                  Add Tags
                </h6>
              </div>

              <Form className="profile-form" onSubmit={handleSubmit}>
                <div className="card-body row">
                  <Form.Group className="mb-3 col-sm-6 m-b30" controlId="name">
                    <Form.Label>Tag Name</Form.Label>
                    <Form.Control
                      type="text"
                      autoComplete="off"
                      value={name}
                      onChange={handleNameChange}
                      isInvalid={nameError !== ""}
                    />
                    <Form.Control.Feedback type="invalid">
                      {nameError}
                    </Form.Control.Feedback>
                  </Form.Group>

                  <Form.Group
                    className="mb-3 col-sm-6 m-b30"
                    controlId="description"
                  >
                    <Form.Label>Description</Form.Label>
                    <Form.Control
                      type="text"
                      autoComplete="off"
                      value={description}
                      onChange={handleDescriptionChange}
                      isInvalid={descriptionError !== ""}
                    />
                    <Form.Control.Feedback type="invalid">
                      {descriptionError}
                    </Form.Control.Feedback>
                  </Form.Group>
                </div>
                <div className="card-footer">
                  <div></div>
                  <button
                    disabled={!formValid}
                    type="submit"
                    className="btn btn-primary"
                    onClick={() => {
                      Swal.fire({
                        position: "top-end",
                        title: "Wait a moment, please",
                        showConfirmButton: false,
                        icon: "info",
                        timer: 2000,
                        toast: true,
                      });
                    }}
                  >
                    {loading === true ? (
                      <div className="d-flex align-items-center justify-content-center ">
                        <span className="me-2">Initiated</span>
                        <Spinner animation="border" size="sm" />
                      </div>
                    ) : (
                      "Save"
                    )}
                  </button>
                  {/* <button type="button" className="btn btn-danger">
                    Delete
                  </button> */}
                </div>
              </Form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default TagsForm;
