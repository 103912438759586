import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import Swal from "sweetalert2";
import { fetchAsyncAllProduct } from "../../../../store/product/productSlice";
import { addToCart } from "../../../../store/addProductSlice";

const ProductItem = ({
  item,
  handleAddProduct,
  props,
  price,
  productColor,
  incrementQuantity,
  decrementQuantity,
  quantity,
  productSize,
  productColorHandler,
  productSizeHandler,
}) => (
  <div className="my-3 d-lg-flex gap-3" key={item.id}>
    <div>
      <img
        src={item?.images?.find((image) => image.isMain)?.url || ""}
        alt={item?.name}
        style={{
          width: "250px",
          height: "250px",
          objectFit: "cover",
          objectPosition: "center",
          marginRight: "10px",
          borderRadius: "5px",
        }}
      />
    </div>
    <div>
      <h4>{item?.name}</h4>
      <p className="mb-0">Title: {item?.title}</p>
      <p className="mb-0">
        {/* Price: $ {item?.sizes.length > 0 ? item?.sizes[0]?.price : price} */}
        Price: $ {price}
      </p>
      <div className="d-lg-flex">
        <div>
          {item?.colors.length > 0 && (
            <>
              <p className="mb-0 ">Colors:</p>
              {item?.colors?.map((i) => (
                <span
                  className="cursor-pointer me-1 ms-2"
                  key={i.id}
                  style={{
                    backgroundColor: i.colorCode,
                    width: "30px",
                    height: "30px",
                    border:
                      productColor?.id === i.id
                        ? "2px solid #000"
                        : "1px solid gray",
                    borderRadius: "50%",
                    display: "inline-block",
                  }}
                  onClick={() => productColorHandler(item.id, i)}
                ></span>
              ))}
            </>
          )}
        </div>
        <div className="mb-2">
          <div className="">
            <span
              className="btn btn-outline-primary btn-sm"
              onClick={decrementQuantity}
            >
              -
            </span>
            <input
              type="number"
              className="quantity-input"
              value={quantity}
              readOnly
            />
            <span
              className="btn btn-outline-primary btn-sm"
              onClick={incrementQuantity}
            >
              +
            </span>
          </div>
        </div>
      </div>
      {item.sizes?.length > 0 ? (
        <>
          <p className="mb-1 ">Size:</p>
          {item?.sizes
            ?.slice()
            .sort((a, b) => a.price - b.price)
            .map((i) => (
              <p
                className="cursor-pointer d-inline-block mx-2 mb-1 px-2 rounded-1 py-1 border "
                key={i.id}
                style={{
                  backgroundColor: productSize?.id === i.id ? "#d3d3d3" : "",
                }}
                onClick={() => productSizeHandler(item.id, i)}
              >
                {i.name}
              </p>
            ))}
        </>
      ) : (
        ""
      )}
      <div>
        <button
          className="btn btn-primary mt-2"
          onClick={() => {
            handleAddProduct(item.id);

            // Swal.fire({
            //   position: "top-end",
            //   title: "Wait a moment, please",
            //   showConfirmButton: false,
            //   icon: "info",
            //   timer: 2000,
            //   toast: true,
            // });
            // props.onHide();
          }}
        >
          Add
        </button>
      </div>
    </div>
  </div>
);

const AddProductOrderModal = (props) => {
  const dispatch = useDispatch();
  const [sizes, setSizes] = useState({});
  const [colors, setColors] = useState({});
  const [quantities, setQuantities] = useState({});
  const [price, setPrice] = useState(0);
  const [search, setSearch] = useState("");

  useEffect(() => {
    dispatch(fetchAsyncAllProduct());
  }, [dispatch]);

  const products = useSelector((state) => state.Product.allProduct);

  const filteredProducts = products.filter((item) => {
    const searchTerm = search.toLowerCase();
    return (
      item.name.toLowerCase().includes(searchTerm) ||
      item.title.toLowerCase().includes(searchTerm)
    );
  });

  const decrementQuantity = (id) => {
    setQuantities((prevQuantities) => ({
      ...prevQuantities,
      [id]: Math.max((prevQuantities[id] || 1) - 1, 0),
    }));
  };

  const incrementQuantity = (id) => {
    setQuantities((prevQuantities) => ({
      ...prevQuantities,
      [id]: (prevQuantities[id] || 1) + 1,
    }));
  };

  // const productSizeHandler = (id, size) => {
  //   if (size) {
  //     setSizes((prevSizes) => ({
  //       ...prevSizes,
  //       [id]: size,
  //     }));
  //     setPrice(size.price);
  //   } else {
  //     setPrice(products.price);
  //   }

  //   // setSizes((prevSizes) => ({
  //   //   ...prevSizes,
  //   //   [id]: size,
  //   // }));
  //   // setPrice(size.price);
  // };

  const productSizeHandler = (productId, size) => {
    if (size) {
      setSizes((prevSizes) => ({
        ...prevSizes,
        [productId]: size,
      }));
      setPrice((prevPrices) => ({
        ...prevPrices,
        [productId]: size.price,
      }));
    }
  };

  const productColorHandler = (id, color) => {
    setColors((prevColors) => ({
      ...prevColors,
      [id]: color,
    }));
  };

  const handleAddProduct = (id) => {
    const product = filteredProducts.find((item) => item.id === id);

    // Validation checks
    if (product?.colors?.length > 0 && !colors[id]) {
      Swal.fire({
        position: "top-end",
        title: "Please select a color",
        showConfirmButton: false,
        icon: "warning",
        timer: 1500,
        toast: true,
      });
      return;
    }

    if (product?.sizes?.length > 0 && !sizes[id]) {
      Swal.fire({
        position: "top-end",
        title: "Please select a size",
        showConfirmButton: false,
        icon: "warning",
        timer: 1500,
        toast: true,
      });
      return;
    }

    Swal.fire({
      position: "top-end",
      title: "Wait a moment, please",
      showConfirmButton: false,
      icon: "info",
      timer: 2000,
      toast: true,
    });

    dispatch(
      addToCart({
        product,
        productSize: sizes[id],
        productColor: colors[id],
        cartQuantity: quantities[id] || 1,
      })
    );

    Swal.fire({
      position: "top-end",
      title: "Product added successfully",
      showConfirmButton: false,
      icon: "success",
      timer: 1500,
      toast: true,
    });
    setQuantities({});
  };

  return (
    <div>
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        scrollable
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Add Product to Order
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="input-group mb-3">
            <input
              type="text"
              className="form-control"
              placeholder="Search"
              aria-label="Search"
              aria-describedby="button-addon2"
              value={search}
              onChange={(e) => setSearch(e.target.value)}
            />
            <button
              className="btn btn-outline-primary"
              type="button"
              id="button-addon2"
            >
              <i className="fa fa-search"></i>
            </button>
          </div>
          {filteredProducts?.map((item) => {
            // console.log("item", item);
            const priceToDisplay =
              item.sizes && item.sizes.length > 0
                ? price[item.id] || item.sizes[0].price
                : item.price;
            return (
              <ProductItem
                key={item.id}
                item={item}
                decrementQuantity={() => decrementQuantity(item.id)}
                incrementQuantity={() => incrementQuantity(item.id)}
                quantity={quantities[item.id] || 1}
                productSize={sizes[item.id]}
                price={priceToDisplay}
                // price={price}
                productColor={colors[item.id]}
                productColorHandler={productColorHandler}
                productSizeHandler={productSizeHandler}
                handleAddProduct={handleAddProduct}
                props={props}
              />
            );
          })}
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default AddProductOrderModal;
