import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  deleteMessage,
  getMessageRead,
  getMessageUnread,
  getSingleMessage,
  markAsRead,
} from "./InboxAPI";

// Fetch All inbox
export const fetchAsyncInbox = createAsyncThunk(
  "inbox/fetchInbox",
  async () => {
    const inbox = await getMessageRead();
    return inbox;
  }
);

// Fetch Unread inbox
export const fetchAsyncUnread = createAsyncThunk(
  "inbox/fetchUnread",
  async () => {
    const inbox = await getMessageUnread();
    return inbox;
  }
);

// fetch single inbox
export const fetchAsyncSingleInbox = createAsyncThunk(
  "inbox/fetchSingleInbox",
  async (id) => {
    const inbox = await getSingleMessage(id);
    return inbox;
  }
);

// Delete inbox
export const deleteAsyncInbox = createAsyncThunk(
  "inbox/deleteInbox",
  async (id) => {
    const inbox = await deleteMessage(id);
    return inbox;
  }
);

// Mark as read
export const AsyncMarkAsRead = createAsyncThunk(
  "inbox/markAsRead",
  async (id) => {
    const inbox = await markAsRead(id);
    return inbox;
  }
);


const initialState = {
  allInbox: [],
  singleInbox: [],
  unReadInbox: [],
  status: "idle",
  error: null,
};

// inbox slice
const inboxSlice = createSlice({
  name: "inbox",
  initialState,
  extraReducers: (builder) => {
    builder
      // Handle fetchAsyncInbox
      .addCase(fetchAsyncInbox.pending, (state) => {
        state.status = "loading";
        state.error = null;
      })
      .addCase(fetchAsyncInbox.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.allInbox = action.payload;
      })
      .addCase(fetchAsyncInbox.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      // Handle fetchAsyncUnread
      .addCase(fetchAsyncUnread.pending, (state) => {
        state.status = "loading";
        state.error = null;
      })
      .addCase(fetchAsyncUnread.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.unReadInbox = action.payload;
      })
      .addCase(fetchAsyncUnread.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      // Handle fetchAsyncSingleInbox
      .addCase(fetchAsyncSingleInbox.pending, (state) => {
        state.status = "loading";
        state.error = null;
      })
      .addCase(fetchAsyncSingleInbox.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.singleInbox = action.payload;
      })
      .addCase(fetchAsyncSingleInbox.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      // Handle deleteAsyncInbox
      .addCase(deleteAsyncInbox.pending, (state) => {
        state.status = "loading";
        state.error = null;
      })
      .addCase(deleteAsyncInbox.fulfilled, (state, action) => {
        state.status = "succeeded";
      })
      .addCase(deleteAsyncInbox.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      });
  },
});

export default inboxSlice.reducer;
