import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  getShopDetails,
  getSocialLinks,
  postShopDetails,
  postSocialLinks,
} from "./ShopDetailsAPI";

export const fetchShopDetails = createAsyncThunk(
  "shopDetails/fetchShopDetails",
  async () => {
    const collections = await getShopDetails();
    return collections;
  }
);

// Async thunk for creating a category
export const createShopDetails = createAsyncThunk(
  "shopDetails/createShopDetails",
  async (data) => {
    const response = await postShopDetails(data);
    return response;
  }
);

// create social links
export const createSocialLinks = createAsyncThunk(
  "shopDetails/createSocialLinks",
  async (data) => {
    const response = await postSocialLinks(data);
    return response;
  }
);

// Get social links
export const fetchSocialLinks = createAsyncThunk(
  "shopDetails/fetchSocialLinks",
  async () => {
    const collections = await getSocialLinks();
    return collections;
  }
);

const initialState = {
  socialLinks: {},
  shopDetails: {},
  loading: "idle",
  error: null,
};

const ShopDetailsSlice = createSlice({
  name: "ShopDetails",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchShopDetails.pending, (state) => {
        state.loading = "loading";
        state.error = null;
      })
      .addCase(fetchShopDetails.fulfilled, (state, action) => {
        state.loading = "succeeded";
        state.shopDetails = action.payload;
      })
      .addCase(fetchShopDetails.rejected, (state, action) => {
        state.loading = "failed";
        state.error = action.error.message;
      })
      .addCase(createShopDetails.pending, (state) => {
        state.loading = "loading";
        state.error = null;
      })
      .addCase(createShopDetails.fulfilled, (state, action) => {
        state.loading = "succeeded";
        state.shopDetails = action.payload;
      })
      .addCase(createShopDetails.rejected, (state, action) => {
        state.loading = "failed";
        state.error = action.error.message;
      })
      .addCase(fetchSocialLinks.pending, (state) => {
        state.loading = "loading";
        state.error = null;
      })
      .addCase(fetchSocialLinks.fulfilled, (state, action) => {
        state.loading = "succeeded";
        state.socialLinks = action.payload;
      })
      .addCase(fetchSocialLinks.rejected, (state, action) => {
        state.loading = "failed";
        state.error = action.error.message;
      })
      .addCase(createSocialLinks.pending, (state) => {
        state.loading = "loading";
        state.error = null;
      })
      .addCase(createSocialLinks.fulfilled, (state, action) => {
        state.loading = "succeeded";
        state.socialLinks = action.payload;
      })
      .addCase(createSocialLinks.rejected, (state, action) => {
        state.loading = "failed";
        state.error = action.error.message;
      });
  },
});

export default ShopDetailsSlice.reducer;
