import { useState } from "react";
import { useNavigate } from "react-router-dom";
import login from "../../images/login.jpg";
import { LoginAction } from "../../store/actions/singInSignOutSlice";
import { useDispatch } from "react-redux";
import Swal from "sweetalert2";

function Login(props) {
  const navigate = useNavigate();
  let errorsObj = { email: "", password: "" };
  const [errors, setErrors] = useState(errorsObj);
  // username: "abss@ecommerceTest.com"
  // password: "Abss123!"
  
  const [username, setUserName] = useState("");
  const [password, setPassword] = useState("");
  const dispatch = useDispatch();

  async function onLogin(e) {
    e.preventDefault();
    let error = false;
    const errorObj = { ...errorsObj };
    if (username === "") {
      errorObj.username = "Email is Required";
      error = true;
    }
    if (password === "") {
      errorObj.password = "Password is Required";
      error = true;
    }
    setErrors(errorObj);
    if (error) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Something went wrong!",
      });
      return;
    }
    await localStorage.clear();
    dispatch(LoginAction({ username, password, navigate }));
  }

  return (
    <div className="page-wraper">
      <div className="authincation ">
        <div className="container ">
          <div className="row justify-content-center h-100 align-items-center">
            <div className="col-md-12 h-100 d-flex align-items-center">
              <div className="authincation-content style-1">
                <div className="row h-100">
                  <div className="col-md-6 h-100">
                    <div className="img-bx">
                      <img src={login} alt="" className="img-fluid" />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="auth-form">
                      <h4 className="main-title">Sign in</h4>
                      {props.errorMessage && (
                        <div className="bg-red-300 text-red-900 border border-red-900 p-1 my-2">
                          {props.errorMessage}
                        </div>
                      )}
                      {props.successMessage && (
                        <div className="bg-green-300 text-green-900 border border-green-900 p-1 my-2">
                          {props.successMessage}
                        </div>
                      )}
                      <form onSubmit={onLogin}>
                        <div className="form-group mb-3 pb-3">
                          <label className="font-w600">Email</label>
                          <input
                            type="email"
                            className="form-control solid"
                            value={username}
                            onChange={(e) => setUserName(e.target.value)}
                          />
                          {errors.email && (
                            <div className="text-danger fs-12">
                              {errors.email}
                            </div>
                          )}
                        </div>
                        <div className="form-group mb-3 pb-3">
                          <label className="font-w600">Password</label>
                          <input
                            type="password"
                            className="form-control solid"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                          />
                          {errors.password && (
                            <div className="text-danger fs-12">
                              {errors.password}
                            </div>
                          )}
                        </div>
                        <div className="text-center">
                          <button
                            type="submit"
                            className="btn btn-primary btn-block rounded"
                          >
                            Sign Me In
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Login;
