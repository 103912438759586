import React, { useState } from "react";
import { Card, Form } from "react-bootstrap";
import Loading from "../../components/Loading";
import Swal from "sweetalert2";
import GeneralSocial from "./GeneralSocial";
import ProfileSettings from "./ProfileSettings";
import DeliveryZones from "./DeliveryZones/DeliveryZones";

const General = () => {
  const [businessName, setBusinessName] = useState("");
  const [loading, setLoading] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  const status = "succeeded";

  const submitName = async () => {
    try {
      setLoading(true);
      Swal.fire({
        icon: "success",
        title: "Success!",
        text: "Your info has been updated successfully.",
      });
    } catch (error) {
      console.error("Error updating info:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleSwitchChange = async () => {
    try {
      const willProceed = await Swal.fire({
        title: "Are you sure?",
        text: "Once submitted, you will not be able to change this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, change it!",
      });
      if (willProceed.isConfirmed) {
        const newIsOpen = !isOpen;
        setIsOpen(newIsOpen);
        await Swal.fire("Changed!", "Status has been updated.", "success");
      }
    } catch (error) {
      console.error("Error updating info:", error);
      await Swal.fire("Oh noes!", "The AJAX request failed!", "error");
    }
  };

  return (
    <>
      <Card className="m-3">
        <Card.Header as="h5">General setting</Card.Header>
        {status === "loading" ? (
          <Loading status={status} />
        ) : (
          <Card.Body>
            <div className="row">
              <div className="col-md-3">
                <h5>Order Confirmation</h5>
              </div>
              <div className="col-md-9">
                <Form.Check
                  type="switch"
                  className="mt-3"
                  id="custom-switch"
                  label={isOpen ? "Open" : "Closed"}
                  checked={isOpen}
                  onChange={handleSwitchChange}
                />
                <h5>Business Name</h5>
                <input
                  value={businessName}
                  onChange={(e) => setBusinessName(e.target.value)}
                  type="text"
                  className="form-control mb-2"
                />
                <button
                  onClick={() => {
                    submitName();
                  }}
                  className="btn btn-outline-primary rounded-5"
                >
                  Save
                </button>
              </div>
            </div>
            <Card.Footer className="mt-3" />

            <div className="row">
              <div className="col-md-3">
                <h5>Sent to Delivery</h5>
              </div>
              <div className="col-md-9">
                <Form.Check
                  type="switch"
                  className="mt-3"
                  id="custom-switch"
                  label={isOpen ? "Open" : "Closed"}
                  checked={isOpen}
                  onChange={handleSwitchChange}
                />
                <h5>Business Name</h5>
                <input
                  value={businessName}
                  onChange={(e) => setBusinessName(e.target.value)}
                  type="text"
                  className="form-control mb-2"
                />
                <button
                  onClick={() => {
                    submitName();
                  }}
                  className="btn btn-outline-primary rounded-5"
                >
                  Save
                </button>
              </div>
            </div>
            <Card.Footer className="mt-3" />

            <div className="row">
              <div className="col-md-3">
                <h5>Order Completed</h5>
              </div>
              <div className="col-md-9">
                <Form.Check
                  type="switch"
                  className="mt-3"
                  id="custom-switch"
                  label={isOpen ? "Open" : "Closed"}
                  checked={isOpen}
                  onChange={handleSwitchChange}
                />
                <h5>Business Name</h5>
                <input
                  value={businessName}
                  onChange={(e) => setBusinessName(e.target.value)}
                  type="text"
                  className="form-control mb-2"
                />
                <button
                  onClick={() => {
                    submitName();
                  }}
                  className="btn btn-outline-primary rounded-5"
                >
                  Save
                </button>
              </div>
            </div>
            <Card.Footer className="mt-3" />
            <div className="row">
              <div className="col-md-3">
                <h5>After Completion</h5>
              </div>
              <div className="col-md-9">
                <Form.Check
                  type="switch"
                  className="mt-3"
                  id="custom-switch"
                  label={isOpen ? "Open" : "Closed"}
                  checked={isOpen}
                  onChange={handleSwitchChange}
                />
                <h5>Business Name</h5>
                <input
                  value={businessName}
                  onChange={(e) => setBusinessName(e.target.value)}
                  type="text"
                  className="form-control mb-2"
                />
                <button
                  onClick={() => {
                    submitName();
                  }}
                  className="btn btn-outline-primary rounded-5"
                >
                  Save
                </button>
              </div>
            </div>
            <Card.Footer className="mt-3" />
            <DeliveryZones />
            <Card.Footer className="mt-3" />
            <ProfileSettings />
            <Card.Footer className="mt-3" />
            <GeneralSocial />
          </Card.Body>
        )}
      </Card>
    </>
  );
};

export default General;
