import { get, patch, post } from "aws-amplify/api";
import { amplifyConfigs } from "../../config";
import Swal from "sweetalert2";

// Add Order
export async function addOrder({data}) {
  try {
    const restOperation = post({
      apiName: amplifyConfigs.apiName,
      path: "/orders",
      options: {
        body: data,
      },
    });
    const response = await restOperation.response;
    // Swal.fire({
    //   icon: "success",
    //   title: "Success",
    //   text: "Order added successfully",
    // });
    return response;
  } catch (error) {
    Swal.fire({
      icon: "error",
      title: "Oops...",
      text: error.message,
    });
  }
}

// get orders list
export async function getOrders() {
  try {
    const restOperation = get({
      apiName: amplifyConfigs.apiName,
      path: "/orders",
    });
    const response = await restOperation.response;
    return response.body.json();
  } catch (error) {
    
  }
}

// Get Single order
export async function getSingleOrder({id}) {
  try {
    const restOperation = get({
      apiName: amplifyConfigs.apiName,
      path: `/orders/${id}`,
    });
    const response = await restOperation.response;
    return response.body.json();
  } catch (error) {
    
  }
}

// Update Order
export async function updateOrder({id, data}) {
  try {
    const restOperation = patch({
      apiName: amplifyConfigs.apiName,
      path: `/orders/${id}`,
      options: {
        body: data,
      },
    });
    const response = await restOperation.response;
    Swal.fire({
      icon: "success",
      title: "Success",
      text: "Product updated successfully",
    });
    return response;
  } catch (error) {
    
    Swal.fire({
      icon: "error",
      title: "Oops...",
      text: error.message,
    });
  }
}


// Edit order information
export async function editOrderInformation({id, data}) {
  try {
    const restOperation = patch({
      apiName: amplifyConfigs.apiName,
      path: `/orders/${id}/updateOrderInfo`,
      options: {
        body: data,
      },
    });
    const response = await restOperation.response;
    Swal.fire({
      icon: "success",
      title: "Success",
      text: "Order updated successfully",
    });
    return response.body.json();
  } catch (error) {
    Swal.fire({
      icon: "error",
      title: "Oops...",
      text: error.message,
    });
  }
}

// order Product edit 
export async function editOrderProduct({id, data}) {
  try {
    const restOperation = patch({
      apiName: amplifyConfigs.apiName,
      path: `/orders/${id}/updateOrderItem`,
      options: {
        body: data,
      },
    });
    const response = await restOperation.response;
    Swal.fire({
      icon: "success",
      title: "Success",
      text: "Product updated successfully",
    });
    return response.body.json();
  } catch (error) {
    Swal.fire({
      icon: "error",
      title: "Oops...",
      text: error.message,
    });
  }
}

// Add order product
export async function addOrderProduct({id, data}) {
  try {
    const restOperation = post({
      apiName: amplifyConfigs.apiName,
      path: `/orders/${id}/addProducts`,
      options: {
        body: data,
      },
    });
    const response = await restOperation.response;
    Swal.fire({
      icon: "success",
      title: "Success",
      text: "Product added successfully",
    });
    return response.body.json();
  } catch (error) {
    Swal.fire({
      icon: "error",
      title: "Oops...",
      text: error.message,
    });
  }
}