import { del, get, patch, post } from "aws-amplify/api";
import { amplifyConfigs } from "../../config";
import Swal from "sweetalert2";

export async function getCoupon() {
  try {
    const restOperation = get({
      apiName: amplifyConfigs.apiName,
      path: "/coupons",
    });
    const response = await restOperation.response;
    return response.body.json();
  } catch (error) {
    
  }
}

export async function postCoupon(data) {
  try {
    const restOperation = post({
      apiName: amplifyConfigs.apiName,
      path: "/coupons",
      options: {
        body: data,
      },
    });
    const response = await restOperation.response;
    Swal.fire({
      title: "Coupon Created",
      text: "Coupon has been created successfully",
      icon: "success",
      confirmButtonText: "Ok",
    });
    return response;
  } catch (error) {
    
  }
}

// Update a coupon
export async function updateCoupon({ couponId, data }) {
  try {
    const restOperation = patch({
      apiName: amplifyConfigs.apiName,
      path: `/coupons/${couponId}`,
      options: {
        body: data,
      },
    });
    const response = await restOperation.response;
    Swal.fire({
      title: "Coupon Updated",
      text: "Coupon has been updated successfully",
      icon: "success",
      confirmButtonText: "Ok",
    });
    return response;
  } catch (error) {
    
  }
}

// Delete a category
export async function deleteCoupon(couponId) {
  try {
    const restOperation = del({
      apiName: amplifyConfigs.apiName,
      path: `/coupons/${couponId}`,
    });
    const response = await restOperation.response;
    Swal.fire({
      title: "Coupon Deleted",
      text: "Coupon has been deleted successfully",
      icon: "success",
      confirmButtonText: "Ok",
    });
    return response;
  } catch (error) {
    
  }
}
