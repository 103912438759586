import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getCustomerWithAccount, getCustomerWithoutAccount, getCustomers } from "./CustomerAPI";


export const fetchAsyncAllCustomer = createAsyncThunk(
  "customer/fetchCustomer",
  async () => {
    const customer = await getCustomers();
    return customer;
  }
);

export const fetchAsyncCustomerWithAccount = createAsyncThunk(
  "customer/fetchCustomerWithAccount",
  async () => {
    const customer = await getCustomerWithAccount();
    return customer;
  }
);

export const fetchAsyncCustomerWithoutAccount = createAsyncThunk(
  "customer/fetchCustomerWithoutAccount",
  async () => {
    const customer = await getCustomerWithoutAccount();
    return customer;
  }
);



const initialState = {
  customers: [],
  customerWithAccount: [],
  customerWithoutAccount: [],
  isLoading: "idle",
  error: null,
};


export const CustomerSlice = createSlice({
  name: "customer",
  initialState,
  reducers: {
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAsyncAllCustomer.pending, (state) => {
        state.isLoading = "loading";
        state.error = null;
      })
      .addCase(fetchAsyncAllCustomer.fulfilled, (state, action) => {
        state.isLoading = "succeeded";
        state.customers = action.payload;
      })
      .addCase(fetchAsyncAllCustomer.rejected, (state, action) => {
        state.isLoading = "failed";
        state.error = action.error.message;
      })
      .addCase(fetchAsyncCustomerWithAccount.pending, (state) => {
        state.isLoading = "loading";
        state.error = null;
      })
      .addCase(fetchAsyncCustomerWithAccount.fulfilled, (state, action) => {
        state.isLoading = "succeeded";
        state.customerWithAccount = action.payload;
      })
      .addCase(fetchAsyncCustomerWithAccount.rejected, (state, action) => {
        state.isLoading = "failed";
        state.error = action.error.message;
      })
      .addCase(fetchAsyncCustomerWithoutAccount.pending, (state) => {
        state.isLoading = "loading";
        state.error = null;
      })
      .addCase(fetchAsyncCustomerWithoutAccount.fulfilled, (state, action) => {
        state.isLoading = "succeeded";
        state.customerWithoutAccount = action.payload;
      })
      .addCase(fetchAsyncCustomerWithoutAccount.rejected, (state, action) => {
        state.isLoading = "failed";
        state.error = action.error.message;
      });
  }
});

export default CustomerSlice.reducer;