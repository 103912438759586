import { del, get, patch, post } from "aws-amplify/api";
import { amplifyConfigs } from "../../config";

export async function getBrands() {
  try {
    const restOperation = get({
      apiName: amplifyConfigs.apiName,
      path: "/brands",
    });
    const response = await restOperation.response;
    return response.body.json();
  } catch (error) {
    
  }
}

export async function postBrands(data) {
  
  try {
    const restOperation = post({
      apiName: amplifyConfigs.apiName,
      path: "/brands",
      options: {
        body: data,
      },
    });
    await restOperation.response;
  } catch (error) {
    
  }
}


// Update a brand
export async function updateBrand({ id, data }) {
  try {
    const restOperation = patch({
      apiName: amplifyConfigs.apiName,
      path: `/brands/${id}`,
      options: {
        body: data,
      },
    });
    await restOperation.response;
  } catch (error) {
    
  }
}

// Delete a brand
export async function deleteBrand(id) {
  try {
    const restOperation = del({
      apiName: amplifyConfigs.apiName,
      path: `/brands/${id}`,
    });
    await restOperation.response;
  } catch (error) {
    
  }
}

