import React, { useState } from "react";
import { Form, Table } from "react-bootstrap";
import { useParams } from "react-router-dom";
import AdminModal from "./AdminModal";
import UserModal from "./UserModal";

const listOfShop = [
  {
    id: 1,
    name: "Blossom Boutique",
    address: "123 Rose Street, Beirut, Lebanon",
    phone: "1234567890",
    email: "blossom@gmail.com",
    logo: "/logo.webp",
  },
  {
    id: 2,
    name: "Cedar Crafts",
    address: "456 Cedar Avenue, Tripoli, Lebanon",
    phone: "1234567890",
    email: "cedarcrafts@gmail.com",
    logo: "/logo.webp",
  },
  {
    id: 3,
    name: "Lebanese Loom",
    address: "789 Silk Road, Sidon, Lebanon",
    phone: "1234567890",
    email: "lebaneseloom@gmail.com",
    logo: "/logo.webp",
  },
];

const adminName = [
  {
    id: 1,
    name: "Admin Name 1",
  },
  {
    id: 2,
    name: "Admin Name 2",
  },
];

const userName = [
  {
    id: 1,
    name: "User Name 1",
  },
  {
    id: 2,
    name: "User Name 2",
  },
];

const ShopDetails = () => {
  const id = useParams().id;

  const shop = listOfShop.find((shop) => shop.id === parseInt(id));

  const [adminShow, adminSetShow] = useState(false);
  const handleAdminClose = () => adminSetShow(false);
  const handleAdminShow = () => adminSetShow(true);

  const [userShow, userSetShow] = useState(false);
  const handleUserClose = () => userSetShow(false);
  const handleUserShow = () => userSetShow(true);

  const [isOpen, setIsOpen] = useState(false);

  const handleSwitchChange = () => {
    setIsOpen(!isOpen);
  }


  return (
    <div>
      <div className="container">
        <div className="row">
          <div className="col-xl-12">
            <div>
              <div className="card border border-2 mt-2 shadow-none">
                <div className="card-body">
                  <div className="row align-items-center">
                    <div className=" col-md-4">
                      <h4>
                        <span className="fw-semibold">Name: </span>
                        {shop.name}
                      </h4>
                      <p className="mb-0">
                        <span className="fw-semibold">Phone: </span>
                        {shop.phone}
                      </p>
                      <p className="mb-0">
                        <span className="fw-semibold">Email: </span>
                        {shop.email}
                      </p>
                      <p className="mb-0">
                        <span className="fw-semibold">Address: </span>
                        {shop.address}
                      </p>
                    </div>
                    <div className="col-md-4 d-none d-md-block text-center">
                      <img
                        src={shop.logo}
                        alt="logo"
                        className="img-fluid rounded w-50"
                      />
                    </div>
                    <div className=" col-md-4 text-end">
                      <button className="btn btn-primary me-3">
                        Edit
                      </button>
                      <button className="btn btn-danger">Delete</button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="card border border-2 mt-2 shadow-none">
                <div className="card-body">
                  <p>
                    <span className="fw-bold">Current SMS Balance: </span>32
                  </p>
                  <div className="">
                    <input type="text" className="form-control w-50 mb-2" />
                    <button className="btn btn-outline-primary">
                      Recharge SMS
                    </button>
                  </div>
                </div>
              </div>
              <div className="card border border-2 mt-2 shadow-none">
                <div className="card-body">
                  <div className="container">
                    <div className="row">
                      <div className="col-md-6">
                        <div className="d-flex justify-content-between align-items-center pb-3">
                          <h3>Admin</h3>
                          <button
                            onClick={() => adminSetShow(true)}
                            className="btn btn-primary"
                          >
                            Add New Admin
                          </button>
                        </div>
                        <AdminModal
                          show={adminShow}
                          onHide={handleAdminClose}
                        />
                        {adminName.map((admin) => (
                          <div className="border rounded mb-1 w-100 d-flex justify-content-between align-items-center p-2">
                            <p className="mb-0">{admin.name}</p>
                            <div>
                              <button className="btn btn-outline-primary me-2">
                                Edit
                              </button>
                              <button className="btn btn-outline-danger">
                                Delete
                              </button>
                            </div>
                          </div>
                        ))}
                      </div>
                      <div className="col-md-6 mt-lg-0 mt-4">
                        <div className="d-flex justify-content-between align-items-center pb-3">
                          <h3>User</h3>
                          <button
                            onClick={() => userSetShow(true)}
                            className="btn btn-primary"
                          >
                            Add New User
                          </button>
                          <UserModal show={userShow} onHide={handleUserClose} />
                        </div>
                        {userName.map((user) => (
                          <div className="border rounded mb-1 w-100 d-flex justify-content-between align-items-center p-2">
                            <p className="mb-0">{user.name}</p>
                            <div>
                              <button className="btn btn-outline-primary me-2">
                                Edit
                              </button>
                              <button className="btn btn-outline-danger">
                                Delete
                              </button>
                            </div>
                          </div>
                        ))}
                      </div>
                      <Form.Check
                        type="switch"
                        className="mt-3 ms-3"
                        id="custom-switch"
                        label={
                          isOpen ? "Active Shop" : "Deactivate Shop"
                        }
                        checked={isOpen}
                        onChange={handleSwitchChange}

                      />
                    </div>
                  </div>
                </div>
              </div>
              {/* <div className="card border border-2 mt-2 shadow-none">
                <div className="card-body"></div>
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ShopDetails;
