import { configureStore } from "@reduxjs/toolkit";
import singInSignOutSlice from "./actions/singInSignOutSlice";
import collectionSlice from "./collections/collectionSlice";
import brandsSlice from "./brands/brandsSlice";
import categorySlice from "./category/categorySlice";
import userInfoSlice from "./userInfoSlice";
import tagsSlice from "./tags/tagsSlice";
import productSlice from "./product/productSlice";
import ShopDetailsSlice from "./shopDetails/ShopDetailsSlice";
import couponSlice from "./coupon/couponSlice";
import orderSlice from "./order/orderSlice";
import DeliveryZonesSlice from "./DeliveryZones/DeliveryZonesSlice";
import InboxSlice from "./Message/InboxSlice";
import OutBoxSlice from "./Message/OutBoxSlice";
import CustomerSlice from "./Customer/CustomerSlice";
import customProductSlice from "./addProductSlice";

export const store = configureStore({
  reducer: {
    singInSignUp: singInSignOutSlice,
    Brand: brandsSlice,
    Coupon: couponSlice,
    Collection: collectionSlice,
    Category: categorySlice,
    Order: orderSlice,
    Role: userInfoSlice,
    Tag: tagsSlice,
    Product: productSlice,
    ShopDetails: ShopDetailsSlice,
    Delivery: DeliveryZonesSlice,
    Inbox: InboxSlice,
    Outbox: OutBoxSlice,
    Customer: CustomerSlice,
    ProductSlice: customProductSlice,
  },
});
