import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { deleteBrand, getBrands, postBrands, updateBrand } from "./brandsAPI";

  // Fetch All Brands
export const fetchAsyncAllBrands = createAsyncThunk(
  "brands/fetchBrands",
  async () => {
    const products = await getBrands();
    return products;
  }
);

  // Create Brand
export const createAsyncBrands = createAsyncThunk(
  "brands/createBrands",
  async (data) => {
    const response = await postBrands(data);
    return response;
  }
);

// Async thunk for updating a Brand
export const updateAsyncBrand = createAsyncThunk(
  "brand/updateBrand",
  async (data) => {
    const response = await updateBrand(data);
    return response;
  }
);

// Async thunk for deleting a brand
export const deleteAsyncBrand = createAsyncThunk(
  "brand/deleteBrand",
  async (id) => {
    const response = await deleteBrand(id);
    return response;
  }
);

const initialState = {
  allBrand: [],
  status: "idle",
  error: null,
};

// brand slice
const brandSlice = createSlice({
  name: "categories",
  initialState,
  extraReducers: (builder) => {
    builder
      // Handle fetchAsyncAllCategory
      .addCase(fetchAsyncAllBrands.pending, (state) => {
        state.status = "loading";
        state.error = null;
      })
      .addCase(fetchAsyncAllBrands.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.allBrand = action.payload;
      })
      .addCase(fetchAsyncAllBrands.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      // Handle createAsyncCategory
      .addCase(createAsyncBrands.pending, (state) => {
        state.status = "loading";
        state.error = null;
      })
      .addCase(createAsyncBrands.fulfilled, (state, action) => {
        state.allBrand.push(action.payload);
        state.status = "succeeded";
      })
      .addCase(createAsyncBrands.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      // Handle update brand
      .addCase(updateAsyncBrand.pending, (state) => {
        state.status = "loading";
        state.error = null;
      })
      .addCase(updateAsyncBrand.fulfilled, (state, action) => {
        const updateBrand = action.payload;
        if (updateBrand && updateBrand.id) {
          state.allBrand = state.allBrand.map((brand) =>
            brand.id === updateBrand.id
              ? updateBrand
              : brand
          );
        }
      })
      .addCase(updateAsyncBrand.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      // Handle deleteAsync brand
      .addCase(deleteAsyncBrand.pending, (state) => {
        state.status = "loading";
        state.error = null;
      })
      .addCase(deleteAsyncBrand.fulfilled, (state, action) => {
        const deletedId = action.payload;
        state.allBrand = state.allBrand.filter(
          (brand) => brand.id !== deletedId
        );
        state.status = "succeeded";
      })
      .addCase(deleteAsyncBrand.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
  },
});

export default brandSlice.reducer;
