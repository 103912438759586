import React, { useEffect, useMemo, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Dropdown } from "react-bootstrap";
import { SVGICON } from "../../constant/theme";
import EditOrderStatusModal from "./OrderModals/EditOrderStatusModal";
import { useDispatch } from "react-redux";
import { fetchAllOrder } from "../../../store/order/orderSlice";
import { useSelector } from "react-redux";

function getStatusColor(deliveryStatus) {
  switch (deliveryStatus) {
    case "new":
      return "primary";
    case "preparing":
      return "info";
    case "send_to_delivery":
      return "warning";
    case "delivered":
      return "success";
    case "cancelled":
      return "danger";
    default:
      return "secondary";
  }
}

const Order = () => {
  const dispatch = useDispatch();
  const [addActive, setActive] = useState("All");
  const [editItemsId, setEditItemsId] = useState();
  const [modalShow, setModalShow] = useState(false);
  const navigate = useNavigate();

  const handleEdit = (id) => {
    setModalShow(true);
    setEditItemsId(id);
  };

  useEffect(() => {
    dispatch(fetchAllOrder());
  }, [dispatch]);

  const allOrder = useSelector((state) => state.Order.allOrder);
  const status = useSelector((state) => state.Order.status);

  function formatDate(dateString) {
    const date = new Date(dateString);
    const options = {
      year: "numeric",
      month: "numeric",
      day: "numeric",
    };
    return date.toLocaleString("en-US", options);
  }

  const handleSelect = (path) => {
    navigate(path);
  };

  const reverseOrder = allOrder.slice().reverse();

  // Function to filter orders based on addActive value
  const filterOrders = (orders) => {
    if (addActive === "All") return orders;
    return orders.filter((order) => {
      if (addActive === "Preparing")
        return order.deliveryStatus === "preparing";
      if (addActive === "Delivered")
        return order.deliveryStatus === "delivered";
      if (addActive === "Canceled") return order.deliveryStatus === "cancelled";
      if (addActive === "new") return order.deliveryStatus === "new";
      return true;
    });
  };
  // Filtered orders
  const filteredOrders = filterOrders(reverseOrder);

  const [currentPage, setCurrentPage] = useState(0);
  const itemsPerPage = 10;

  const currentData = useMemo(() => {
    const startIndex = currentPage * itemsPerPage;
    return filteredOrders.slice(startIndex, startIndex + itemsPerPage);
  }, [currentPage, filteredOrders]);

  const totalPages = Math.ceil(filteredOrders.length / itemsPerPage);

  return (
    <div className="container">
      <div className="d-flex justify-content-between mb-4 flex-wrap">
        <ul className="revnue-tab nav nav-tabs" id="myTab">
          <li
            className="nav-item"
            onClick={() => {
              setActive("All");
            }}
          >
            <Link
              to={"#"}
              className={`nav-link ${addActive === "All" ? "active" : ""}`}
            >
              All
            </Link>
          </li>
          <li
            className="nav-item"
            onClick={() => {
              setActive("new");
            }}
          >
            <Link
              to={"#"}
              className={`nav-link ${addActive === "new" ? "active" : ""}`}
            >
              New
            </Link>
          </li>
          <li
            className="nav-item"
            onClick={() => {
              setActive("Preparing");
            }}
          >
            <Link
              to={"#"}
              className={`nav-link ${
                addActive === "Preparing" ? "active" : ""
              }`}
            >
              Preparing
            </Link>
          </li>
          <li
            className="nav-item"
            onClick={() => {
              setActive("Delivered");
            }}
          >
            <Link
              to={"#"}
              className={`nav-link ${
                addActive === "Delivered" ? "active" : ""
              }`}
            >
              Delivered
            </Link>
          </li>
          <li
            className="nav-item"
            onClick={() => {
              setActive("Canceled");
            }}
          >
            <Link
              to={"#"}
              className={`nav-link ${addActive === "Canceled" ? "active" : ""}`}
            >
              Canceled
            </Link>
          </li>
        </ul>
        <Link to="/add-order">
          <button className="btn btn-primary">Add Order</button>
        </Link>
      </div>
      <div className="row">
        <div className="col-xl-12">
          <div className="tab-content">
            <div className="tab-pane fade active show">
              <div className="card mt-2">
                <div className="card-body p-0">
                  <div className="table-responsive active-projects style-1 ItemsCheckboxSec shorting ">
                    <div
                      id="orderlist-table"
                      className="dataTables_wrapper no-footer"
                    >
                      <table
                        id="empoloyees-tbl"
                        className="table dataTable no-footer"
                      >
                        <thead>
                          <tr>
                            <th>Date</th>
                            <th>Customer</th>
                            <th>Location</th>
                            <th>Amount</th>
                            <th>Status</th>
                            <th></th>
                          </tr>
                        </thead>
                        <tbody>
                          {status === "loading" ? (
                            <p className="fs-4 mt-4 text-center">Loading</p>
                          ) : (
                            <>
                              {currentData?.map((item, index) => {
                                const statusColor = getStatusColor(
                                  item.deliveryStatus
                                );
                                return (
                                  <tr key={index}>
                                    <td>
                                      <span>{formatDate(item.updatedAt)}</span>
                                    </td>
                                    <td>
                                      <span>
                                        {`${item.orderInformation[0].firstName} ${item.orderInformation[0].lastName}`}
                                      </span>
                                    </td>
                                    <td>
                                      <span>
                                        {item.orderInformation[0].city}
                                      </span>
                                    </td>
                                    <td>
                                      <span>$ {(item.total).toFixed(2)}</span>
                                    </td>
                                    <td>
                                      <span
                                        onClick={() => handleEdit(item.id)}
                                        className={`badge badge-rounded cursor-pointer badge-lg badge-outline-${statusColor}`}
                                      >
                                        {item.deliveryStatus}
                                      </span>
                                    </td>
                                    <td>
                                      <Dropdown>
                                        <Dropdown.Toggle
                                          as="div"
                                          className="i-false btn-link"
                                          data-bs-toggle="dropdown"
                                        >
                                          {SVGICON.ThreeDot}
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu align="end">
                                          <Dropdown.Item
                                            onClick={() =>
                                              handleSelect(
                                                `/order-details/${item.id}`
                                              )
                                            }
                                            className="text-success"
                                          >
                                            Details
                                          </Dropdown.Item>
                                        </Dropdown.Menu>
                                      </Dropdown>
                                    </td>
                                  </tr>
                                );
                              })}
                            </>
                          )}
                          <EditOrderStatusModal
                            show={modalShow}
                            onHide={() => setModalShow(false)}
                            itemId={editItemsId}
                          />
                        </tbody>
                      </table>
                      <div className="d-sm-flex text-center justify-content-between align-items-center">
                        <div className="dataTables_info">
                          Showing {currentPage * itemsPerPage + 1} to{" "}
                          {Math.min(
                            (currentPage + 1) * itemsPerPage,
                            currentData.length
                          )}{" "}
                          of {currentData.length} entries
                        </div>
                        <div
                          className="dataTables_paginate paging_simple_numbers justify-content-center"
                          id="example2_paginate"
                        >
                          <Link
                            className={`paginate_button previous ${
                              currentPage === 0 ? "disabled" : ""
                            }`}
                            to="#"
                            onClick={() =>
                              currentPage > 0 && setCurrentPage(currentPage - 1)
                            }
                          >
                            Prev
                          </Link>
                          <span>
                            {Array.from({ length: totalPages }, (_, i) => (
                              <Link
                                key={i}
                                to="#"
                                className={`paginate_button ${
                                  currentPage === i ? "current" : ""
                                }`}
                                onClick={() => setCurrentPage(i)}
                              >
                                {i + 1}
                              </Link>
                            ))}
                          </span>
                          <Link
                            className={`paginate_button next ${
                              currentPage + 1 === totalPages ? "disabled" : ""
                            }`}
                            to="#"
                            onClick={() =>
                              currentPage + 1 < totalPages &&
                              setCurrentPage(currentPage + 1)
                            }
                          >
                            Next
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Order;
