import { del, get, patch, post } from "aws-amplify/api";
import { amplifyConfigs } from "../../config";

export async function getCollections() {
  try {
    const restOperation = get({
      apiName: amplifyConfigs.apiName,
      path: "/collections",
      options: {
        headers: {
          "Content-Type": "application/json",
          shopid: "1",
        },
      },
    });
    const response = await restOperation.response;
    return response.body.json();
  } catch (error) {
    
  }
}

export async function getSingleCollection(id) {
  try {
    const restOperation = get({
      apiName: amplifyConfigs.apiName,
      path: `/collections/${id}`,
    });
    const response = await restOperation.response;
    return response.body.json();
  } catch (error) {
    
  }
}

export async function postCollections(data) {
  try {
    const restOperation = post({
      apiName: amplifyConfigs.apiName,
      path: "/collections",
      options: {
        body: data,
      },
    });
    await restOperation.response;
  } catch (error) {
    
  }
}

// Update a collection
export async function updateCollection({ id, data }) {
  try {
    const restOperation = patch({
      apiName: amplifyConfigs.apiName,
      path: `/collections/${id}`,
      options: {
        body: data,
      },
    });
    await restOperation.response;
  } catch (error) {
    
  }
}

// Delete a collection
export async function deleteCollection(id) {
  try {
    const restOperation = del({
      apiName: amplifyConfigs.apiName,
      path: `/collections/${id}`,
    });
    await restOperation.response;
  } catch (error) {
    
  }
}

export async function collectionToProduct({ collectionId, productId }) {
  try {
    const restOperation = get({
      apiName: amplifyConfigs.apiName,
      path: `/collections/${collectionId}/products/${productId}`,
    });
    const response = await restOperation.response;
    return response.body.json();
  } catch (error) {
    
  }
}
