import React, { useState } from "react";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";

const UserModal = (props) => {
  const [validated, setValidated] = useState(false);

  const handleSubmit = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }
    setValidated(true);
  };

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">Add User</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form noValidate validated={validated} onSubmit={handleSubmit}>
          <Row className="mb-3">
            <Form.Group as={Col} md="4" controlId="validationCustom01">
              <Form.Label>Name</Form.Label>
              <Form.Control required type="text" placeholder="First name" />
              <Form.Control.Feedback type="invalid">
                Please Provide a Valid Name
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group as={Col} md="4" controlId="validationCustom02">
              <Form.Label>Title</Form.Label>
              <Form.Control required type="text" placeholder="Title" />
              <Form.Control.Feedback type="invalid">
                Please Provide a Valid Title
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group as={Col} md="4" controlId="validationCustom03">
              <Form.Label>Date Of Birth</Form.Label>
              <Form.Control required type="date" placeholder="Date Of Birth" />
              <Form.Control.Feedback type="invalid">
                Please Provide Valid DOB
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group className="mt-3" as={Col} md="6" controlId="validationCustom04">
              <Form.Label>Phone Number</Form.Label>
              <Form.Control
                required
                type="number"
                placeholder="Phone Number"
                pattern="^(70|71|76|78|79|81|03|3)\d{6}$"
              />
              <Form.Control.Feedback type="invalid">
                Please Provide a Valid Phone Number
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group className="mt-3" as={Col} md="6" controlId="validationCustom03">
              <Form.Label>Address</Form.Label>
              <Form.Control required type="text" placeholder="Address" />
              <Form.Control.Feedback type="invalid">
                Please Provide a Valid Address
              </Form.Control.Feedback>
            </Form.Group>
          </Row>
          <Button type="submit">Submit form</Button>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default UserModal;
