import { useEffect, useRef, useState } from "react";
import { Button } from "react-bootstrap";

import { SVGICON } from "../../constant/theme";
import Invoice from "./Invoice";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { fetchSingleOrder } from "../../../store/order/orderSlice";
import { useSelector } from "react-redux";
import Loading from "../../components/Loading";

const OrderDetails = () => {
  const dispatch = useDispatch();
  const [showPDF, setShowPDF] = useState(false);
  const orderId = useParams().id;
  const navigation = useNavigate();

  const invoiceRef = useRef(null);

  useEffect(() => {
    dispatch(fetchSingleOrder({ id: orderId }));
  }, [dispatch, orderId]);

  const order = useSelector((state) => state.Order.singleOrder);

  const orderStatus = useSelector((state) => state.Order.singleOrderStatus);

  const handleEditOrder = (id) => {
    navigation(`/edit-order/${id}`);
  };

  const calculateTotalPrice = (quantity, price = 0) => {
    return (quantity * price).toFixed(2);
  };

  const calculateSubtotal = (order) => {
    return (order?.total - order?.deliveryFee).toFixed(2);
  };

  let historyData = [];

  if (order?.deliveryBy) {
    historyData.unshift({
      title: `Your Order on Delivery by ${order.deliveryBy}`,
    });
  }

  if (order?.preparedBy) {
    historyData.unshift({
      title: `Order Prepared by ${order.preparedBy}`,
      timing: order.preparedDate,
    });
  }

  if (order?.sendToDeliveryBy) {
    historyData.unshift({
      title: `Order Sent to Delivery by ${order.sendToDeliveryBy}`,
      timing: order.sendToDeliveryDate,
    });
  }

  if (order?.deliveredBy) {
    historyData.unshift({
      title: `Order Delivered by ${order.deliveredBy}`,
      timing: order.deliveredDate,
    });
  }

  if (order?.cancelledBy) {
    historyData.unshift({
      title: `Order Cancelled by ${order.cancelledBy}`,
      timing: order.cancelledDate,
    });
  }

  useEffect(() => {
    if (showPDF && invoiceRef.current) {
      invoiceRef.current.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  }, [showPDF]);

  // console.log(order);

  return (
    <div className="container">
      <div className="row">
        <div className="col-xl-12">
          <div className="row">
            {orderStatus === "loading" ? (
              <Loading status={orderStatus} />
            ) : (
              <>
                <div className="col-xl-4">
                  <div className="card">
                    <div className="card-header border-0 pb-0">
                      <h4 className="title">Customer Location</h4>
                    </div>
                    <div className="card-body pt-2 px-0">
                      <div
                        className="history-box dz-scroll"
                        style={{ height: "300px" }}
                      >
                        <ul className="history">
                          <li>
                            <div className="history-deatils">
                              <h6 className="font-w600">Street Address</h6>
                              <span className="text-primary">
                                {order?.orderInformation[0]?.streetAddress}
                              </span>
                            </div>
                          </li>
                        </ul>
                        <ul className="history">
                          <li>
                            <div className="history-deatils">
                              <h6 className="font-w600">City</h6>
                              <span className="text-primary">
                                {order?.orderInformation[0]?.city}
                              </span>
                            </div>
                          </li>
                        </ul>
                        <ul className="history">
                          <li>
                            <div className="history-deatils">
                              <h6 className="font-w600">Country</h6>
                              <span className="text-primary">
                                {order?.orderInformation[0]?.country}
                              </span>
                            </div>
                          </li>
                        </ul>
                        {order?.orderInformation[0]?.deliveryZone && (
                          <ul className="history">
                            <li>
                              <div className="history-deatils">
                                <h6 className="font-w600">Country</h6>
                                <span className="text-primary">
                                  {order?.orderInformation[0]?.country}
                                </span>
                              </div>
                            </li>
                          </ul>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-xl-8">
                  <div className="card">
                    <div className="card-header border-0 pb-0">
                      <h4 className="h-title">Items</h4>
                      <div>
                        <Button
                          variant="primary"
                          className="me-2"
                          onClick={() => handleEditOrder(orderId)}
                        >
                          Edit Order
                        </Button>
                        <Button
                          variant="primary"
                          onClick={() => setShowPDF(!showPDF)}
                        >
                          {showPDF ? "Close Invoice" : "View Invoice"}
                        </Button>
                      </div>
                    </div>
                    <div className="card-body pt-2">
                      {order?.orderItems?.map((item, ind) => (
                        <div className="food-items-bx" key={ind}>
                          <div className="">
                            <img
                              className="orderDetails-image rounded-2"
                              src={item?.images[0]?.url}
                              alt=""
                            />
                          </div>
                          <div className="d-flex w-100 px-2 flex-column flex-md-row align-items-end">
                            <div className="food-items-info w-100">
                              <span className="text-primary">
                                {item.product.categories?.name}
                              </span>
                              <h6>{item.product.name}</h6>
                              <span>
                                Price:{" "}
                                <span className="text-blue text-primary fw-semibold">
                                  $
                                  {item?.size === null
                                    ? item?.product?.price
                                    : item?.price}
                                </span>
                              </span>
                              {item?.size && (
                                <span className="ms-2">Size: {item?.size}</span>
                              )}
                              {item?.color && (
                                <span className="ms-2">
                                  Color:{" "}
                                  <span
                                    style={{
                                      backgroundColor: item?.color,
                                      width: "12px",
                                      height: "12px",
                                      display: "inline-block",
                                      border: "1px solid #000",
                                      borderRadius: "50%",
                                    }}
                                  ></span>
                                </span>
                              )}
                            </div>

                            <p className="mb-0 ms-3 w-50 text-end">Qty: {item?.quantity}</p>
                            <p className="mb-0 ms-3 w-50 text-end">
                              Subtotal:
                              <span className="text-primary fw-semibold">
                                $
                                {calculateTotalPrice(
                                  item?.quantity,
                                  item?.size === null
                                    ? item?.product?.price
                                    : item?.price
                                )}
                              </span>
                            </p>
                          </div>
                        </div>
                      ))}

                      <hr />
                      <div className="food-totle">
                        <ul className="d-flex align-items-center justify-content-between">
                          <li>
                            <span>Subtotal</span>
                          </li>
                          <li>
                            <h6>$ {calculateSubtotal(order)}</h6>
                          </li>
                        </ul>
                        <ul className="d-flex align-items-center justify-content-between">
                          <li>
                            <span>Delivery Charge</span>
                          </li>
                          <li>
                            <h6>$ {order?.deliveryFee || 0.0}</h6>
                          </li>
                        </ul>
                        {order?.discountFee && (
                          <ul className="d-flex align-items-center justify-content-between">
                            <li>
                              <span>Discount Fee</span>
                            </li>
                            <li>
                              <h6>$ {order?.discountFee}</h6>
                            </li>
                          </ul>
                        )}
                        <ul className="d-flex align-items-center justify-content-between">
                          <li>
                            <span>Total</span>
                          </li>
                          <li>
                            <h6>$ {order?.total?.toFixed(2)}</h6>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-12 col-md-8">
                  <div className="card">
                    <div className="card-header border-0 pb-0">
                      <h4 className="title">History</h4>
                    </div>
                    <div className="card-body pt-2 px-0">
                      <div
                        className="history-box dz-scroll"
                        style={{ height: "300px" }}
                      >
                        {historyData.length > 0 ? (
                          historyData.map((item, index) => (
                            <ul className="history" key={index}>
                              <li>
                                <div className="history-dot"></div>
                                <div className="history-deatils">
                                  <h6 className="font-w600">{item.title}</h6>
                                  <span className="text-primary">
                                    {item.timing}
                                  </span>
                                </div>
                              </li>
                            </ul>
                          ))
                        ) : (
                          <p className="ms-3">No history data available.</p>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-12 col-md-4">
                  <div className="card h-auto">
                    <div className="card-body">
                      <ul className="d-flex mb-3">
                        <li>
                          <Link to={"#"} className="icon-bx icon-outline-bx ">
                            {SVGICON.UserSvg}
                          </Link>
                        </li>
                        <li className="ms-2">
                          <span className="mb-0">Name</span>
                          <h6 className="mb-0">
                            {order?.orderInformation[0]?.firstName}{" "}
                            {order?.orderInformation[0]?.lastName}
                          </h6>
                        </li>
                      </ul>
                      <ul className="d-flex mb-3">
                        <li>
                          <Link to={"#"} className="icon-bx icon-outline-bx">
                            {SVGICON.Location}
                          </Link>
                        </li>
                        <li className="ms-2">
                          <span className="mb-0">Phone</span>
                          <h6 className="mb-0">
                            {order?.orderInformation[0]?.phone}
                          </h6>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>

                {/* pdf is here */}
                <div ref={invoiceRef}>
                  <Invoice orders={order} showPDF={showPDF} />
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default OrderDetails;
