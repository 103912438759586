import { del, get, patch, post } from "aws-amplify/api";
import { amplifyConfigs } from "../../config";
import Swal from "sweetalert2";

export async function getCategory() {
  try {
    const restOperation = get({
      apiName: amplifyConfigs.apiName,
      path: "/categories",
    });
    const response = await restOperation.response;
    return response.body.json();
  } catch (error) {}
}

export async function getSingleCategory(id) {
  try {
    const restOperation = get({
      apiName: amplifyConfigs.apiName,
      path: `/categories/${id}`,
    });
    const response = await restOperation.response;
    return response.body.json();
  } catch (error) {}
}

export async function postCategory(data) {
  try {
    const restOperation = post({
      apiName: amplifyConfigs.apiName,
      path: "/categories",
      options: {
        body: data,
      },
    });
    await restOperation.response;
  } catch (error) {}
}

// Update a category
export async function updateCategory({ data, id }) {
  try {
    const restOperation = patch({
      apiName: amplifyConfigs.apiName,
      path: `/categories/${id}`,
      options: {
        body: data,
      },
    });
    const response = await restOperation.response;
    Swal.fire({
      icon: "success",
      title: "Category Created",
      text: "Your category has been created successfully!",
    });
    return response;
  } catch (error) {
    Swal.fire({
      icon: "error",
      title: "Oops...",
      text: "Failed to update category. Please try again later.",
    });
  }
}

// Delete a category
export async function deleteCategory(categoryId) {
  try {
    const restOperation = del({
      apiName: amplifyConfigs.apiName,
      path: `/categories/${categoryId}`,
    });
    const response = await restOperation.response;
    Swal.fire({
      icon: "success",
      title: "Success",
      text: "Category deleted successfully",
    });
    return response;
  } catch (error) {
    Swal.fire({
      icon: "error",
      title: "Oops...",
      text: "Failed to delete category. Please try again later.",
    });
  }
}

// Add sub-category
export async function postSubCategory({ data, id }) {
  try {
    const restOperation = post({
      apiName: amplifyConfigs.apiName,
      path: `/subcollections/categories/${id}`,
      options: {
        body: data,
      },
    });
    const response = await restOperation.response;
    Swal.fire({
      icon: "success",
      title: "Success",
      text: "Sub-category added successfully",
    });
    return response;
  } catch (error) {}
}

// Update a category
export async function updateSubCategory({ data, id }) {
  try {
    const restOperation = patch({
      apiName: amplifyConfigs.apiName,
      path: `/subcollections/${id}`,
      options: {
        body: data,
      },
    });
    const response = await restOperation.response;
    Swal.fire({
      icon: "success",
      title: "Sub-category updated",
      text: "Your sub-category has been updated successfully!",
    });
    return response;
  } catch (error) {
    Swal.fire({
      icon: "error",
      title: "Oops...",
      text: "Failed to update sub-category. Please try again later.",
    });
  }
}

export async function getSingleSubCategory({id}) {
  try {
    const restOperation = get({
      apiName: amplifyConfigs.apiName,
      path: `/subcollections/${id}`,
    });
    const response = await restOperation.response;
    return response.body.json();
  } catch (error) {}
}

// Delete sub category
export async function deleteSubCategory(id) {
  try {
    const restOperation = del({
      apiName: amplifyConfigs.apiName,
      path: `/subcollections/${id}`,
    });
    const response = await restOperation.response;
    // Swal.fire({
    //   icon: "success",
    //   title: "Success",
    //   text: "Sub-category deleted successfully",
    // });
    return response;
  } catch (error) {
    Swal.fire({
      icon: "error",
      title: "Oops...",
      text: "Failed to delete sub-category. Please try again later.",
    });
  }
}
