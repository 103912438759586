import React from "react";
import {
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  PDFViewer,
} from "@react-pdf/renderer";

// Styles definition
const styles = StyleSheet.create({
  page: {
    flexDirection: "column",
    backgroundColor: "#fff",
    marginTop: 10,
    padding: 40,
  },
  header: {
    fontSize: 12,
    textAlign: "center",
    marginBottom: 20,
  },
  userInfo: {
    marginBottom: 10,
    fontSize: 10,
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  table: {
    display: "table",
    width: "auto",
    borderStyle: "solid",
    borderWidth: 1,
    borderRightWidth: 0,
    borderBottomWidth: 0,
  },
  tableRow: {
    flexDirection: "row",
  },
  tableCol: {
    width: "25%",
    borderStyle: "solid",
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
    padding: 5,
  },
  tableCell: {
    marginTop: 5,
    fontSize: 10,
  },
  totals: {
    marginTop: 10,
    paddingTop: 10,
    fontSize: 10,
    textAlign: "right",
  },
});

function formatDate(dateString) {
  const date = new Date(dateString);
  const options = {
    year: "numeric",
    month: "numeric",
    day: "numeric",
    // hour: 'numeric',
    // minute: '2-digit',
    // hour12: true
  };
  return date.toLocaleString("en-US", options);
}

const calculateTotalPrice = (quantity, price = 0) => {
  return quantity * price;
};

const calculateSubtotal = (order) => {
  return order?.total - order?.deliveryFee;
};

const Invoice = ({ showPDF, orders }) => {
  return (
    <div>
      {showPDF && (
        <PDFViewer width="100%" height="600px">
          <Document>
            <Page style={styles.page} size="A6">
              <Text style={styles.header}>Al Khatib Invoice</Text>
              <View style={styles.userInfo}>
                <View>
                  <Text style={{ paddingBottom: "5px" }}>
                    Customer: {orders.orderInformation[0].firstName}{" "}
                    {orders.orderInformation[0].lastName}
                  </Text>
                  <Text style={{ paddingBottom: "5px" }}>
                    Phone: {orders.orderInformation[0]?.phone}
                  </Text>
                </View>
                <View style={{ textAlign: "right " }}>
                  <Text style={{ paddingBottom: "5px" }}>
                    Shipping Address:{" "}
                    {orders.orderInformation[0]?.streetAddress}
                  </Text>
                  <Text style={{ paddingBottom: "5px", marginLeft: "auto" }}>
                    Date: {formatDate(orders.orderInformation[0]?.createdAt)}
                  </Text>
                </View>
              </View>

              <View style={styles.table}>
                <View style={styles.tableRow}>
                  <View style={styles.tableCol}>
                    <Text style={styles.tableCell}>Product</Text>
                  </View>
                  <View style={styles.tableCol}>
                    <Text style={styles.tableCell}>Quantity</Text>
                  </View>
                  <View style={styles.tableCol}>
                    <Text style={styles.tableCell}>Price</Text>
                  </View>
                  <View style={styles.tableCol}>
                    <Text style={styles.tableCell}>Total</Text>
                  </View>
                </View>
                {orders?.orderItems?.map((item, index) => (
                  <View key={index} style={styles.tableRow}>
                    <View style={styles.tableCol}>
                      <Text style={styles.tableCell}>
                        {item?.product?.name}
                      </Text>
                    </View>
                    <View style={styles.tableCol}>
                      <Text style={styles.tableCell}>{item?.quantity}</Text>
                    </View>
                    <View style={styles.tableCol}>
                      <Text style={styles.tableCell}>
                        {item.product?.sizes?.length > 0
                          ? item.product?.sizes[0]?.price
                          : item?.price}
                      </Text>
                    </View>
                    <View style={styles.tableCol}>
                      <Text style={styles.tableCell}>
                        {calculateTotalPrice(
                          item?.quantity,
                          item.product?.sizes?.length > 0
                            ? item.product?.sizes[0]?.price
                            : item?.price
                        )}
                      </Text>
                    </View>
                  </View>
                ))}
              </View>
              <View style={styles.totals}>
                <Text>Subtotal: ${calculateSubtotal(orders).toFixed(2)}</Text>
                <Text>Delivery Charge: ${orders?.deliveryFee?.toFixed(2) || 0.00}</Text>
                <Text>Total: ${orders?.total?.toFixed(2)}</Text>
              </View>
            </Page>
          </Document>
        </PDFViewer>
      )}
    </div>
  );
};

export default Invoice;
