import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import { RouterProvider } from "react-router-dom";
import { Provider } from "react-redux";
import { store } from "./store/store";
import ThemeContext from "./context/ThemeContext";

import Router from "./jsx/Router";
import "./css/custom.css";
import { Amplify } from "aws-amplify";
import { amplifyConfigs } from "./config";


Amplify.configure({
  Auth: {
    Cognito: {
      userPoolId: amplifyConfigs.userPoolId,
      userPoolClientId: amplifyConfigs.userPoolClientId,
      identityPoolId: amplifyConfigs.identityPoolId,
    },
  },
  API: {
    REST: {
      [amplifyConfigs.apiName]: {
        endpoint: amplifyConfigs.apiURL,
        region: amplifyConfigs.apiRegion,
      },
    },
  },
});

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <ThemeContext>
        <RouterProvider router={Router}></RouterProvider>
      </ThemeContext>
    </Provider>
  </React.StrictMode>
);
