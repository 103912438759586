import { del, get, patch, post } from "aws-amplify/api";
import { amplifyConfigs } from "../../config";

export async function getTags() {
  try {
    const restOperation = get({
      apiName: amplifyConfigs.apiName,
      path: "/tags",
    });
    const response = await restOperation.response;
    return response.body.json();
  } catch (error) {
    
  }
}

export async function postTags(data) {
  try {
    const restOperation = post({
      apiName: amplifyConfigs.apiName,
      path: "/tags",
      options: {
        body: data,
      },
    });
    await restOperation.response;
  } catch (error) {
    
  }
}

// Update a tag
export async function updateTag({ id, data }) {
  try {
    const restOperation = patch({
      apiName: amplifyConfigs.apiName,
      path: `/tags/${id}`,
      options: {
        body: data,
      },
    });
    await restOperation.response;
  } catch (error) {
    
  }
}

// Delete a tag
export async function deleteTag(id) {
  try {
    const restOperation = del({
      apiName: amplifyConfigs.apiName,
      path: `/tags/${id}`,
    });
    await restOperation.response;
  } catch (error) {
    
  }
}
