import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import {
  deleteAsyncCollection,
  fetchAsyncAllCollections,
} from "../../../store/collections/collectionSlice";
import { useSelector } from "react-redux";
import Loading from "../../components/Loading";
import Swal from "sweetalert2";
import { FaRegEye } from "react-icons/fa6";
import { MdEdit } from "react-icons/md";
import { MdDeleteForever } from "react-icons/md";

const Collection = () => {
  const dispatch = useDispatch();

  // Fetch all categories
  useEffect(() => {
    dispatch(fetchAsyncAllCollections());
  }, [dispatch]);

  const collections = useSelector((state) => state.Collection.allCollection);
  const status = useSelector((state) => state.Collection.status);

  const handleDeleteCollection = async (id, event) => {
    event.preventDefault();
    const result = await Swal.fire({
      title: "Are you sure?",
      text: "You want to delete this collection?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#3085d6",
      confirmButtonText: "Yes, delete it!",
    });

    if (result.isConfirmed) {
      try {
        await dispatch(deleteAsyncCollection(id));
        await dispatch(fetchAsyncAllCollections());
        Swal.fire("Deleted!", "Your collection has been deleted.", "success");
      } catch (error) {
        Swal.fire(
          "Error!",
          "Failed to delete collection. Please try again later.",
          "error"
        );
      }
    }
  };

  return (
    <div className="container">
      <Link className="text-white" to="/collections/add">
        <button className="btn btn-primary mb-3">
          Create a new collection
        </button>
      </Link>

      {status === "loading" ? (
        <Loading status={status} />
      ) : (
        <div className="row">
          <div className="col-xl-12">
            <div className="card mt-2">
              <div className="card-body p-0">
                <div className="table-responsive active-projects style-1 ItemsCheckboxSec shorting ">
                  <div
                    id="orderlist-table"
                    className="dataTables_wrapper no-footer"
                  >
                    <table
                      id="empoloyees-tbl"
                      className="table dataTable no-footer"
                    >
                      <thead>
                        <tr>
                          <th>name</th>
                          <th className="text-end">Actions</th>
                        </tr>
                      </thead>
                      <tbody>
                        {collections && collections.length > 0 ? (
                          <>
                            {collections?.map((collection) => {
                              return (
                                <tr
                                  className="list-border"
                                  key={collection?.id}
                                >
                                  <td>
                                    <Link
                                      to={`/collection/${collection?.id}/products`}
                                    >
                                      <span>{collection?.name}</span>
                                    </Link>
                                  </td>
                                  <td className="text-end">
                                    <Link
                                      to={`/collection/${collection?.id}/products`}
                                    >
                                      <button className="btn btn-outline-success me-2">
                                        <FaRegEye />
                                      </button>
                                    </Link>
                                    <Link
                                      to={`/collections/edit/${collection?.id}`}
                                    >
                                      <button className="btn btn-outline-primary me-2">
                                        <MdEdit />
                                      </button>
                                    </Link>
                                    <button
                                      className="btn btn-outline-danger"
                                      onClick={(event) =>
                                        handleDeleteCollection(
                                          collection?.id,
                                          event
                                        )
                                      }
                                    >
                                      <MdDeleteForever />
                                    </button>
                                  </td>
                                </tr>
                              );
                            })}
                          </>
                        ) : (
                          <h3 className="mt-4 ms-4 ">No product found</h3>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Collection;
