import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import Swal from "sweetalert2";
import {
  deleteAsyncDelivery,
  fetchAsyncAllDelivery,
} from "../../../../store/DeliveryZones/DeliveryZonesSlice";
import DeliveryZonesModal from "./DeliveryZonesModal";
import { useSelector } from "react-redux";
import { MdDeleteForever } from "react-icons/md";

const DeliveryZones = () => {
  const dispatch = useDispatch();
  const [show, setShow] = useState(false);
  const handleModal = () => {
    setShow(true);
  };
  useEffect(() => {
    dispatch(fetchAsyncAllDelivery());
  }, [dispatch]);
  const delivery = useSelector((state) => state.Delivery.allDelivery);
  const status = useSelector((state) => state.Delivery.status);

  const handleDelete = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(deleteAsyncDelivery(id));
      }
    });
  };

  return (
    <div className="row">
      <div className="col-md-3">
        <h5>Delivery Zones</h5>
        <p className="text-muted">
          Your location name abss is displayed across many areas including on
          your online pages and messages to client
        </p>
      </div>

      <div className="col-md-9">
        {status === "loading" ? (
          <>Loading Please wait </>
        ) : (
          <>
            {delivery.map((data, index) => (
              <div key={index} className="d-flex align-items-center gap-3">
                <div>
                  <MdDeleteForever
                    onClick={() => handleDelete(data.id)}
                    className="fs-3 mb-3 cursor-pointer text-danger"
                    title="Delete Item"
                  />
                </div>
                <div>
                  <ul className="mb-3">
                    <li>
                      Location: <strong>{data.areaName}</strong>
                    </li>
                    <li>
                      Delivery Fee: <strong>$ {data.deliveryFee}</strong>
                    </li>
                  </ul>
                </div>
              </div>
            ))}
          </>
        )}
        <button
          onClick={handleModal}
          className="btn btn-outline-primary rounded-5"
        >
          Add
        </button>

        <DeliveryZonesModal status={status} show={show} onHide={() => setShow(false)} />
      </div>
    </div>
  );
};

export default DeliveryZones;
