import { del, get, post } from "aws-amplify/api";
import { amplifyConfigs } from "../../config";
import Swal from "sweetalert2";

export async function postDeliveryZones(data) {
  
  try {
    const restOperation = post({
      apiName: amplifyConfigs.apiName,
      path: "/delivery-fees",
      options: {
        body: data,
      },
    });
    const response = await restOperation.response;
    Swal.fire({
      icon: "success",
      title: "Success",
      text: "Delivery zone added successfully",
    });
    return response;
  } catch (error) {
    
    Swal.fire({
      icon: "error",
      title: "Error",
      text: "Failed to add delivery zone",
    });
  }
}

export async function getDeliveryZones() {
  try {
    const restOperation = get({
      apiName: amplifyConfigs.apiName,
      path: "/delivery-fees",
    });
    const response = await restOperation.response;
    return response.body.json();
  } catch (error) {
    
  }
}

// Delete a delivery zone
export async function deleteDeliveryZone(id) {
  try {
    const restOperation = del({
      apiName: amplifyConfigs.apiName,
      path: `/delivery-fees/${id}`,
    });
    const response = await restOperation.response;
    Swal.fire({
      icon: "success",
      title: "Success",
      text: "Delivery zone deleted successfully",
    });
    return response;
  } catch (error) {
    
  }
}
