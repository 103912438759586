import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import {
  fetchAllOrder,
  updateOrderStatus,
} from "../../../../store/order/orderSlice";
import { useDispatch } from "react-redux";
import Swal from "sweetalert2";
import Select from "react-select";
import { useState } from "react";

const allStatus = [
  {
    value: "Preparing",
    id: "preparing",
    label: "Preparing",
    name: "Preparing",
  },
  {
    value: "Sent to Delivery",
    id: "send_to_delivery",
    label: "Sent to Delivery",
    name: "Sent to Delivery",
  },
  {
    value: "delivered",
    id: "delivered",
    label: "Delivered",
    name: "Delivered",
  },
  {
    value: "cancelled",
    id: "cancelled",
    label: "Cancelled",
    name: "Cancelled",
  },
];

const EditOrderStatusModal = ({ itemId, ...props }) => {
  const [selectedOption, setSelectedOption] = useState(null);

  const dispatch = useDispatch();

  const handleEditProduct = async (status) => {
    const dataToSend = {
      id: itemId,
      data: {
        status: status,
      },
    };
    try {
      await dispatch(updateOrderStatus(dataToSend));
      await dispatch(fetchAllOrder());
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Something went wrong!",
      });
    }
  };

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Change Order Status
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Select
          onChange={setSelectedOption}
          options={allStatus}
          style={{
            lineHeight: "40px",
            color: "#7e7e7e",
            paddingLeft: " 15px",
          }}
        />
      </Modal.Body>
      <Modal.Footer>
        <Button
          onClick={() => {
            handleEditProduct(selectedOption.id);
            props.onHide();
          }}
        >
          Save
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default EditOrderStatusModal;
