import React, { useEffect, useState, useRef } from "react";
import { Link } from "react-router-dom";
import { Tab, Modal, Spinner } from "react-bootstrap";
import { useDispatch } from "react-redux";
import {
  createAsyncCoupon,
  deleteAsyncCoupon,
  fetchAsyncAllCoupon,
  updateAsyncCoupon,
} from "../../../store/coupon/couponSlice";
import Swal from "sweetalert2";
import { useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { MdEdit } from "react-icons/md";
import { MdDeleteForever } from "react-icons/md";

const DiscountCoupon = () => {
  const dispatch = useDispatch();
  const [mode, setMode] = useState("add");
  const [editingCoupon, setEditingCoupon] = useState(null);
  const [show, setShow] = useState(false);


  useEffect(() => {
    dispatch(fetchAsyncAllCoupon());
  }, [dispatch]);

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  const coupons = useSelector((state) => state.Coupon.allCoupon);
  const loading = useSelector((state) => state.Coupon.status);

  useEffect(() => {
    if (mode === "edit") {
      reset({
        name: editingCoupon[0].name,
        discount: editingCoupon[0].discount,
        activeFrom: editingCoupon[0].activeFrom,
        expireDate: editingCoupon[0].expireDate,
        isDeliveryFree: editingCoupon[0].isDeliveryFree,
        numberOfUse: editingCoupon[0].numberOfUse,
      });
    } else {
      reset();
    }
  }, [mode, editingCoupon, reset]);

  useEffect(() => {
    if (show && mode === "add") {
      reset({
        name: "",
        discount: "",
        activeFrom: "",
        expireDate: "",
        isDeliveryFree: false,
        numberOfUse: "",
      });
    }
  }, [show, mode, reset]);

  // const handleSave = () => {
  //   const discountValue = parseFloat(discount);
  //   const numberOfUseValue = parseInt(numberOfUse);
  //   const addNewData = {
  //     name,
  //     discount: discountValue,
  //     activeFrom,
  //     expireDate,
  //     isDeliveryFree,
  //     numberOfUse: numberOfUseValue,
  //   };

  //   const updateData = {
  //     couponId: editingCoupon?.id,
  //     data: addNewData,
  //   };

  //   // Dispatch the appropriate action based on the mode
  //   // const saveAction =
  //   //   mode === "edit"
  //   //     ? updateAsyncCoupon(updateData)
  //   //     : createAsyncCoupon(addNewData);

  //   // dispatch(saveAction)
  //   //   .then(() => {
  //   //     Swal.fire({
  //   //       icon: "success",
  //   //       title: mode === "edit" ? "Coupon Updated" : "Coupon Created",
  //   //       text: `Your coupon has been ${
  //   //         mode === "edit" ? "updated" : "created"
  //   //       } successfully!`,
  //   //       onClose: () => {
  //   //         setShow(false);
  //   //         dispatch(fetchAsyncAllCoupon());
  //   //       },
  //   //     });
  //   //     dispatch(fetchAsyncAllCoupon());
  //   //   })
  //   //   .catch((error) => {
  //   //     Swal.fire({
  //   //       icon: "error",
  //   //       title:
  //   //         mode === "edit" ? "Coupon Update Failed" : "Coupon Creation Failed",
  //   //       text: `There was an error ${
  //   //         mode === "edit" ? "updating" : "creating"
  //   //       } the coupon. Please try again later.`,
  //   //     });
  //   //   });
  // };

  const onSubmit = async (addNewData) => {
    Swal.fire({
      position: "top-end",
      title: "Wait a moment, please",
      showConfirmButton: false,
      icon: "info",
      timer: 2000,
      toast: true,
    });

    addNewData.numberOfUse = parseInt(addNewData.numberOfUse);
    addNewData.discount = parseFloat(addNewData.discount);

    const updateData = {
      ...(mode === "edit" &&
        editingCoupon[0]?.id && { couponId: editingCoupon[0].id }),
      data: addNewData,
    };
    try {
      if (mode === "edit") {
        await dispatch(updateAsyncCoupon(updateData));
      }
      if (mode === "add") {
        await dispatch(createAsyncCoupon(addNewData));
      }
      await dispatch(fetchAsyncAllCoupon());
      setShow(false);
    } catch (error) {
      Swal.fire({
        icon: "error",
        title:
          mode === "edit" ? "Coupon Update Failed" : "Coupon Creation Failed",
        text: `There was an error ${
          mode === "edit" ? "updating" : "creating"
        } the coupon. Please try again later.`,
        timer: 2000,
      });
    }
  };

  // Function to handle editing a coupon
  const handleEdit = (id) => {
    setMode("edit");
    setEditingCoupon(coupons.filter((coupon) => coupon.id === id));
    setShow(true);
  };

  const handleAddBtn = () => {
    setShow(true);
    setMode("add");
  };

  const handleDelete = async (id) => {
    const result = await Swal.fire({
      title: "Are you sure?",
      text: "You will not be able to recover this coupon!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    });

    // If user confirms the deletion
    if (result.isConfirmed) {
      await dispatch(deleteAsyncCoupon(id));
      dispatch(fetchAsyncAllCoupon());

      // Display a success Swal notification
      Swal.fire("Deleted!", "Your coupon has been deleted.", "success");
    }
  };

  const [data, setData] = useState(
    document.querySelectorAll("#orderlist-table tbody tr")
  );
  const sort = 10;
  const activePag = useRef(0);
  const [test, settest] = useState(0);
  const chageData = (frist, sec) => {
    for (var i = 0; i < data.length; ++i) {
      if (i >= frist && i < sec) {
        data[i].classList.remove("d-none");
      } else {
        data[i].classList.add("d-none");
      }
    }
  };

  useEffect(() => {
    setData(document.querySelectorAll("#orderlist-table tbody tr"));
  }, [test]);

  activePag.current === 0 && chageData(0, sort);
  let paggination = Array(Math.ceil(data.length / sort))
    .fill()
    .map((_, i) => i + 1);
  const onClick = (i) => {
    activePag.current = i;
    chageData(activePag.current * sort, (activePag.current + 1) * sort);
    settest(i);
  };

  return (
    <div className="container">
      <Tab.Container defaultActiveKey={"Active"}>
        <div className="d-flex justify-content-between mb-4 flex-wrap">
          <div className="d-flex align-items-center justify-content-end">
            <Link
              to={"#"}
              className="btn btn-primary me-2"
              onClick={() => handleAddBtn()}
            >
              Add Coupon
            </Link>
          </div>
        </div>
        <div className="row">
          <div className="col-xl-12">
            <Tab.Content>
              <Tab.Pane eventKey={"Active"}>
                <div className="card mt-2">
                  <div className="card-body p-0">
                    <div className="table-responsive active-projects style-1 ItemsCheckboxSec shorting ">
                      <div
                        id="orderlist-table"
                        className="dataTables_wrapper no-footer"
                      >
                        <table
                          id="empoloyees-tbl"
                          className="table dataTable no-footer"
                        >
                          <thead>
                            <tr>
                              <th>name</th>
                              <th>Discount Percent</th>
                              <th>Valid Start</th>
                              <th>Valid Until</th>
                              <th>Delivery</th>
                              <th>Redemption Limit</th>
                              <th>Actions</th>
                            </tr>
                          </thead>
                          <tbody>
                            {loading === "loading" ? (
                              <p className="fs-4 mt-4 text-center">Loading</p>
                            ) : (
                              <>
                                {coupons?.map((item) => (
                                  <tr key={item.id}>
                                    <td>
                                      <span>{item.name}</span>
                                    </td>
                                    <td>
                                      <span>{item.discount}%</span>
                                    </td>
                                    <td>
                                      <div className="d-flex align-items-center">
                                        <p className="mb-0 ms-2">
                                          {item.activeFrom}
                                        </p>
                                      </div>
                                    </td>
                                    <td>
                                      <div className="d-flex align-items-center">
                                        <p className="mb-0 ms-2">
                                          {item.expireDate}
                                        </p>
                                      </div>
                                    </td>
                                    <td>
                                      <div className="d-flex align-items-center">
                                        <p className="mb-0 ms-2">
                                          {item.isDeliveryFree
                                            ? "Free Delivery"
                                            : "COD"}
                                        </p>
                                      </div>
                                    </td>
                                    <td>
                                      <div className="d-flex align-items-center">
                                        <p className="mb-0 ms-2">
                                          {item.numberOfUse} times
                                        </p>
                                      </div>
                                    </td>
                                    <td>
                                      <button
                                        className="btn btn-outline-primary me-2"
                                        onClick={() => handleEdit(item.id)}
                                      >
                                        <MdEdit />
                                      </button>
                                      <button
                                        className="btn btn-outline-danger"
                                        onClick={() => {
                                          handleDelete(item.id);
                                        }}
                                      >
                                        <MdDeleteForever className="fs-4"/>
                                      </button>
                                    </td>
                                  </tr>
                                ))}
                              </>
                            )}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </Tab.Pane>
            </Tab.Content>
          </div>
        </div>
      </Tab.Container>
      <Modal show={show} onHide={setShow} centered>
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">
              {mode === "edit" ? "Edit Coupon" : "Add Coupon"}
            </h5>
            <button
              type="button"
              className="btn-close"
              onClick={() => setShow(false)}
            ></button>
          </div>
          <div className="modal-body">
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="form-group">
                <label className="form-label">Name</label>
                <input
                  type="text"
                  className="form-control mb-3"
                  placeholder="Name"
                  {...register("name", { required: "Name is required" })}
                />
                {errors.name && (
                  <p className="text-danger">{errors.name.message}</p>
                )}

                <label className="form-label">Discount Percent</label>
                <input
                  type="number"
                  className="form-control mb-3"
                  placeholder="discount percent"
                  {...register("discount", {
                    required: "Discount percent is required",
                  })}
                />
                {errors.discount && (
                  <p className="text-danger">{errors.discount.message}</p>
                )}

                <label className="form-label">Valid Start</label>
                <input
                  type="date"
                  className="form-control  mb-3"
                  {...register("activeFrom", {
                    required: "Valid Start is required",
                  })}
                />
                {errors.activeFrom && (
                  <p className="text-danger">{errors.activeFrom.message}</p>
                )}

                <label className="form-label">Valid Until</label>
                <input
                  type="date"
                  className="form-control  mb-3"
                  {...register("expireDate", {
                    required: "Valid Until is required",
                  })}
                />
                {errors.expireDate && (
                  <p className="text-danger">{errors.expireDate.message}</p>
                )}

                <label className="form-label">Redemption Limit</label>
                <input
                  type="number"
                  className="form-control mb-3"
                  placeholder="Redemption Limit"
                  {...register("numberOfUse", {
                    required: "Redemption limit is required",
                  })}
                />
                {errors.numberOfUse && (
                  <p className="text-danger">{errors.numberOfUse.message}</p>
                )}

                <div className="form-check">
                  <input
                    type="checkbox"
                    className="form-check-input "
                    id="freeDelivery"
                    {...register("isDeliveryFree")}
                  />
                  <label className="form-check-label" htmlFor="freeDelivery">
                    Free Delivery
                  </label>
                </div>
              </div>
              <div className="modal-footer">
                {loading === "loading" ? (
                  <div className="d-flex btn btn-primary disabled align-items-center justify-content-center ">
                    <span className="me-2">Initiated</span>
                    <Spinner animation="border" size="sm" />
                  </div>
                ) : (
                  <button type="submit" className="btn btn-primary">
                    Save
                  </button>
                )}
              </div>
            </form>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default DiscountCoupon;
