import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { useDispatch } from "react-redux";
import Swal from "sweetalert2";
import { fetchAsyncAllProduct } from "../../../store/product/productSlice";
import { addProductToCollection } from "../../../store/collections/collectionSlice";

// Extracted the product item into a separate component for better readability and reusability
const ProductItem = ({ item, handleAddProduct }) => (
  <div className="my-3 d-flex gap-4" key={item.id}>
    <img
      src={item?.images?.find((image) => image.isMain)?.url || ""}
      alt={item?.name}
      style={{
        width: "250px",
        height: "250px",
        objectFit: "cover",
        objectPosition: "center",
        marginRight: "10px",
        borderRadius: "5px",
      }}
    />
    <div>
      <h4>{item?.name}</h4>
      <p className="mb-0">Title: {item?.title}</p>
      <p className="mb-0">
        Size: {item?.sizes[0]?.name}, Price: {item?.sizes[0]?.price}
      </p>
      <p className="mb-0">
        Colors: {item?.colors?.map((i) => (
          <span key={i.id} className="ms-2">{i.name}</span>
        ))}
      </p>
      <p className="mb-0">Discount: {item?.discount}</p>
      <p className="mb-0">
        Stock Status: {item?.storageStatus === "in" ? "In Stock" : "Stock Out"}
      </p>
      <button
        className="btn btn-outline-primary"
        onClick={() => handleAddProduct(item.id)}
      >
        Add
      </button>
    </div>
  </div>
);

const CollectionsProduct = ({ show, id, allProduct, onHide }) => {
  const dispatch = useDispatch();
  const [search, setSearch] = useState("");



  const filteredProducts = allProduct.filter((item) => {
    const searchTerm = search.toLowerCase();
    return item.name.toLowerCase().includes(searchTerm) || item.title.toLowerCase().includes(searchTerm);
  });

  const filterProductsNotInCollections = (allProduct, collectionId) => {
    return allProduct?.filter((item) => (
      !item.collections ||
      !item.collections.some((collection) => collection.id === collectionId)
    ));
  };

  const handleAddProduct = async (productId) => {
    onHide();
    if (id) {
      try {
        await dispatch(addProductToCollection({ collectionId: id, productId }));
        Swal.fire({
          icon: "success",
          title: "Success!",
          text: "Product added to collection successfully!",
        });
        await dispatch(fetchAsyncAllProduct());
      } catch (error) {
        console.error(error); // Added error logging
      }
    }
  };

  return (
    <div>
      <Modal show={show} onHide={onHide} centered size="lg" scrollable>
        <Modal.Header closeButton>
          <Modal.Title>Add Product to This Collection</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="input-group mb-3">
            <input
              type="text"
              className="form-control"
              placeholder="Search"
              aria-label="Search"
              aria-describedby="button-addon2"
              value={search}
              onChange={(e) => setSearch(e.target.value)}
            />
            <button className="btn btn-outline-primary" type="button" id="button-addon2">
              <i className="fa fa-search"></i>
            </button>
          </div>
          {filterProductsNotInCollections(filteredProducts, id)?.length === 0 ? (
            <div className="text-center">
              <p className="text-danger">
                *There is no product to add to this collection!
              </p>
            </div>
          ) : (
            filterProductsNotInCollections(filteredProducts, id)?.map((item) => (
              <ProductItem item={item} handleAddProduct={handleAddProduct} />
            ))
          )}
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default CollectionsProduct;