import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { useState } from "react";
import { Card } from "react-bootstrap";
import { fetchAsyncAllProduct } from "../../../store/product/productSlice";
import { useSelector } from "react-redux";
import { addProductToCollection } from "../../../store/collections/collectionSlice";
import Swal from "sweetalert2";
import { FaArrowCircleLeft } from "react-icons/fa";
import SubCategoryModal from "../Categories/SubCategoryModal";
import {
  deleteAsyncSubCategory,
  fetchAsyncSingleCategory,
} from "../../../store/category/categorySlice";
import SubCategoryEditModal from "../Categories/SubCategoryEditModal";
import CollectionsProduct from "../Collections/CollectionsProduct";
import SubCategoryProduct from "../Categories/SubCategoryProduct";

const Products = () => {
  // All States
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const id = useParams().id;
  const [searchTerm, setSearchTerm] = useState("");
  const [dataToShow, setDataToShow] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [showModalSubCategory, setShowModalSubCategory] = useState(false);
  const [filteredItem, setFilteredItem] = useState([]);
  // sub category edit modal
  const [showEditModal, setShowEditModal] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);

  const isCategoryPage = location.pathname.includes("/category/");

  useEffect(() => {
    dispatch(fetchAsyncAllProduct());
  }, [dispatch]);
  const allProduct = useSelector((state) => state.Product.allProduct);
  const status = useSelector((state) => state.Product.status);

  // Filter products based on the Items
  useEffect(() => {
    if (!Array.isArray(allProduct)) return;

    let newDataToShow = [...allProduct];
    const pathname = location.pathname;

    if (pathname.includes("/brand/")) {
      newDataToShow = newDataToShow.filter((item) => item.brands?.id === id);
    } else if (pathname.includes("/collection/")) {
      newDataToShow = newDataToShow.filter((item) =>
        item.collections?.some((col) => col?.id === id)
      );
    } else if (pathname.includes("/tag/")) {
      newDataToShow = newDataToShow.filter((item) => item.tags?.id === id);
    } else if (pathname.includes("/category/")) {
      newDataToShow = newDataToShow.filter(
        (item) => item.categories?.id === id
      );
    } else if (pathname.includes("/sub-categories/")) {
      newDataToShow = newDataToShow?.filter((item) =>
        item.subcollections?.some((subcollection) => subcollection?.id === id)
      );
    }

    setDataToShow(newDataToShow);
  }, [allProduct, location.pathname, id]);

  useEffect(() => {
    const pathname = location.pathname;

    if (pathname.includes("/category/") && id) {
      dispatch(fetchAsyncSingleCategory(id));
    }
  }, [dispatch, id, location]);

  const singleCategory = useSelector((state) => state.Category.singleCategory);
  const singleCatStatus = useSelector((state) => state.Category.status);

  // Function to toggle modal visibility
  const toggleModal = () => {
    setShowModal(!showModal);
  };

  const toggleModalSubCategory = () => {
    setShowModalSubCategory(!showModalSubCategory);
  };

  // Function to filter products not in any collection with the given id
  const filterProductsNotInCollections = (allProduct, collectionId) => {
    return allProduct?.filter((item) => {
      return (
        !item.collections ||
        !item.collections.some((collection) => collection.id === collectionId)
      );
    });
  };

  const handleChangeRoute = () => {
    let newPath = "";
    if (location.pathname.includes("collection")) {
      newPath = `/collections`;
    } else if (location.pathname.includes("brand")) {
      newPath = `/brands`;
    } else if (location.pathname.includes("tag")) {
      newPath = `/tags`;
    } else if (location.pathname.includes("category")) {
      newPath = `/categories`;
    } else if (location.pathname.includes("sub-categories")) {
      newPath = `/categories`;
    } else if (location.pathname.includes("products")) {
      newPath = `/`;
    }
    navigate(newPath);
  };

  useEffect(() => {
    const filtered = dataToShow.filter((item) => {
      const searchTermLower = searchTerm.toLowerCase();
      const nameIncludes = item.name.toLowerCase().includes(searchTermLower);

      return nameIncludes;
    });
    setFilteredItem(filtered);
  }, [searchTerm, dataToShow]);

  return (
    <>
      {/* ------- Collection and Sub-category Button Start---------- */}
      <div className="m-3">
        <Link to={`/add/products`}>
          <button className="btn btn-primary">Add New Product</button>
        </Link>
        {location.pathname.includes("collection") && (
          <button className="btn btn-primary ms-2" onClick={toggleModal}>
            Add Product on This Collection
          </button>
        )}
        {location.pathname.includes("category") &&
          ((singleCategory?.products?.length > 0 &&
            singleCategory?.subcollections?.length > 0) ||
            (singleCategory?.products?.length === 0 &&
              singleCategory?.subcollections?.length === 0) ||
            (singleCategory?.products?.length === 0 &&
              singleCategory?.subcollections?.length > 0)) && (
            <button
              className="btn btn-primary ms-2"
              onClick={toggleModalSubCategory}
            >
              Add Sub-Category
            </button>
          )}
      </div>
      {/* ------- Collection and Sub-category Button End---------- */}
      <div className="container mh-auto">
        <div className="row mb-3">
          <div className="col-lg-6">
            <h2 className="text-start col-6 mb-3">
              <Link onClick={handleChangeRoute}>
                <FaArrowCircleLeft className="me-2" />
              </Link>
              {location.pathname.includes("category")
                ? "Category"
                : location.pathname.includes("collection")
                ? "Collection"
                : location.pathname.includes("tag")
                ? "Tag"
                : location.pathname.includes("brand")
                ? "Brands"
                : "Products"}
            </h2>
          </div>
          <div className="col-lg-6">
            <div className="input-group mb-3">
              <input
                type="text"
                className="form-control"
                placeholder="Search"
                aria-label="Search"
                aria-describedby="button-addon2"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
              <button
                className="btn btn-outline-primary"
                type="button"
                id="button-addon2"
              >
                <i className="fa fa-search"></i>
              </button>
            </div>
          </div>
        </div>
        <div className="row">
          {/* --------------------- sub categories Start--------------------- */}
          <SubCategoryProduct
            singleCategory={singleCategory}
            isCategoryPage={isCategoryPage}
            singleCatStatus={singleCatStatus}
          />
          {/* ---------------------- sub categories End------------------- */}

          {/* ---------------------- All Product Start ------------------- */}
          {singleCategory?.subcollections?.length > 0 &&
          isCategoryPage === true ? (
            <></>
          ) : (
            <>
              <>
                {status === "loading" ? (
                  <div className="text-center">
                    <p className="fw-bold fs-4">Loading, please wait...</p>
                  </div>
                ) : (
                  <>
                    {filteredItem?.length > 0 ? (
                      filteredItem?.map((item, index) => (
                        <div
                          className="col-sm-12 col-md-4 col-xl-3"
                          key={index}
                        >
                          <Link to={`/singleProduct/${item.id}`}>
                            <div className="card">
                              <div className="card-body">
                                <div className="m-b-30">
                                  <div className="new-arrival-content position-relative">
                                    <div className="row">
                                      <div className="col-12">
                                        <Card.Img
                                          className="card-image-custom"
                                          src={
                                            item?.images?.find(
                                              (image) => image.isMain
                                            )?.url || ""
                                          }
                                        />
                                      </div>
                                      <div className="col-12 mb-2 mt-5">
                                        <h4>{item.name}</h4>
                                        <p className="m-0">
                                          Title: {item.title}
                                        </p>
                                        <div className="d-flex flex-row align-items-center">
                                          {item?.colors.length > 0 && (
                                            <>
                                              <span className="fw-semibold me-3">
                                                colors:
                                              </span>
                                              {item?.colors.map((i) => (
                                                <p
                                                  className="pe-2 mb-0"
                                                  key={i.name}
                                                >
                                                  {i.name}
                                                </p>
                                              ))}
                                            </>
                                          )}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="text-end">
                                  <Link to={`/edit/products/${item.id}`}>
                                    <button className="btn btn-primary">
                                      Edit
                                    </button>
                                  </Link>
                                </div>
                              </div>
                            </div>
                          </Link>
                        </div>
                      ))
                    ) : (
                      <div className="text-center mt-5">
                        <h2>No product found</h2>
                      </div>
                    )}
                  </>
                )}
              </>
            </>
          )}
          {/* ---------------------- All Product End ------------------- */}
        </div>
        {/* Modal */}
        <CollectionsProduct
          allProduct={allProduct}
          show={showModal}
          filterProductsNotInCollections={filterProductsNotInCollections}
          onHide={toggleModal}
          id={id}
        />
        <SubCategoryModal
          show={showModalSubCategory}
          onHide={toggleModalSubCategory}
          id={id}
        />
      </div>
    </>
  );
};

export default Products;
