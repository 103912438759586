import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Tab } from "react-bootstrap";
import { FaReplyAll } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import Loading from "../../components/Loading";
import { fetchAsyncInbox } from "../../../store/Message/InboxSlice";

const AllMessage = ({ page }) => {
  const dispatch = useDispatch();

  // Fetch all messages
  useEffect(() => {
    dispatch(fetchAsyncInbox());
  }, [dispatch]);
  const allMessages = useSelector((state) => state.Inbox.allInbox);
  const status = useSelector((state) => state.Inbox.status);

  const [dataToShow, setDataToShow] = useState([]);
  useEffect(() => {
    if (allMessages?.length > 0) {
      setDataToShow(allMessages);
    }
  }, [allMessages]);

  // pagination
  const [currentPage, setCurrentPage] = useState(1);
  const recordsPage = 10;
  const lastIndex = currentPage * recordsPage;
  const firstIndex = lastIndex - recordsPage;
  const paginatedData = dataToShow?.slice(firstIndex, lastIndex);

  const npage = Math.ceil(dataToShow?.length / recordsPage);
  const number = [...Array(npage + 1)?.keys()]?.slice(1);

  function prePage() {
    if (currentPage !== 1) {
      setCurrentPage(currentPage - 1);
    }
  }
  function changeCPage(id) {
    setCurrentPage(id);
  }
  function nextPage() {
    if (currentPage !== npage) {
      setCurrentPage(currentPage + 1);
    }
  }

  return (
    <>
      <Tab.Container defaultActiveKey="Import">
        <Tab.Content>
          <div className="email-list">
            {status === "loading" ? (
              <Loading status={status} />
            ) : (
              <>
                {paginatedData?.length === 0 && (
                  <div className="text-center mt-5">
                    No Message message in this page
                  </div>
                )}
                {paginatedData?.map((data, ind) => (
                  <div
                    className={`message ${!data.isRead ? "bg-white" : ""}`}
                    key={ind}
                  >
                    <div>
                      <Link
                        to={`/single-message/${data.id}`}
                        className="col-mail col-mail-2"
                      >
                        <div className="hader">{data.name}</div>
                        <div className="subject">{data.subject}</div>
                        <div className="date">
                          {new Date(data.createdAt).toLocaleString()}
                        </div>
                      </Link>
                    </div>
                  </div>
                ))}
              </>
            )}
          </div>
        </Tab.Content>
      </Tab.Container>
    </>
  );
};

export default AllMessage;
