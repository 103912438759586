import React, { useState } from "react";
import { Card, Spinner } from "react-bootstrap";
import Swal from "sweetalert2";

const Profile = () => {
  const [phone, setPhone] = useState("");
  const [landline, setLandline] = useState("");
  const [email, setEmail] = useState("");
  const [address, setAddress] = useState("");
  const [loading, setLoading] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      const data = { phone, landline, email, address };
      Swal.fire({
        title: "Success",
        text: "Update Contact Success",
        icon: "success",
        confirmButtonText: "Ok",
      });
    } catch (error) {
      console.error("Error updating contact:", error);
      Swal.fire({
        title: "Error",
        text: "Failed to update contact",
        icon: "error",
        confirmButtonText: "Ok",
      });
    } finally {
      setLoading(false);
    }
  };


  return (
    <>
      <Card className="m-3">
        <Card.Header as="h5">Contact</Card.Header>
        <Card.Body>
          <Card.Title className="text-center fs-2">Details</Card.Title>
          <Card.Text className="container">
            <form onSubmit={handleSubmit}>
              <div className="col">
                <div data-mdb-input-init className="form-outline">
                  <label className="form-label" for="form6Example1">
                    Phone
                  </label>
                  <input
                    type="text"
                    value={phone}
                    required
                    onChange={(e) => setPhone(e.target.value)}
                    id="form6Example1"
                    className="form-control"
                  />
                </div>
              </div>
              <div data-mdb-input-init className="form-outline mb-4">
                <label className="form-label" for="form6Example4">
                  Landline
                </label>
                <input
                  value={landline}
                  type="text"
                  required
                  onChange={(e) => setLandline(e.target.value)}
                  id="form6Example4"
                  className="form-control"
                />
              </div>

              <div data-mdb-input-init className="form-outline mb-4">
                <label className="form-label" for="form6Example5">
                  Email
                </label>
                <input
                  type="email"
                  value={email}
                  required
                  onChange={(e) => setEmail(e.target.value)}
                  id="form6Example5"
                  className="form-control"
                />
              </div>

              <div data-mdb-input-init className="form-outline mb-4">
                <label className="form-label" for="form6Example6">
                  address
                </label>
                <input
                  value={address}
                  required
                  onChange={(e) => setAddress(e.target.value)}
                  type="text"
                  id="form6Example6"
                  className="form-control"
                />
              </div>
              <button
                data-mdb-ripple-init
                type="submit"
                className="btn btn-primary btn-block mb-4"
              >
                {loading === true ? (
                  <div className="d-flex align-items-center justify-content-center ">
                    <span className="me-2">Submitting</span>
                    <Spinner animation="border" size="sm" />
                  </div>
                ) : (
                  "Submit"
                )}
              </button>
            </form>
          </Card.Text>
        </Card.Body>
      </Card>
    </>
  );
};

export default Profile;
